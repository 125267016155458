@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
#root {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(var(--bs-secondary-rgb), $alpha: 0.7) transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(var(--bs-secondary-rgb), $alpha: 0.7);
}

.layout {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  width: 100vw;
  height: 100vh;
  header {
    grid-row: 1;
    grid-column: 1 / 3;
  }
  aside {
    grid-row: 2;
    grid-column: 1;
    height: 100%;
    overflow: hidden;
  }
  main {
    grid-row: 2;
    grid-column: 2;
    height: 100%;
    overflow: auto!important;
  }
}

.menu {
  .nav-link {
    color: var(--bs-body-color);
    padding: 4px 12px;
    &.active {
      background-color: rgba(var(--bs-white-rgb), 0.1);
      .menu-icon {
        color: #5584ff;
      }
    }
    &:hover {
      background-color: rgba(var(--bs-white-rgb), 0.03);
    }
  }
  .accordion-item {
    background: transparent;
    .accordion-button {
      background-color: transparent;
      box-shadow: none;
      &::after {
        filter: grayscale(1) brightness(1.5);
        scale: 0.85;
      }
      &:hover {
        background-color: rgba(var(--bs-white-rgb), 0.03);
      }
      &:not(.collapsed) {
        color: unset;
      }
    }
  }
  .accordion-header:has(+ .submenu .nav-link.active) .menu-icon {
    color: #5584ff;
  }
}

.nav-underline .nav-link.active {
  
  border-color: var(--bs-primary);
  font-weight: 400;
  text-shadow: 0px 0px 1px black;
}

table {
  thead th {
    font-weight: normal;
    background-color: var(--bs-light) !important;
    color: var(--bs-secondary) !important;
    font-weight: 500;
    // &:first-child {
    //   border-top-left-radius: 8px;
    //   border-bottom-left-radius: 8px;
    // }
    // &:last-child {
    //   border-top-right-radius: 8px;
    //   border-bottom-right-radius: 8px;
    // }
  }
}

.logomargin {

  margin-left: -35px;
 width:  274px;
 
}

