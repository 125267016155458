.upload-section{
  padding: 0px 12px 13px 12px;
  display: flex;
  label {
    font-family: "OpenSans-Semibold";
    font-size: 10px;
    line-height: 12px;
  }        
  .upload-link{
    position: relative;
    &.mrgn-top{
      margin-top: 0.5rem;
    }
    .btn{
      height: auto;
    }
    .form-control-file{
        top:0px;
        opacity: 0;
        cursor: pointer;
        font-size: 0;
        position: absolute; 
        width: 200px;     
        left: 0px;   
        height: auto;   
    }          
  }
  .form-group{
    width: 100%;
    margin-right: 0px;
    // &:first-child{
    //   margin-right: 15px;
    // }
    // &:last-child{
    //   margin-left: 15px;
    // }
  }
  .upload-area{
   
    box-sizing: border-box;          
    padding: 0px;
    .dropzone{
      // border: 2px dashed #E0E3E6;
      border: 2px solid #fff;
      border-radius: 3px;
      min-height: 71px;
      //cursor: -webkit-grab; 
      //cursor: grab;
      cursor: pointer;
      text-align: center;
      aside{width:100%;}
      // p{
      //   color: #3F4347;
      //   font-family: "OpenSans";
      //   font-style: normal;
      //   font-weight: 600;
      //   font-size: 16px;
      //   line-height: 22px;
      //   span{
      //     color: #2CAF92;
      //     text-decoration: underline;
      //   }
      // }
      p{
        color: #84888C;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        font-family: "OpenSans";
        margin-bottom: 0px;
        width:100%;
        text-align: center;
        span{
          color: #2CAF92;
            text-decoration: underline;
          font-family: "OpenSans-Semibold";
        }
        &.not-accepted{
          color: #B12A18;
        }
      }
      &:focus, &:focus-visible{
        border-color: #2caf92;
        outline:0px;
      }
      &:hover{
        cursor: pointer;
      } 
    }
    ul{
      margin:0px;
      padding:0px;
      li{
        padding:2px 2px;
        .d-flex{
          .icon{width:40px;
          svg{
            width: 40px;
            height: 40px;
            color: #2caf92;
          }
        }
          .file-info{
            width:calc(100% - 80px);
            padding:0px 10px;
            .name-loader{
              width: calc(100% - 40px);
              padding-right: 20px;
              .name{    
                font-family: "OpenSans";
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                text-align: left;
                line-height: 16px; 
                max-width: 100%;
                position: relative;                 
                .full-name{
                  //max-width:calc(100% - 100px);
                  //max-width:250px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  cursor: pointer;
                  color: #1D1E20;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 18px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .full-size{
                  font-family: "OpenSans-Semibold";
                }
                .details{
                  margin-top: 4px;
                  color: #84888C;
                  font-family: "OpenSans";
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 18px;
                }
              }
              .loader{
                .bg-success{
                  background-color: #2CAF92 !important;
                }
              }
            }
            .formate-percentage{
              width:40px;
              .formate, .percentage{
                width:40px;
                height:24px;
                background: #E5F5FF;
                border-radius: 3px;
                color: #065A8C;
                font-family: "OpenSans-Semibold";
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
              }
              .percentage{
                background: transparent;
              }
            }
          }
          .action{
            width:40px;
            .more-action{
              &.dropdown{
                .more{
                  border-color: #fff;
                  &:hover, &:active, &:focus, &:not(:disabled):not(.disabled):active{
                    border-color: #364760;
                  }
                }
              }
            }
          }
        }              
      }
    }
    &.default{
      // border: 2px dashed #E0E3E6;
      border: 2px dashed #fff;
      border-radius: 20px;
      min-height: 74px;
      cursor: -webkit-grab; 
      cursor: grab;
      text-align: center;            
      position: relative;  
      padding: 10px;          
      .dropzone{
        border: 0px;
        background-color: transparent;
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        min-height: 71px;              
        cursor: pointer;              
        text-align: center;
        p{
          //color: #3F4347;
          font-family: "OpenSans";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          span{
            color: #2CAF92;
            text-decoration: underline;
          }
        }
      }
      &.drag-active{
        border-color: #2CAF92;
        .dropzone{
          z-index: 1;
          cursor: grab;
          cursor: -webkit-grab; 
          background-color: rgba(255, 255, 255, 0.8);
          p{
            color: #000000;
          }
        }
      }
    }          
  }
  .form-group{
    textarea{
      &.form-control{
        white-space: pre-wrap;
      }
    }
  }        
}
@keyframes loadingAnimation {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@mixin defaultCheckBoxStyle {
  .form-check-input {
      margin-top: 0;
      margin-right: 8px;
  }

  input[type="checkbox"] {
      appearance: none;
      -webkit-appearance: none;
      background-color: #fff;
      border: 1px solid #c7c8ca;
      padding: 8px;
      border-radius: 3px;
      display: inline-block;
      position: relative;
      top: 2px;
      outline: none;
      cursor: pointer;

      &:disabled {
          background-color: #84888c !important;
          border: 1px solid #84888c !important;
          cursor: not-allowed !important;
      }

      &:checked {
          background-color: #2caf92;
          border: 1px solid #2caf92;
      }

      &:checked:after {
          content: "";
          position: absolute;
          top: 2px;
          left: 5px;
          display: block;
          width: 6px;
          height: 10px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
      }

      &:indeterminate {
          background-color: #2caf92;
          border: 1px solid #2caf92;
      }

      &:indeterminate:after {
          content: "";
          position: absolute;
          top: 8px;
          left: 3px;
          display: block;
          width: 10px;
          height: 10px;
          border: solid #fff;
          border-width: 2px 0 0 0;
          transform: rotate(0deg);
      }
  }

  .form-check-label {
      margin-top: 14px;
      cursor: pointer;
  }
}
.loader-responsive{
  .performanceList{
    .filter-sec{
        padding: 20px;
      .header{
        .loadingAnimation{
          //background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
          background: linear-gradient(100deg, #e6e7e8 30%, #f6f7f8 50%, #e6e7e8 70%);
          background-size: 400%;
          animation: loadingAnimation 1.2s ease-in-out infinite;
        }
      }
      &.upload-section{
        .tableList{
          width: 100%;
          ul{
            li{              
              .list{
                width: 50%;
                padding-left:0px;
                padding-right: 5%;
                .loadingAnimation{
                  height: 75px;
                  width:100%;
                }                  
                  &:last-child{
                    padding-right: 0%;
                    padding-left: 5%;
                  }
              }
              &:last-child{
                border-bottom:0px;
              }
            }
          }
        }
      }
    }
    .transactions-list{
      .tableHeader{
        height: 48px;
      }
      .tableList{
        ul{
          li{
            height: 48px;
            .list{
              .loadingAnimation{
                width:90%;
              }
                width: 15%;
                &:last-child{
                  width:10%;
                  .loadingAnimation{
                    width:90%;
                    margin-left: 10%;
                  }
                }
            }
            &:last-child{
              border-bottom:0px;
            }
          }
        }
      }
    }
  }
}



.react-confirm-alert-overlay{
  &.confirm-modal{
    .react-confirm-alert-button-group{
      .btn{
        padding: 6px 18px;
        height: 30px;
        color: #fff;
        &:hover{
          color:#fff;
        }
      }
      .upload-link{
        position: relative;
        &.mrgn-top{
          margin-top: 0.5rem;
        }
        .btn{
          height: 30px;
          color:#3f4347;
          &:hover{
            color:#3f4347;
          }
        }
        .form-control-file{
            top: 0px;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            cursor: pointer;
            font-size: 0;
            position: absolute;            
        }          
      }
    }    
  }
}
.backDrop-dropdown, .loader-spinner{position: fixed; top:0; left:0; right:0; bottom: 0; z-index: 1; background-color: rgba(0,0,0,0);}
.attach-invoice-modal{
  width: 362px;
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #E0E3E6;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-family: "OpenSans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  z-index: 2;
  transition: all 0.5s ease;
  //left: 0;
  // margin-left: auto;
  // margin-right: auto;
  
  // left: 0;
   //right: 0;
  //  left: 50%;
    
  //   transform: translate(-50%,0);
  .header{
    padding:17px 16px 15px;
    border-bottom: 1px solid #E0E3E6;
    .title{
      color: #1D1E20;
      letter-spacing: 0.16px;
      font-weight: bold;
      margin-left:8px;
    }
  }
  .search{
    padding:8px 16px;
    border-bottom: 1px solid #E0E3E6;
  }
  .list{
    ul{
      list-style: none;
      padding: 0px;
      height: 185px;
      overflow-y: auto;
      li{
        //margin-bottom: 20px;
        padding: 13px 16px 12px;
        cursor: pointer;
        &:last-child{
          margin-bottom: 0px;
        }
        .date{font-weight: bold;color: #1D1E20;}
        .name{color: #6A6E73;}
        &:hover{
          background-color: #f4f6f8;
        }
      }
    }
  }
}
.loader-spinner{
  z-index: 9999;
  background-color: rgba(0,0,0,0.6);
  .spinner-border{
    &.text-success{
      color: #2caf92 !important;
    }
  } 
}
.move-invoice-modal{
  .modal-70w {
    width: 70%;
    max-width: none!important;
  }
  .form-group{
    .form-label{
      font-family: "OpenSans-Semibold";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #1d1e20;
    }
    .single-select{
      &.dropdown{
        .dropdown-toggle{
          width: 100%;
          height: 40px;
          padding: 0px 16px;
          color: #1d1e20;
          font-family: "OpenSans";
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          .drop-arrow{
            position: absolute;
            right: 10px;
            top: 13px;
          }
          &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active{
            background-color: #fff !important;
            color: #1d1e20 !important;
            border: 1px solid #2caf92 !important;
            .drop-arrow{
              svg{
                fill: none;
                color: #1d1e20;
              }                
            }
          }
          &::after{
            content:none;
          }
        }
        &.show{
          .btn-primary{
            background-color: #fff !important;
            color: #1d1e20 !important;
            border: 1px solid #2caf92 !important;
            .drop-arrow{
              svg{
                fill: none;
                color: #1d1e20;
              }                
            }
          }
        }
        .dropdown-menu{
          .dropdown-item{
            padding: 13px 17px 12px;
            &.read-only{
              //pointer-events: none;
              opacity: 0.6;
              cursor: default;
              &:hover{
                background-color: transparent;
              }
            }
          }
        }
      }
    }
      .single-select-image{
        &.bg-blue{
          .dropdown-toggle{
            width: 100%;
            height: 40px;
            padding: 0px 16px;
            color: #1d1e20;
            font-family: "OpenSans";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            .drop-arrow{
              position: absolute;
              right: 10px;
            }
            &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active{
              background-color: #fff !important;
              color: #1d1e20 !important;
              border: 1px solid #2caf92 !important;
              .drop-arrow{
                svg{
                  fill: none;
                  color: #1d1e20;
                }                
              }
            }
          }
          &.show{
            .btn-primary{
              background-color: #fff !important;
              color: #1d1e20 !important;
              border: 1px solid #2caf92 !important;
              .drop-arrow{
                svg{
                  fill: none;
                  color: #1d1e20;
                }                
              }
            }
          }
          .dropdown-menu{
            .list-area{
              min-height: auto;
            }
          }
        }        
      } 
  }
  .filter-sec{
    .form-group{
      margin-right: 15px;
      .form-label{
        font-family: "OpenSans-Semibold";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #1d1e20;
      }
      .calender-picker{
        .drop-arrow{
          position: absolute;
          right: 12px;
          top: 10px;
          z-index: 1;
        }
        .react-datepicker-wrapper{
          .react-datepicker__input-container{
            .form-control{
              //height: 36px;
              color: #1d1e20;
              font-family: "OpenSans";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
              padding-left: 16px;
              padding-right: 16px;
              &.react-datepicker-ignore-onclickoutside{
                background-color: #fff;
                border: 1px solid #2caf92;
              }
            }
          }
        }            
      }
      .single-select-image{
        width: 175px;
      }
      textarea{
        &.form-control{
          width: 450px;
        }
      }
    }    
  }
  .associated-invoices{
    .sec-ttl{
      font-family: "OpenSans-Semibold";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 16px;
    }
    .paid-status{
      background: #DEFFF8;
      border-radius: 3px;
      color: #0E9074;
      padding:4px 6px;
      width: 75px;
      text-align: center;
    }
    .custom-control-label{
      &::before{
        left: -2.3rem;
      }
      &::after{
        left: -2.3rem;
      }
    } 
  }
  .modal-content{
    .modal-header{
      padding: 16px;
      .modal-title{
        color: #1D1E20;
        font-family: "OpenSans";
        letter-spacing: 0.16px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }
      .close{
        padding: 0;
        margin: 0;
        line-height: 18px;
      }
    }
    .modal-body{
      padding: 24px 16px;
      .form-label{
        font-family: "OpenSans";
        color: #1D1E20;
        letter-spacing: 0.16px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        margin-bottom:8px;
      }      
    }
    .modal-footer{
      padding: 12px;
      .btn{
        height: 32px;
      }
    }
  }
  
  &.mark-invoice-as-paid{
    .form-control{
      &:disabled{
        background: #FFFFFF;
        opacity: 0.8;
        border: 1px solid #ced4da;
      }
    }
    .form-group{
      margin-bottom: 24px;
      .form-control{        
        font-family: "OpenSans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;               
      }
      input{
        height: 40px;
        padding: 0px 12px;
      } 
      .single-select-image{
        .dropdown-toggle{
          padding: 0px 12px;
          font-weight: 400;
          .drop-arrow{
            svg{
              margin-right: 2px;
            }
          }
        }
        .dropdown-menu{
          width: 100%;
        }
      }
      &.account-dropdown{
        .single-select-image{          
          .dropdown-menu{
            width: 300px;
          }
        }
      }
      .react-datepicker-wrapper{
        width: 100%;
        .form-control{
          padding: 0px 12px;
          font-family: "OpenSans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #1D1E20;
        }
      }
      &.remove-btm-space{
        margin-bottom: 0px;
      }
      &.event-disabled{    
        .single-select-image{
          &.bg-blue{
            cursor: not-allowed;
            .dropdown-toggle{
              background: #FFFFFF;
              opacity: 0.8;
              border: 1px solid #ced4da;
              pointer-events: none;              
              .drop-arrow{
                opacity: 0.8;
              }
            }
            &:hover{
              .dropdown-toggle{
                background-color: #f3f3f4;
              }
            }
          }
        }
      }
    }
    .coa-select{
      .rbt-input-hint{
        opacity: 0;
      } 
      .dropdown-item{
        &.load-more{
          text-align: center;
          background-color: #2caf92;
          color:#fff;
          padding-top: 8px;
          padding-bottom: 8px;
          &:hover{
            background-color: #0E9074;
            color:#fff;
          }
          pointer-events: unset;
          cursor: pointer;
        }
      }
    }
  }  
}


.transaction-slideout{
  &.bankAccount-setting{
    .container-fluid{
      &.body-sec{
        padding-bottom:0px;
        .bank-account-setting{
          padding: 0px 32px 28px;
          .nav{
            &.nav-tabs{
              .nav-link{
                &:focus{
                  border-color : transparent;
                }
                &.active{
                  border-color: #2caf92;
                }
              }
            }
          } 
          .tab-content{
            padding: 0px;
          }
          .section-ttl{
            font-family: "OpenSans-Bold";
            font-size: 16px;
            line-height: 22px;
            padding: 24px 0px;
            background-color: #fff;      
          }
          .form-section{
            font-family: "OpenSans";
            font-style: normal;
            font-weight: normal;
            color: #1D1E20;
            font-size: 14px;
            line-height: 18px;
            overflow-x: hidden; 
            overflow-y: auto;
            height: calc(100vh - 234px);
            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            input[type="number"] {
              -moz-appearance: textfield;
              appearance: textfield;
            }
            .pos-unset{
              position: unset;
            }
            &.mr-28{
              margin-top: 28px;
            }
            &.footer-enable{
              height: calc(100vh - 306px);
            }   
            label{
              font-family: "OpenSans-Bold";
              svg{
                margin-left: 5px;
                fill: #6A6E73;
              }              
            }
            .form-control-plaintext{
              font-family: "OpenSans";
              font-weight: normal;
              font-style: normal;    
              line-height: 18px;
              font-size: 14px;
            }
            .form-control{
              font-family: "OpenSans";
              font-weight: normal;
              font-style: normal;    
              line-height: 18px;
              font-size: 14px;
              border-color: #fff;
              &:focus{
                border-color: #2caf92;
              }
              &:read-only{
                background-color: #f3f3f4;
                border-color: #f3f3f4;
              }
            }
            .upload-link{
              position: relative;
              border: 2px dashed #E0E3E6;
              border-radius: 0.25rem;
              min-height: 100px;
              cursor: -webkit-grab; 
              cursor: grab;
              text-align: center;
              .dropzone{
                min-height: 100px;
                p{
                  margin-bottom: 0px;
                  span{
                    color: #2CAF92;
                    text-decoration: underline;
                  }
                }
              }
              aside{
                position: absolute;
                top: 0;
                left:0;
                right:0;                
                ul{                 
                  margin: 0px; 
                  padding: 0px;                 
                  li{
                    min-height: 100px;
                    padding: 10px;
                    background-color: #fff;
                    .image-sec{
                      padding-right: 10px;
                      width:160px;
                      img{
                        width:100%;
                      }
                    }
                    .image-name{
                      font-family: "OpenSans";
                      font-style: normal;
                      padding-right: 10px;
                      color: #1D1E20;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 18px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                    .delete{
                      .cross{padding-right: 0px;}
                    }
                    &:hover{
                      cursor: default;
                    }
                  }
                }
              }
            }
            .calender-picker{
              position: relative;
              margin:0px;
              .calender{
                position: absolute;
                top:35px;
              }
              .calender-display{
                font-family: "OpenSans";
                font-weight: normal;
                font-style: normal;
                line-height: 18px;
                font-size: 14px;
                padding: 10px 8px;
                .calender-icon{
                  svg{
                    fill:#3F4347;
                  }
                }
                &.open{
                  .calender-icon{
                    svg{
                      fill:#fff;
                    }
                  }
                }
              }
              &.input-form-calender{
                .form-control{
                  padding-left: 30px;
                }
                .calender-icon{
                  position: absolute;
                  top: 8px;
                  left: 8px;
                  svg{
                    fill:#3F4347;
                  }
                }
                .chevron-down{
                  position: absolute;
                  top: 8px;
                  right: 8px;
                  svg{
                    position: relative;
                    top:0px;
                    right:0px;
                    fill:#3F4347;
                  }
                }
              }
            }
            .btn-link{
              &.form-padding{
                padding: 0px 8px;
              }
            }
            .custom-control{
              &.custom-switch{
                margin-left: 8px;
              }
            } 
          }
          .form-group{
            .rbt-menu{
              &.dropdown-menu{
                font-family: "OpenSans";
                font-style: normal;
                font-weight: normal;
                box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
                width: 100%;
                border: 1px solid #e0e3e6;
                border-radius: 3px;
                background: #ffffff;
                padding: 0px;
                max-height: 200px;
                overflow: auto;
                .dropdown-item{     
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;           
                  padding: 12px 8px;
                  color: #1d1e20;
                  //border-bottom: 1px solid #e0e3e6;
                  border:0px;
                  pointer-events: none;
                  &.load-more{
                    text-align: center;
                    background-color: #2caf92;
                    color:#fff;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    &:hover{
                      background-color: #0E9074;
                    }
                    pointer-events: unset;
                  }
                  &:hover, &:focus{
                    background-color: transparent;
                    cursor: default;
                  }
                }
                &.menu-item-clickable{
                  .dropdown-item{
                    pointer-events: auto;
                    &:hover{
                      background-color: #f4f6f8;
                    }
                  }
                }
              }              
            }            
            .calender-picker{
              position: relative;
              &.up-side-show{
                .calender{
                  position: unset;
                  top: inherit;
                  .react-calendar{
                    bottom: 35px;
                    z-index: 11;
                    // .react-calendar__viewContainer{
                    //   .react-calendar__month-view{
                    //     .react-calendar__month-view__days{
                    //       height: 220px;
                    //     }
                    //   } 
                    // }
                  }
                }
              }
              .react-datepicker-wrapper{
                width:100%;
                .react-datepicker__input-container{
                  .form-control{
                    padding: 10px 8px;
                    height: calc(1.5em + 0.75rem + 2px);
                    border-color: #ced4da;
                    &.react-datepicker-ignore-onclickoutside{
                      border-color: #2caf92;
                      background-color: #fff;
                    }
                  }
                }                
              }
              .chevron-down{
                svg{
                  fill: #84888C;
                  position: absolute;
                  right: 15px;
                  top: 14px;
                }
              }                            
            }
            &.error{
              label{
                color:#B12A18;
              }
              .form-control{
                border-color: #B12A18;
              }
              span{                
                &.error{
                  display: block;
                  margin-top: 2px;
                  color: #B12A18;
                }
              }
              .custom-selectbox{
                &.dropdown{
                  .dropdown-toggle{
                    border-color: #B12A18;
                  }
                } 
              }
              .calender-picker{
                .react-datepicker-wrapper{
                  .react-datepicker__input-container{
                    .form-control{
                      border-color: #B12A18;
                      &.react-datepicker-ignore-onclickoutside{
                        border-color: #B12A18;
                      }
                    }
                  }                
                }             
              }              
            }
            .vendorSearch{
              .VendorList, .isFromCCSI{
                label{
                  font-family: "OpenSans-Semibold";
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 18px;
                  color: #1d1e20;
                }
                .form-control{
                  border-color: #ced4da;
                  &:focus{
                    border-color: #2caf92;
                  }
                }
                .form-group{
                  margin-right: 15px;
                }
              }              
            }
          }
          .BankSyncTab{
            .form-section-banksync{
             height: auto;
             border-bottom: 1px solid #ced4da;
            Label{
              padding: 28px 130px 0px 0px;
              margin-bottom: 16px;
              font-family: 'OpenSans';
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
            }
            .bankSyncToggle{
              top:22px;
            }
          }
            .body-section-bank-sync{
              align-items: center;
              padding: 30px 0px 0px 0px;
              .circleBankSection{
                padding-top: 16px;
                .circle-BankFeed{
                  width: 100px;
                  height: 100px;
                  background: #F4F8FB;
                  border-radius: 50%;
                  color: #065a8c;
                  font-family: "OpenSans";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 24px;
                  margin-right: 12px;
                }
              }
              .labelBankS{
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                padding-top: 16px;
              }
              .infoText{
                align-items: center;
                font-family: "OpenSans";
                font-weight: normal;
                font-style: normal;    
                line-height: 18px;
                font-size: 14px;
                line-height: 20px;
                padding: 6px;
                margin-left: 30%;
                margin-right: 30%;
                text-align: center;
                }
               .btn-BankSync{
                text-align: center;
                padding: 20px;
               } 
              }
              .bank-sync-setup-step {
                .bank-sync-steps {
                  background-color: #e5f5ff;
                  width: 700px;
                  .setup-header-bank-sync {
                    padding: 16px 24px;
                    border-bottom: 1px solid #065a8c20;
                    .heading {
                      font-family: "OpenSans-Semibold";
                      font-size: 14px;
                      line-height: 18px;
                      letter-spacing: 0.16px;
                      color: #065a8c;
                      margin-bottom: 0;
                    }
                    .steps {
                      font-family: "OpenSans";
                      font-size: 14px;
                      line-height: 18px;
                      color: #065a8c;
                    }
                  }
                  .setup-body-bank-sync {
                    padding: 24px;
                    height: 70px;
                    .body-heading {
                      font-family: "OpenSans-Semibold";
                      font-size: 14px;
                      line-height: 18px;
                      letter-spacing: 0.16px;
                      color: #065a8c;
                      margin-bottom: 8px;
                    }
                    .body-description {
                      font-family: "OpenSans";
                      font-size: 14px;
                      line-height: 18px;
                      color: #065a8c;
                      margin-bottom: 20px;
                    }
                    .form-section {
                      .body-section {
                        margin-bottom: 24px;
                        .form-group {
                          margin-bottom: 0;
                          .validation-error {
                            .form-label {
                              color: #b12a18 !important;
                            }
                            .form-control,
                            .dropdown-toggle {
                              border-color: #b12a18 !important;
                            }
                            .validation-message {
                              font-family: "OpenSans";
                              font-size: 12px;
                              line-height: 16px;
                              color: #b12a18;
                              margin-top: 8px;
                              display: block;
                            }
                          }
                          label,
                          .form-control {
                            width: 100%;
                          }
                          .form-control {
                            height: 40px;
                          }
                          .single-select {
                            width: 100%;
                            .dropdown-toggle {
                              width: 100%;
                            }
                          }
                        }
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3) {
                          .form-group {
                            width: 48%;
                          }
                        }
                        &:last-child {
                          margin-bottom: 0;
                        }
                      }
                    }
                    .body-section {
                      .form-section-Bank {
                        overflow-x: visible; 
                        overflow-y: visible;
                       // height: calc(100vh - 234px);
                       .SyncDateError{
                        font-family: OpenSans;
                        font-size: 13px;
                        color: rgb(177, 42, 24);
                        margin-left: 8px;
                      }
                        .form-group {
                          
                          width: 48%;
                          label,
                          .form-control {
                            width: 100%;
                          }
                          .date-picker {
                            position: relative;
                            .DropArrow-Bank-Sync{
                              right:10px !important
                            }
                            .react-datepicker-wrapper {
                                .form-control {
                                    // background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z' fill='%233F4347' /%3E%3C/svg%3E");
                                    background-repeat: no-repeat;
                                    background-position: right 12px top 12px;
                                    min-width: 102px;
                                }
                            }
                        }
                        }
                        .SyncDateError{
                          font-family: OpenSans;
                          font-size: 13px;
                          color: rgb(177, 42, 24);
                          margin-left: 8px;
                        }
                      }
                    }
                  }
                  .addjustHeight{
                    height: 180px!important;
                  }
                  .setup-footer {
                    padding: 16px 24px;
                    border-top: 1px solid #065a8c20;
                    text-align: right;
                    .btn {
                      margin-left: 16px;
                    }
                  }
                }
                .ConfirmationLabelB{
                  font-family: 'OpenSans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                .badge-Bank{
                  margin-left:4%;
                  .badgePendingBankSync{
                    background-color:  #fff3e8;//#DEFFF8;
                    color:#863902;//#0E9074;
                    margin-left: 12px;
                  }
                  .badgeBankLinked{
                    background-color:  #d8fff6;
                    color:#2caf92;
                    margin-left: 12px;
                  }
                }
                .ThreeDotBank-Sync{
                  margin: -7px 0px 0px 40px;
                }
                .bankNameLabel{
                  margin-top:3px;
                }
                
                }
                
              }
              .add-bank-account-section {
                .form-group {
                  .readonly-value {
                    .account-type {
                      width: 180px;
                    }
                    .account-number {
                      width: 108px;
                    }
                    .confirmed-unconfirmed {
                      width: 100px;
                    }
                  }
                }
                .confirm-bank-section {
                  margin-bottom: 1rem;
                  .confirm-bank-setup {
                    background-color: #fff3e8;
                    width: 700px;
                    .setup-header {
                      padding: 16px 24px;
                      border-bottom: 1px solid #065a8c20;
                      .heading {
                        font-family: "OpenSans-Semibold";
                        font-size: 14px;
                        line-height: 18px;
                        letter-spacing: 0.16px;
                        color: #065a8c;
                        margin-bottom: 0;
                      }
                      .steps {
                        font-family: "OpenSans";
                        font-size: 14px;
                        line-height: 18px;
                        color: #065a8c;
                      }
                    }
                    .setup-body {
                      padding: 24px;
                      .body-heading {
                        font-family: "OpenSans-Semibold";
                        font-size: 14px;
                        line-height: 18px;
                        letter-spacing: 0.16px;
                        color: #065a8c;
                        margin-bottom: 8px;
                      }
                      .body-description {
                        font-family: "OpenSans";
                        font-size: 14px;
                        line-height: 18px;
                        //color: #065a8c;
                        color: #863902;
                        margin-bottom: 20px;
                      }
                    }
                    .setup-footer {
                      padding: 16px 24px;
                      border-top: 1px solid #065a8c20;
                      text-align: right;
                      .btn {
                        margin-left: 16px;
                      }
                    }
                  }
                  .confirm-form-setup {
                    background-color: #e5f5ff;
                    width: 700px;
                    .setup-header {
                      padding: 16px 24px;
                      border-bottom: 1px solid #065a8c20;
                      .heading {
                        font-family: "OpenSans-Semibold";
                        font-size: 14px;
                        line-height: 18px;
                        letter-spacing: 0.16px;
                        color: #065a8c;
                        margin-bottom: 0;
                      }
                      .steps {
                        font-family: "OpenSans";
                        font-size: 14px;
                        line-height: 18px;
                        color: #065a8c;
                      }
                    }
                    .setup-body {
                      padding: 24px;
                      .body-heading {
                        font-family: "OpenSans-Semibold";
                        font-size: 14px;
                        line-height: 18px;
                        letter-spacing: 0.16px;
                        color: #065a8c;
                        margin-bottom: 8px;
                      }
                      .body-description {
                        font-family: "OpenSans";
                        font-size: 14px;
                        line-height: 18px;
                        color: #065a8c;
                        // color: #863902;
                        margin-bottom: 20px;
                      }
                      .body-section {
                        .form-section {
                          .form-group {
                            width: 48%;
                            .validation-error {
                              // .form-label {
                              //   color: #b12a18 !important;
                              // }
                              .form-control,
                              .dropdown-toggle {
                                border-color: #b12a18 !important;
                              }
                              .validation-message {
                                font-family: "OpenSans";
                                font-size: 12px;
                                line-height: 16px;
                                color: #b12a18;
                                margin-top: 8px;
                                display: block;
                              }
                            }
                            label,
                            .form-control {
                              width: 100%;
                            }
                          }
                        }
                      }
                    }
                    .setup-footer {
                      padding: 16px 24px;
                      border-top: 1px solid #065a8c20;
                      text-align: right;
                      .btn {
                        margin-left: 16px;
                      }
                    }
                  }
                }
              }
            }
        }
      }
    }
    &.modal-form{
      .container-fluid{
        height: auto;
        &.body-sec{
          width: 100%;
          .bank-account-setting{
            padding: 0px;
            .form-section{
              height: auto;              
            }
          }
        }
      }
    }
  }
} 

.custom-selectbox{
  &.dropdown{
    font-family: "OpenSans";
    font-weight: normal;
    font-style: normal;    
    line-height: 18px;
    font-size: 14px;
    .dropdown-toggle{
      width:100%;
      font-family: "OpenSans";
      font-weight: normal;
      font-style: normal; 
      line-height: 18px;
      font-size: 14px;
      padding: 10px 8px;
      text-align: left;
      color: #495057;
      background-color: #fff;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &::after{
        border: 0px;
      }
      svg{
        fill: #84888C;
        position: absolute;
        right: 15px;
        top: 16px;
      }
      &:hover, &:active, &:not(:disabled):not(.disabled):active{
        background-color: #fff;
        border-color: #ced4da;
        color: #495057;
      }
      &:focus{
        border-color: #2caf92;
        background-color: #fff;
        color: #495057;
        svg{
          fill: #2caf92;
        }
      }
      .selected-value{
        width: calc(100% - 40px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.placeholder{
          color: #84888c;
        }
      }
      &.height-35{
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0px 8px;
        display: flex;
        align-items: center; 
      }
      &:disabled{
        background-color: #f3f3f4;
        border-color: #f3f3f4;
        opacity: 1;
        pointer-events: none;
      }      
    }
    .dropdown-menu{
      font-family: "OpenSans";
      font-style: normal;
      font-weight: normal;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
      width: 100%;
      border: 1px solid #e0e3e6;
      border-radius: 3px;
      background: #ffffff;
      padding: 0px;
      max-height: 200px;
      overflow: auto;
      &.transform{
        transform: translate(0px, 40px) !important;
      }      
      .dropdown-item{
        font-family: "OpenSans";
        font-size: 14px;
        font-weight: normal;
        line-height: 18px;     
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;           
        padding: 12px 8px;
        color: #1d1e20;
        //border-bottom: 1px solid #e0e3e6;
        border:0px;
        .checked{
          display: none;
        }
        &:last-child{
          border-bottom: 0px;
        }
        &:hover, &:focus{
          background-color: #e9ecef;
        }
        &.active, &:active{
          background-color: transparent;
          font-weight: 600;
          cursor: default;
        }
        &.active{
          .checked{
            display: block;
          }
        }
      }
    }
    &.show{
      .dropdown-toggle{
        border-color: #2caf92;
        background-color: #fff;
        color: #495057;
        svg{
          fill: #2caf92;
        }
      }
    } 
  }
}

.bg-blue-static{
  .transaction-slideout{
    .back-drop{
      background-color: rgba(43, 53, 67, 1);
      opacity: 1;
      pointer-events: none;      
      &.viewer-panel{
        pointer-events: auto;
        &.opacity{
          pointer-events: none;
        }        
      }
    }
    .body-sec{
      .page-heading{
        .action-group{
          .cross{display: none;}
        }
      }
    }
  }  
}


.react-confirm-alert-overlay{
  &.copy-trancsation-alert{
    .react-confirm-alert{
      .react-confirm-alert-body{
        .react-confirm-alert-button-group{
          button{
            border: 1px solid #2caf92;
            &:nth-child(1){
              background: #2caf92;
            }
          }
        }
      }
    }
  }
  .react-confirm-alert{
    .react-confirm-alert-body{
      font-family: "OpenSans";
      padding: 16px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #1D1E20;
      padding-bottom: 24px;
      h1{
        font-family: "OpenSans";
        margin-bottom: 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.16px;
        color: #1D1E20;    
      }
    } 
  }
  &.mark-unpaid{
    .react-confirm-alert{
      .react-confirm-alert-body{
        .react-confirm-alert-button-group{
          button{
            &:nth-child(1){
              background: #2CAF92;
              color: #FFFFFF;
            }
            &:nth-child(2){
              color: #2CAF92;
            }
          }
        }
      }
    }
  }   
}

.accountsPage{
  .body-sec{
    .react-bootstrap-table{
      tbody{
        tr{
          td{
            a{
              color: #1d1e20;
            }
          }
        }
      }
    }
  }
}
.reconcile-status{
  .custom-selectbox{
    &.width-236{
      width:236px;
    }
  } 
  .reconsile-table{
    overflow: auto;
    max-height: calc(100vh - 200px);
    .react-bootstrap-table{
      .table{
        overflow: auto;
        margin-bottom: 0px;
        .badge{
          background: #F4F6F8;
          border-radius: 3px;
          padding: 0px 5px;
          color: #3F4347;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          white-space: nowrap;
          text-align: center;
          overflow: hidden;
          //display: flex;
          align-items: center;
          margin: 0 auto;
          justify-content: center;
          //text-overflow: ellipsis;
          width: auto;
          display: inline-flex!important;
          height:24px;
          &.locked{
            width:auto;
            span{padding-left:2px; opacity: 0.7; margin-top: -3px;}
          }
          &:hover{
            cursor: pointer;
          }
          &.red{
            background: #FFEFED;
            color: #B12A18;
            width: auto;
          }
          &.green{
            background: #DEFFF8;
            color: #0E9074;
            width: auto;
          }
          &.blue{
            background: #E5F5FF;
            color: #065A8C;
            width: auto;
          }
          &.blank{
            background: transparent;
            width: auto;
            &:hover{
              cursor: default;
            }
          }
        }
        thead{
          position: sticky;
          top: 0px;
          z-index: 1;
          th{
            font-family: "OpenSans";
            text-align: center;
            width:auto;  
            padding: 0px 12.5px;         
            &.acct-no,&.ehid,&.acct-type{
              text-align: left;
            }
            &.acct-no{
              width: 150px;
            }
            &.acct-type{
              width:120px;
            }            
            &.month-name{
              width:100px;
            }
            &.ehid{
              width: 80px;
              text-align: center;
            }            
          }
        }
        tbody{
          td{
            overflow: hidden;            
            text-align: center;
            padding: 0px 12.5px;
            font-family: "OpenSans";
            &.acct-no, &.acct-type{
              text-align: left;
            }
            .cursor-pointer{
              &:hover{
                cursor: pointer;
              }
            }
            &.month-name{
              text-overflow: unset;
            }
          }
        }
      }
    }
  }
}  

@mixin thWidth{
  width: 12%;
}

.CCwdTable {
  font-family: "OpenSans";

  thead {
    th.expand-cell-header {
      width: 50px;
    }

    th.ptcDate {
      @include thWidth;
      min-width: 100px;
    }

    th.ptcEhid {
      @include thWidth;
      min-width: 100px;
    }

    th.ptcNotes {
      width: 40%;
    }

    th.ptcAttachments {
      width: 8%;
      min-width: 100px;
    }

    th.ptcTransTotal {
      @include thWidth;
      min-width: 100px;
      text-align: right !important;
    }

    th.ptcIndescription {
      width: 30%;
    }

    th.ptcCl {
      width: 50px;
    }

    th.ptcAp {
      width: 50px;
    }
  }

  tbody {
    .ptcTransTotal {
      text-align: right !important;
    }
  }
}

.CCwdTable {
  &>.react-bootstrap-table {
    &>.table {
      &>tbody {
        &>tr {
          &>td.reset-expansion-style {
            &>div:nth-child(1) {
              border: 0px !important;
              border-radius: 0px !important;
              padding: 0px 0 0px 0 !important;

              &>div,
              &>div.row-expansion-style {
                padding: 0 0px 0 0px;

                &>.react-bootstrap-table {
                  &>.table {
                    thead {
                      height: 35px;

                      th {
                        height: 35px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ccSlideList{
  .react-bootstrap-table{
    .table{
    // th.ehid{
    //   //width: 15%!important;
    // }
    th.coa{
      width: 20%!important;
    }
    th.description{
      width: auto!important;
    }
    th.amount{
      width: 18%!important;
      @media only screen and (max-width: 1500px) {
        width: 20%!important;
      }
    }
  }
}
}

.more-action{
  &.dropdown{
    .dropdown-menu{
      &.noChild-item{
        border: 0px;
      }
    }
  }
}

// for disabled all the controlls
.transaction-slideout{
  .form-control{
    &:read-only{
      background-color: #fff !important;
      border-color: #ced4da !important;
      opacity: 0.7 !important;
      pointer-events: none;
    }
    &:disabled{
      &:hover{
        background-color: #fff !important;
      }
    }
  }
  .date-picker{
    .react-datepicker-wrapper{
      .react-datepicker__input-container{
        .form-control{
          &:read-only{
            background-color: #fff !important;
            border-color: #ced4da;
            opacity: 0.7;
            cursor: no-drop;
          }
        }
      }
    }
  }
  .dropdown{
    .dropdown-toggle{
      &:disabled{
        opacity: 0.7;
        //pointer-events: none;
        border: 1px solid #ced4da !important;
        background: #fff !important;
      }
    }
  }
  .disabled-area{
    pointer-events: unset;
    cursor: not-allowed;
    opacity: 0.7;
    &.parent{
      .disabled-area{
        opacity: 1;
      }
    }
    .disabled-area{
      opacity: 1;
    }
    
    .btn, .form-control{
      &:disabled{
        opacity: 1 !important;
        border: 1px solid #ced4da !important;
        background: #fff !important;
      }
      &:read-only{
        //opacity: 0.5 !important;
        // border: 1px solid #ced4da !important;
        // background-color: #fff !important;
      }
    }
    .dropdown-toggle{
      &:disabled{
        border: 1px solid #ced4da !important;
        //background-color: #fff !important;
      }
    }
    input, .form-control, .btn, .drop-arrow {
      pointer-events: none !important;     
    }
    .calender-picker{
      &:hover{
        .react-datepicker-wrapper{
          .react-datepicker__input-container{
            .form-control{
              border: 1px solid #ced4da !important;
            }
          }
        }  
      }
    }
        
  }
}

.badges{
  &.extraction{
    margin: 32px 32px 0px 32px;
    font-family: "OpenSans";
    color: #065A8C;
    background: #E5F5FF;
    display: block;
    border-radius: 3px;
    padding: 12px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    height: auto;
    .content{
      margin-bottom: 16px;
    }
    .action{
      .btn{
        margin-left: 16px;
      }
    }
  }
}

.total-amount {
  &.ccaAmount {
    &>.form-group {
      margin: 0px!important;
      &>.single-select {
        &>.form-control {
          font-family: "Poppins-Medium";
          font-style: normal;
          font-weight: 400;
          font-size: 20px !important;
          line-height: 20px;
          color: #1d1e20;
          text-align: center;
          width: 190px;
        }

        
      }
    }
    .label {
      font-family: "OpenSans-Semibold";
      font-size: 12px;
      line-height: 16px;
      color: #6a6e73;
      letter-spacing: 0.16px;
      margin-top: 0.5rem
    }
  }
}

.hideInput {
  display: none;
}
.showInput {
  display: block;
}

.invoice-approval-modal{
  .modal-content{
    .modal-header{
      padding: 24px 32px;
      .modal-title{
        color: #1D1E20;
        font-family: "OpenSans";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
      }
    }
    .modal-body{
      padding: 24px 32px;
      .info{
        color: #1D1E20;
        font-family: "OpenSans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
        .bold{
          margin-top:15px;
          font-weight: 600;
        }
      }
    }
    .modal-footer{
      background: #F4F6F8;

    }
  }
  .invoice-approval{
      .card{
        border: 0px;
        box-shadow: none;
        .card-header{
          margin-top: 8px;
          padding: 0px 32px;
          background-color: transparent;
          border-bottom: 0px;
          .btn{
            width:100%;
            font-family: "OpenSans";
            padding: 8px 0px 16px;
            color: #1D1E20;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            height: auto;
            &.activeToggle{
              padding: 16px 0px;
              svg{
                transform: scaleY(-1);
              }
            }
            &:hover, &:focus{
              cursor: pointer;
              text-decoration: none;
            }
          }
        }
        .card-body{
          padding: 0px;
          display: block;
          flex: none;
          ul{
            margin: 0px;
            padding: 0px;
            list-style: none;
            background: #FFFFFF;
            border: 1px solid #E0E3E6;
            box-sizing: border-box;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.03);
            border-radius: 3px;
            li{
              border-bottom: 1px solid #E0E3E6;
              font-family: "OpenSans";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              padding: 12px 16px;
              .badge{
                background: #F4F6F8;
                border-radius: 3px;
                color: #3F4347;
                letter-spacing: 0.16px;
                font-family: "OpenSans";
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                margin-right: 10px;
                &.orange{
                  background: #FFF3E8;
                  color: #863902;
                }
                &.green{
                  background: #DEFFF8;
                  color: #0E9074;
                }
              }
              &:last-child{
                border-bottom: 0px;
              }
              .completed{
                text-decoration-line: line-through;
                color: #84888C;
              }
            }
          }
        }
      }
  }
}
.banner{
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  height:48px;
  &.orange-bg, &.blue-bg {
    font-family: "OpenSans";
    font-size: 14px;
    color: #863902;
    line-height: 40px;
    background-color: #fff3e8;
    &.margin{
      padding: 0px 32px;
    }
    .bold{
      font-family: "OpenSans-Semibold";
    }
  }
  &.blue-bg{
    height:48px;
    margin-left: 0px;
    background: #e5f5ff;
    color: #065a8c;
  }
}

.accounts-tableSmall,.accounts-tableWide { thead { th { z-index: 1;}}}
@media only screen and (max-width: 1500px) {
  .transaction-slideout{
    .journalvendor{
      min-width: 200px;
    }
    .container-fluid{
      padding-left: 0px !important;
      padding-right: 0px !important;
      &.body-sec{
        width: 80%;
        &.viewer-panel{
          width: 60%;
        }
        .page-heading{
          padding: 18px 22px;
        }
        .filter-sec{
          padding: 22px 22px 5px;
        }
        .transactions-list{
          padding: 22px 22px 13px 22px;
          &.inpute{
            .react-bootstrap-table{
              table{
                table-layout: fixed;
              }
            }
          }
        }
        .footer-section{
          width: 80%;
          &.viewer-panel{
            width: 60%;
          }
        }
      }
    }
    .back-drop{ 
      width: 20%;
      &.viewer-panel{
        width: 40%;
        .scroll-area{
          .zoom-btn{
            left: 35%;
          }
        }
        .footer-section{
          width: 40%;
        }
        .header-panel, .footer-section{
          padding: 0px 15px;
        }
        .scroll-area{
          .custom-page-action{
            .page-ttl, .page-count{
              position: relative;
            }
            // .action{
            //   .more-action{
            //     .dropdown-menu{
            //       // &.show{
            //       //   //right: 147px;
            //       // }
            //     } 
            //   }
            // }
          }
          &.doc-sec{
            overflow-x: hidden;
          }
        }
      }
    }
  }
  .accounts-tableWide,.accounts-tableSmall {
    &>.react-bootstrap-table {
      &>.table {
         table-layout: fixed!important; 
      }
    }
  }
  .back-drop{
    &.viewer-panel{
      .scroll-area{
        .react-pdf__Document{
          .custom-page-action{
            .action{
              .more-action{
                .dropdown-menu{
                  transform: none !important;
                  inset: 33px 0px auto auto !important;
                  //inset: auto !important;
                  top: 33px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.rbt-menu{
  &.dropdown-menu{
    .dropdown-item{
      &:hover, &:focus, &.active{
        background-color: #f4f6f8;
        cursor: default;
      }
    }
  }
}


.hasDisabledArea{
  pointer-events: none;
  opacity: 1;
  &.enabledFeild{
    pointer-events:auto;
    .form-control, .dropdown{
      pointer-events: none;
      opacity: 0.5;
    }
    .enable-controll{
      .form-control, .dropdown{
        pointer-events: auto;
        opacity: 1;
      }
    }
  }
}


