.breadcrumb-mrgn{margin-top:48px;}
.breadcrumb{
    background-color: #fff;
    margin-bottom: 0px;
    border-radius: 0px;
    padding-left: 0px;
    .breadcrumb-item{
        a{
            color: #c4cdd5;
            &:hover{
                color: #2a394f;
            }
        }
        &.active{
            color: #2a394f;
        }
        &::before{
            content: '>';
            color: #C4CDD5;
        }
        &:first-child{
            &::before{
                content: '';
            }
        }
    }
    
}