 @import "bootstrap/dist/css/bootstrap.min.css";
@import "./Common/Assets/Styles/theme.scss";
@import "./Common/Assets/Styles/main.scss";
@import "./Common/Assets/Styles/config";



@import "./Common/Assets/Styles/font";
@import "./Common/Assets/Styles/navbar";
@import "./Common/Assets/Styles/breadcrumbs";
@import "./Common/Assets/Styles/pageTitle";
@import "./Common/Assets/Styles/buttons";
@import "./Common/Assets/Styles/table";
@import "./Common/Assets/Styles/alerts";
@import "./Common/Assets/Styles/_pagination";
@import "~video-react/styles/scss/video-react";
@import 'react-datepicker/dist/react-datepicker';


// .btn-link {
//   color: #15b79e;
//   font-family: Inter-SemiBold;
//   font-size: 14px;
//   line-height: 20px;
// }

.divgreen 
{
  color: #15b79e!important;
  font-weight:600 !important;

}

.btn.btn-primary.btn-link {
  background-color: transparent;
  color: #164da0;
  border-color: transparent;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
font-size: 14px;

  cursor: pointer;
  padding: 0px;
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    text-decoration: underline;
    cursor: pointer;
  }
  
}

.divbottom {
  margin-bottom: 5em
  
}
.columns-3 {
  width: 100%;
  display:flex;
}
/* columns */
.columns-3 > * {
  width: calc(100% / 3);
  text-align: center;
}
.columns-4 {
  width: 100%;
  display:flex;
}
/* columns */
.columns-4 > * {
  width: calc(100% / 5);
  text-align: center;
}
.columns-20 {
  width: 20%;
  display:flex;
}
.columns-80 {
  width: 70%;
  display:flex;
}

.l-flex {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.l-flex {
  overflow-x: hidden;
  overflow-y: hidden;
}

.l-fg3 {
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 600px) {
    margin: -10px;    
  }
  
  > div {
    padding: 1.5rem;
    flex-basis: 100%;
    @media (min-width: 600px) {
      margin: 10px;
      flex-basis: calc(33.333% - 20px);      
    }
  }
  
  > div + div {
    @media (max-width: 599px) {
      margin-top: 20px;      
    }
  }
  
  > div:nth-child(2),
  > div:nth-child(2) {
    @media (min-width: 600px) {
      flex-basis: calc(60% - 20px);
    }
  }
  
  > div:nth-child(1) {
    background: rgba(rgb(120, 120, 120), 0.25);
  }

  > div:nth-child(2) {
    background: rgba(rgb(255, 255, 255), 0.25);
  }
  
  > div:nth-child(3) {
    background: rgba(blue, 0.25);
  }
  
  > div:nth-child(4) {
    background: rgba(blue, 0.25);
  }
  
  > div:nth-child(5) {
    background: rgba(blue, 0.25);
  }

}

.l-fg1 {
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 600px) {
    margin: -10px;    
  }
  

  > div {
   background: rgba(rgb(211, 211, 214), 0.25);
    padding: 1.5rem;
    flex-basis: 100%;
    @media (min-width: 600px) {
      margin: 10px;
      flex-basis: calc(100% - 20px);      
    }
  }

}
.transactions-list{
  .tableHeader{
    font-weight: normal;
    background-color: var(--bs-light) !important;
    color: var(--bs-secondary) !important;
    font-weight: 500;
  }
  .tableList{
    ul{
      li{
        height: 48px;
        .list{
          .loadingAnimation{
            width:90%;
          }
            width: 15%;
            &:last-child{
              width:10%;
              .loadingAnimation{
                width:90%;
                margin-left: 10%;
              }
            }
        }
        &:last-child{
          border-bottom:0px;
        }
      }
    }
  }
}

 

.growth {
  width: 100vw;
  height: 100vh;
  background-color: red;
   
 }
 .scroll
{
  height: 100% !important;
  overflow: auto !important;
  padding: 20px;
}

.search-control-height
{
 height:325px !important;
}

.search-input-height
{
 height:30px !important;
}

.d-flex {
  display: flex !important;
}

 
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(var(--bs-secondary-rgb), 0.7) transparent;
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}
.mt-3 {
  margin-top: 1rem !important;
}

// @media (max-width: 767px) {
//   .table-responsive .dropdown-menu,
//   .table-responsive .dropdown-toggle {
//         position: static !important;
//   }
// }

// @media (min-width: 768px) {
//     .table-responsive {
//         overflow: visible;
//     }
// }
.my-class {
  overflow: inherit !important;
}
.table-responsiveadd {
  overflow-x: auto;
  -webkit-overflow-scrolling: auto;
}
.table-responsiveremove {
  overflow-y: visible !important;
}
.table-responsiveadd:hover {
  overflow-x: auto;
  -webkit-overflow-scrolling: auto;
}
// .table-responsive {
//   overflow-y: visible !important;
// }
// .table-responsive .dropdown-menu {
     
// //  position: absolute !important;
//   z-index: 3;
// }
// .table-responsive.res-drop {height:500px!important;}
// /* width */
// ::-webkit-scrollbar {
//   width: 10px;
// }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.my-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
 // overflow-y: auto!important;
  display: flex;
  flex-direction: column;
  position: fixed;
}
.my-header {
  display: flex;
  align-items: center;
 // padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
border-bottom: "1px solid #fff" ;
box-shadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)";
padding: 25px;
              //   borderBottom: "1px solid #fff",
              //   boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
              //   padding: "25px",
}
// .offcanvas {
//   position: fixed;
//   bottom: 0;
//   z-index: 1006;
//   //var(--bs-offcanvas-zindex);
//   display: flex;
//   flex-direction: column;
//   max-width: 100%;
//   color: var(--bs-offcanvas-color);
//   visibility: hidden;
//   background-color: var(--bs-offcanvas-bg);
//   background-clip: padding-box;
//   outline: 0;
//   transition: var(--bs-offcanvas-transition);
 
// }
// .offcanvas-header {
//   display: flex;
//   align-items: center;
//   padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
// }

// .offcanvas-body1 {
//   flex-grow: 1;
//    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
//   overflow-y: auto;
// }

// .offcanvas-backdrop.show {
//   opacity: 0.5;
// }
 
// .offcanvas-backdrop.fade {
//   opacity: 0;
// }
 
// .offcanvas-backdrop {
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 1040;
//   width: 100vw;
//   height: 100vh;
//   background-color: #000;
// }
.back-drop.viewer-panel {
  background: #ffffff;
  right: auto;
  right: 0;
  top:62.50px;
  transition: opacity .3s ease-out;
  width: 75%;
  border-color: #1d1e20;
  overflow: hidden!important;
}

.back-drop.viewer-panel-left {
  background: #ffffff;
  right: auto;
  left: 0;
  top:62.50px;
  transition: opacity .3s ease-out;
  width: 25%;
  border-color: #1d1e20;
  overflow: hidden!important;
}

.divheader {

  // background-color: var(--bs-light) !important;
  width: 115%;
  display: flex;
  padding: 10px;
  margin: 0;
}
.my-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
  
}
.back-drop, .pos-fxd {
  bottom: 0;
  position: fixed;
  right: 0;
  top:62.50px;
}
.back-drop1 {
  position: fixed;
  background-color: rgba(58, 58, 62, 0.4);
  width: 100%;
  top:62.50px;
  left: 0px;
  bottom: 0px;
  overflow: hidden;

      .scroll-area1{
          position: relative;
          height: calc(100vh - 88px);
          padding: 0px 32px 15px; 
          overflow-y: auto;
          overflow-x: hidden;
          &.doc-sec{
            overflow-x: auto;
            padding: 0px 0px 15px;
              .custom-page-action{
                &.doc-header{
                  margin: 10px 0px;
                  padding: 0px 32px;
                  background: #333F4F;
                  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
                  height:45px;            
                }
                .doc-panel{
                  padding: 0px 32px;
                  width: 200px;
                }
              }       
          }}
          &.doc-sec{
            overflow-x: auto;
            padding: 0px 0px 15px;
              .custom-page-action{
                &.doc-header{
                  margin: 10px 0px;
                  padding: 0px 32px;
                  background: #333F4F;
                  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
                  height:45px;            
                }
                .doc-panel{
                  padding: 0px 32px;
                }
              }       
          }
    
}
.pos-fxd {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.index-1000 {
    z-index: 1006;
  }

  &.index-1010 {
    z-index: 1002;
  }
}
.filter-sec{
  padding:32px 32px 40px;
  background: #ffffff;
  border-bottom: 1px solid #e0e3e6;
  transition: all 0.6s ease;
  &.upload-section{
    background: transparent;
    border-bottom: 0px;
  }
}

.form-group{
  margin-right: 24px;
  font-family: "OpenSans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #1d1e20;
  .form-label{
    font-family: "OpenSans-Semibold";
  }
  .form-control{
    font-size: 14px;
    line-height: 18px;
    color: #1d1e20;
    padding: 10px 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.invalid-Invoice{
      border-color: #dc3545;
    }
  }
}


 /** CSS for rect overlaymodal **/
.react-confirm-alert-overlay {
  z-index: 1200;

  .react-confirm-alert {
    .react-confirm-alert-body {
      font-family: "OpenSans";
      font-style: normal;
      font-size: 14px;
      width: 400px;
      padding: 15px;
      text-align: left;
      background: #fff;
      border-radius: 3px;
      border: 1px solid #e0e3e6;
      box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
      color: #1d1e20;
      z-index: 1205;

      h1 {
        font-family: "OpenSans-Semibold";
        font-size: 16px;
        letter-spacing: 0.16px;
      }

      .react-confirm-alert-button-group {
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
        padding-top: 10px;
        flex-direction: row-reverse;
        border-top: 1px solid #e0e3e6;

        & > button:nth-child(1) {
          font-family: "OpenSans";
          font-weight: 600;
          background: #2CAF92;
          border-radius: 3px;
        }

        & > button:nth-child(2) {
          font-family: "OpenSans";
          font-weight: 600;
          background: none !important;
          color: #1d1e20;
        }
      }
    }
  }
  &.horizontalScrollAlert {
    .react-confirm-alert-button-group {
      .leave-without-recalc {
        background: #2caf92 !important;
      }
    }
  }
}

.body-sec {
  .page-heading {
    color: #1d1e20;
    font-family: "Poppins-Medium";
    font-size: 20px;
    line-height: 32px;
    padding-bottom: 16px;
    align-items: center;
    height: 72px;
    width: "75%"!important;        
   
    &.underline {
      border-bottom: 1px solid #e0e3e6;
    }
    .height-40 {
      height: 40px;
    }
    .tag-line {
      font-family: "OpenSans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #6a6e73;
    }
    &.mrgn-btn-24 {
      margin-bottom: 24px;
      z-index: 0;
    }
    &.sticky-top {
      position: sticky;
      top:0;
      left:0;
      right: 0;
      
      z-index: 2;
    }
  }

  .sub-page-heading {
    font-size: 12px;
    margin-top: 5px;
    font-family: "Poppins-Medium";
  }

  .sub-page-section {
    font-size: 12px;
    margin-top: 15px;
    font-family: "Poppins-Medium";
    line-height: 18px;
    border-bottom: 1px solid #e5e5e6;
  }
}
.back-drop.viewer-panel .footer-section {
  background-color: var(--bs-light) !important;
  border-radius: 0 0 3px 0;
  bottom: 0;
  box-shadow: 0 0 6px #0006;
  height: 60px;
 // margin-top: 50px;
  padding: 10px 32px;
  position: fixed;
  right: auto;
  right: 0;
  width: 75%;
  z-index: 1;
}

.footer-section-new {
  background-color: var(--bs-light) !important;
  border-radius: 0 0 3px 0;
  bottom: 0;
  box-shadow: 0 0 6px #0006;
  height: 60px;
 // margin-top: 50px;
  padding: 10px 32px;
  position: fixed;
  right: auto;
  right: 0;
  width: 75%;
  z-index: 1;
}
.small-width {
  .body-sec {
    .page-heading {
      padding-left: 39px;
    }
  }
}
.tabfocus {
  border-color: #28a745;
  
} 

input:focus, textarea:focus{
  box-shadow: none !important;
}

.tabborder{

  box-shadow: none !important;
}
.formsselect {
  display: block;
  width: 100%;
  //padding: 0.375rem 0.75rem;
  margin-left: 2%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
 
// .nav-tabs {
//   border-color: #e0e3e6;
//   padding-bottom: 1px;
// }

// .btn-primary {
//     /* --bs-btn-color: #fff; */
//     /* --bs-btn-bg: #1d3be2; */
//     /* --bs-btn-border-color: #1d3be2; */
//     /* --bs-btn-hover-color: #fff; */
//     /* --bs-btn-hover-bg: #1932c0; */
//     /* --bs-btn-hover-border-color: #172fb5; */
//     /* --bs-btn-focus-shadow-rgb: 63, 88, 230; */
//     /* --bs-btn-active-color: #fff; */
//     /* --bs-btn-active-bg: #172fb5; */
//     /* --bs-btn-active-border-color: #162caa; */
//     /* --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); */
//     /* --bs-btn-disabled-color: #fff; */
//     /* --bs-btn-disabled-bg: #1d3be2; */
//     /* --bs-btn-disabled-border-color: #1d3be2; */
// }
// .nav-tabs {
//   border-bottom: 1px solid #ffffff;
// }
// .nav {
//   display: flex;
//   flex-wrap: wrap;
//   list-style: none;
//   margin-bottom: 0;
//    box-shadow: none !important;
//   padding-left: 0;
// }
 
// .nav-tabs .nav-link.active, .nav-tabs , .nav-tabs  {
//   border-color: #120336;
//   color: #1d1e20;
//   box-shadow: none !important;
// }
// .nav-item {
// .nav-link {
//   border-color: #f4f6f8;
//   &.active {
//    // background-color: #13599f;
//   //  border-color: #2caf92!important;
//   background-color: rgba(var(--bs-white-rgb), 0.1);
//   }
//   &:hover {
//     //border-color: #2caf92!important;
//     background-color: rgba(var(--bs-white-rgb), 0.03);
//   }
// }
// }
.topmargin {
padding-top: 10%;


}
.bottommargin {
  padding-bottom: 10%;
  
  
  }
.dropcustom {
  .single-select {
    width: 100%;

    .dropdown-toggle {
      background: #fff;
      background-color: #fff;
       //height: 30px;

      &::after {
        content: "";
        border: solid #1d1e20;
        border-width: 0 1.6px 1.6px 0;
        display: inline-block;
        // padding: 0.15em;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        position: absolute;
        right: 10px;
        //top: 5px;
       // margin-bottom: 2px;
      }

      &:focus {
        background-color: transparent;
      }

      &:disabled {
        background-color: #f3f3f4;
      }
    }

    .search-sec {
      .form-control {
        width: 100% !important;
        border-color: #ced4da;

        &:focus {
          border-color: #2caf92 !important;
        }
      }
    }
  }
}

// .transaction-slideout .container-fluid.body-sec .filter-sec .form-group .single-select .single-select-image {
//   width: 169px;
// }

// .transaction-slideout .container-fluid.body-sec .filter-sec .form-group {
//   color: #1d1e20;
//   font-family: OpenSans;
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 18px;
//   margin-right: 24px;
// }
// .filter-btn {
//   max-width: 110px;
//   .drop-arrow {
//     position: relative;
//     left: 4px;
//     right: 0;
//     top: 0;
//   }
//   &.dropdown-toggle {
//     &:hover {
//       background-color: #f4f6f8 !important;
//       border-color: #f4f6f8 !important;
//       color: #1d1e20 !important;
//       svg {
//         stroke: #1d1e20 !important;
//       }
//     }
//   }
// }
// .caret {
//   display: none;
//   }

//   .dropdown-toggle::after {
//     display: inline-block;
//     margin-left: 0.255em;
//     vertical-align: 0.255em;
//     content: "";
//     border-top: 0.3em solid;
//     border-right: 0.3em solid transparent;
//     border-bottom: 0;
//     border-left: 0.3em solid transparent;
// }

// .dropdown-toggle {
//   .drop-arrow {
//     //margin-left: 5px;
//     svg {
//       fill: none !important;
//       stroke: #fff !important;
//       //margin-right: 0;
//     }
//   }
//   &:hover {
//     background-color: #603736 !important;
//     border-color: #364760 !important;
//     color: #fff !important;

//     svg {
//       fill: #fff !important;
//     }

//     .drop-arrow {
//       //margin-left: 5px;
//       svg {
//         fill: none !important;
//         stroke: #fff !important;
//         //margin-right: 0;
//       }
//     }
//   }
// }
.txt-area {
  max-width: 100%;
}
// .show {
//   .dropdown-toggle {
//     &:hover {
//       background-color: #364760 !important;
//       border-color: #364760 !important;
//       color: #fff !important;
//       svg {
//         stroke: #fff !important;
//       }
//     }
//   }
// }
 
// .form-control {
//   background-clip: padding-box;
//   background-color: #fff;
//   border: 1px solid #ced4da;
//   border-radius: .25rem;
//   color: #495057;
//   display: block;
//   font-size: 1rem;
//   font-weight: 400;
//   height: calc(1.5em + .75rem + 2px);
//   line-height: 1.5;
//   padding: .375rem .75rem;
//   transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
//   width: 100%;
// }
button, input {
  overflow: visible;
}
button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}
.sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
 .page-heading {
  align-items: center;
  color: #1d1e20;
  font-family: Poppins-Medium;
  font-size: 28px;
  line-height: 32px;
  padding-bottom: 26px;
  
}
.mr-auto {
  letter-spacing: 0.16px;
  font-family: "Poppins-Medium";

  line-height: 18px;
  padding: 3px 6px;
  font-weight: bolder;
}

.action-group{
  .custom-switch{
    margin-right:20px;
   
  }
  .cross{
    margin-left: 24px;
    height: 32px;
    width: 32px;
    
  }
}
.pos-sticky{
  position: sticky;
  top:0;
  left:0;
  right: 0;
  background-color: #fff;
  z-index: 2;
}
.d-flex {
  display: flex !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}
.ml-auto, .mx-auto {
  margin-left: auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.rightbutton {
  display: block;
  margin-left: auto;
  margin-right: 0;
}

.login-bg {
  overflow-x: hidden;
  
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
}
.base-white {
  background-color: transparent;
  height: 100vh;

  .card {
    //box-shadow: none;
    .card-heading,
    .card-footer {
      border: 0px;
      background-color: transparent;
    }
  }

  &.marketing {
    background-color: #fff;
  }
}
.margingaddrow {
  margin-bottom: 100px;
}

.transactions-list .split-assign-coa {
  position: relative;
}
.upload-section {
  display: flex;
  padding: 0 12px 13px;
}
.login-body {
  height: 100vh;
  color: #fff;
  justify-content: center;
  padding: 65px;

  .title {
    font-family: "Poppins-Medium";
    font-size: 28px;
    margin-bottom: 24px;
    line-height: 18px;
  }

  .desc {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 2rem;
  }

  &.height-1 {
    height: 100vh;
  }
}
.align-items-center {
  justify-content: start;
}

.login {
  .login-container {
    height: calc(100vh - 25px);
    margin-bottom: 25px;
    &.login {
      height: calc(100vh - 65px);
    }
    .action-link {
      font-family: "OpenSans";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #065a8c;
      &:hover {
        color: #065a8c;
        text-decoration: underline;
      }
    }
  }
  .help {
    color: #6a6e73;
    font-family: "OpenSans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    span {
      
      margin-right: 5px;
    }
    .btn-primary {
      background-color: transparent;
      font-family: "OpenSans";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      padding: 0px;
      color: #065a8c;
      border: 0px;
      &:hover,
      &:focus,
      .active,
      &:not(:disabled):not(.disabled):active {
        background-color: transparent;
        color: #065a8c;
        border: 0px;
        text-decoration: underline;
      }
    }
  }

  .brand-img {
    text-align: center;
    margin-bottom: 32px;
  }

  .lg-btn {
    margin: 32px auto 0px;
    display: block;
    width: 80%;

    .fa-lock {
      margin-right: 5px;
    }
  }
}

 
 
    .scroll-area{
      position: relative;
      height: calc(100vh - 88px);
      padding: 0px 32px 15px; 
      overflow-y: auto;
      overflow-x: hidden;
      &.doc-sec{
        overflow-x: auto;
        padding: 0px 0px 15px;
          .custom-page-action{
            &.doc-header{
              margin: 10px 0px;
              padding: 0px 32px;
              background: #333F4F;
              box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
              height:45px;            
            }
            .doc-panel{
              padding: 0px 32px;
            }
          }       
      }
      &.modal-open{
        overflow-y: hidden;
      }
      .footer-enable{
        margin-bottom: 90px;
      }
      .react-pdf__Document{
        .custom-checkbox{
          .custom-control-label{
            cursor: pointer;
            &::before{
              background-color: transparent;
            }
          }
          .custom-control-input:indeterminate ~ .custom-control-label::before {
            border-color: #2daf92;
            background-color: #2daf92;
          }
          .custom-control-input:checked ~ .custom-control-label::before {
            border-color: #2daf92;
            background-color: #2daf92;
          }
        }      
        .react-pdf__Page{       
          &:last-child{
            border-bottom:0px;
          }
          canvas{
            //transition: all 0.6s ease;
            margin: 0 auto;
            // width: 100% !important;
            // height: auto !important;
            //min-width: 100%;
          }
        }
        .custom-page-action{
          height:32px;
          margin:10px 0px;
          .page-ttl, .page-count{
            color: #CACED2;
            letter-spacing: 0.16px;
            font-family: "OpenSans";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 33%;
          }
          .page-ttl{
            .custom-control-label{
              width: 100%;
            }
          }
          .page-count{
            font-size: 12px;
            line-height: 18px;
            font-weight: normal;
          }
          .action{
            .btn{
              height:32px;              
            }
            .more-action{
              margin-left: 15px;
              .btn-outline-primary{
                background:transparent;
                border-color: transparent;
                color: #2CAF92;
                font-family: "OpenSans";
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                // &:hover, &:active, &:not(:disabled):not(.disabled):active {
                //   background: #364760;
                //   border-color: #364760;
                // }
              }
              &.show, &:hover, &:active, &:not(:disabled):not(.disabled):active{
                .btn-outline-primary{               
                  background: #364760;
                  border-color: #364760;
                  color:#fff;
                }                
              }
              &.dropdown{
                .dropdown-menu{
                  .dropdown-header{
                    font-family: "OpenSans-Semibold";
                    color: #6A6E73;
                    font-size: 10px;
                    line-height: 16px;
                    letter-spacing: 1px;
                    padding: 13px 17px 12px;
                    text-transform: uppercase;
                  }
                  .dropdown-item{
                    border-bottom:0px;
                  }
                } 
              } 
            }
          }
        }
        .grid-view{
          display: inline-block;       
          margin-right: 32px;
          margin-bottom: 7px;        
          .custom-page-action{
            .action{
              position: relative;
            }
            .page-count{
              max-width: 50%;
            }
          }
          .react-pdf__Page{
            width:181px;
            height:213px;
            overflow: hidden;
            canvas{
              width: 100% !important;
              height:auto !important;
            }
          }        
        }
      }
      .grid-sec{
        border-bottom: 1px solid rgba(224, 227, 230, 0.2);
      }
      .custom-error-msg{
        padding: 2px 0px;
        font-family: "OpenSans";
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        margin-right: 15px;
      }
      .email-attachement{
        margin: 10px 0px;
        .link-btn{
          font-size: 16px;
          line-height: 18px;
          height: auto;
        }
        .info{
          margin-top:2px;
          color:#cfa72a;
          font-size: 12px;
          line-height: 18px;
          font-weight: normal;
          letter-spacing: 0.16px;
          font-family: "OpenSans-Bold";
          //font-family: "OpenSans";
          font-style: normal;
        }
        &.doc-view{
          margin: 20px 32px;
          padding: 10px 0px 0px;
          border-top: 1px solid rgba(224, 227, 230, 0.2);
        }
      }
      .zoom-btn{
        position: fixed;
        bottom: 70px;
        left: 40%;
        border-radius: 3px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
        .btn{
          //padding: 0px 10px;
          padding: 0px 6px;
          height: 30px;
        }
      }
    }       
 
 
 

.react-confirm-alert-overlay{
  &.confirm-modal{
    .react-confirm-alert-button-group{
      .btn{
        padding: 6px 18px;
        height: 30px;
        color: #fff;
        &:hover{
          color:#fff;
        }
      }
      .upload-link{
        position: relative;
        &.mrgn-top{
          margin-top: 0.5rem;
        }
        .btn{
          height: 30px;
          color:#3f4347;
          &:hover{
            color:#3f4347;
          }
        }
        .form-control-file{
            top: 0px;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            cursor: pointer;
            font-size: 0;
            position: absolute;            
        }          
      }
    }    
  }
}
 
.loader-spinner{
  z-index: 2;
  background-color: rgba(0,0,0,0.6);
  .spinner-border{
    &.text-success{
      color: #2caf92 !important;
    }
  } 
}
 

 
 
 

.react-confirm-alert-overlay{
  &.copy-trancsation-alert{
    .react-confirm-alert{
      .react-confirm-alert-body{
        .react-confirm-alert-button-group{
          button{
            border: 1px solid #2caf92;
            &:nth-child(1){
              background: #2caf92;
            }
          }
        }
      }
    }
  }
  .react-confirm-alert{
    .react-confirm-alert-body{
      font-family: "OpenSans";
      padding: 16px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #1D1E20;
      padding-bottom: 24px;
      h1{
        font-family: "OpenSans";
        margin-bottom: 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.16px;
        color: #1D1E20;    
      }
    } 
  }
  &.mark-unpaid{
    .react-confirm-alert{
      .react-confirm-alert-body{
        .react-confirm-alert-button-group{
          button{
            &:nth-child(1){
              background: #2CAF92;
              color: #FFFFFF;
            }
            &:nth-child(2){
              color: #2CAF92;
            }
          }
        }
      }
    }
  }   
}

 

 

 

 
 
 
 
 
 

 
 

 

 

//*****Navigation Menus Ends*****//

//*****Color Variables*****//
$color-green1: #2caf92;
$color-white: #fff;
$color-grey80: #1d1e20;

//*****SASS Global Templates*****//
%textColor {
  font-family: "OpenSans-Semibold";
  font-size: 14px;
  line-height: 18px;
  display: block;
  color: #b12a18;
}
//*****SASS Global Templates*****//

//*****Global Mixin*****//
@mixin customtooltipProp {
  display: none;
  font-family: "OpenSans";
  font-size: 14px;
  color: #fff;
  background-color: rgba(54, 71, 96, 0.95);
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  width: 148px;
  white-space: pre-wrap;
  text-align: left;
  padding: 8px 10px;
  transform: translate(-50%);
  border-radius: 5px;
  position: absolute;
  top: 24px;
  transition: opacity 0.3s ease-out;
  z-index: 10;
}
@mixin customtooltipPropBefore {
  content: "";
  position: absolute;
  left: 50%;
  right: 50%;
  margin-left: 0px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent #364760 transparent;
}
@mixin reactComponentTooltipProp {
  font-family: "OpenSans";
  font-size: 14px;
  background-color: #364760;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  width: 148px;
  white-space: pre-wrap;
  text-align: left;
  padding: 8px 10px;
}
@mixin customCheckBoxFullHalfCheckedProp {
  .form-check-input {
    margin-top: 0;
    margin-right: 8px;
  }
  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #c7c8ca;
    padding: 8px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    top: 2px;
    outline: none;
    cursor: pointer;

    &:disabled {
      background-color: #84888c !important;
      border: 1px solid #84888c !important;
      cursor: not-allowed !important;
    }

    &:checked {
      background-color: #2caf92;
      border: 1px solid #2caf92;
    }

    &:checked:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 5px;
      display: block;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    &:indeterminate {
      background-color: #2caf92;
      border: 1px solid #2caf92;
    }

    &:indeterminate:after {
      content: "";
      position: absolute;
      top: 8px;
      left: 3px;
      display: block;
      width: 10px;
      height: 10px;
      border: solid #fff;
      border-width: 2px 0 0 0;
      transform: rotate(0deg);
    }
  }
  .form-check-label {
    margin-top: 14px;
    cursor: pointer;
  }
}
//**overview**//
@mixin TextAreaClearBtn {
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  position: absolute;
  bottom: 70px;
  right: 0;
  cursor: pointer;
  &::after {
    content: "\000D7";
    display: inline-block;
    justify-content: center;
    width: 20px;
    height: 20px;
    position: absolute;
    background-color: #5acfb5;
    z-index: 1;
    right: 22px;
    top: -6px;
    bottom: 0;
    margin: auto;
    padding: 2px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    font-weight: normal;
    font-size: 16px;
    cursor: pointer;
    font-family: "OpenSans";
  }
  &.btn {
    &:focus {
      background-color: transparent;
      border: 1px solid transparent;
    }
  }
}
//**Schedule**//
@mixin dropdownToggleAfter {
  content: "";
  border: 1px solid #84888c;
  border-width: 0 0.1em 0.1em 0;
  display: inline-block;
  padding: 0.15em;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  right: 16px;
  top: 16px;
}
//*****Global Mixin*****//

.html-time-picker {
  background-color: transparent;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #1d1e20;
  font-family: "OpenSans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  height: 40px;
  padding: 0px 16px;
  width: 100%;
  outline: 0px;

  &:focus {
    border: 1px solid #2caf92;
  }
}
.time-picker {
  &.form-group {
    margin-bottom: 0px;
  }
  &.modf-input {
    color: #1d1e20;
    font-family: "OpenSans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    background-color: transparent;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: 40px;
    padding: 0px 16px;
    width: 100px;
    .form-control {
      border: 0px;
      padding: 0px;
      height: auto;
      width: 29px;
      text-align: center;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    span {
      margin-right: 2px;
    }
  }
}

.transition {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.noPointer {
  pointer-events: none !important;
}

 

 
.body-sec {
  &.container-fluid {
    &.pos-rel {
      position: relative;
    }
    .tab-content {
      .section {
        .left-panel {
          .left-body {
            .scrollDiv {
              background: #ffffff;
              border: 1px solid #e0e3e6;
            }
          }
          .search-header {
            .leftFilterDiv {
              position: relative;
              svg {
                position: absolute;
                top: 11px;
                left: 10px;
              }
              .search-label {
                input {
                  text-indent: 24px;
                }
              }
            }
          }
        }
        .right-panel {
          .pos-header {
            .more-action {
              .moreActionRolePermission {
                padding-top: 10px !important;
              }
              .rolePermissionMoreFilter {
                font-family: "OpenSans";
                font-size: 14px;
                display: block;
                top: 56px;
                left: auto;
                height: 80px;
                background: #fff;
                border: 1px solid #e0e3e6;
                box-sizing: border-box;
                box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
                border-radius: 3px;
                .form-group {
                  label {
                    svg {
                      margin-right: 4px;
                    }
                  }
                }
              }
              .rolePermissionMoreFilterforAdmin {
                font-family: "OpenSans";
                font-size: 14px;
                height: 40px;
                display: block;
                top: 56px;
                left: auto;
                background: #ffffff;
                border: 1px solid #e0e3e6;
                box-sizing: border-box;
                box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
                border-radius: 3px;
                .form-group {
                  label {
                    border-bottom: 1px solid #e0e3e6;
                    margin-top: -5px;
                    svg {
                      margin-right: 4px;
                    }
                  }
                }
              }
              .moreDropDownRolePermission {
                display: block;
                top: 56px;
                left: auto;
                margin-right: -35px;
                width: 350px;
                .deleteFormGroupRP {
                  padding: 16px;
                  &.form-group {
                    margin-bottom: 0;
                  }
                  label {
                    margin-bottom: 0;
                    .deleteRoleTitle {
                      font-family: "OpenSans-Semibold";
                      font-size: 14px;
                      line-height: 18px;
                      color: #1d1e20;
                      letter-spacing: 0.16px;
                    }
                    .deleteRoleMsg {
                      font-family: "OpenSans";
                      font-size: 14px;
                      line-height: 18px;
                      color: #1d1e20;
                    }
                  }
                  .dropdown {
                    .newassignrole {
                      background-color: #fff !important;
                      border: 1px solid #caced2 !important;
                      width: 100% !important;
                      &.dropdown-toggle {
                        &::after {
                          border: solid #84888c;
                          border-width: 0 0.1em 0.1em 0;
                          display: inline-block;
                          padding: 0.15em;
                          transform: rotate(45deg);
                          -webkit-transform: rotate(45deg);
                          position: absolute;
                          right: 16px;
                          top: 16px;
                        }
                      }
                      .selectedRole {
                        font-family: "OpenSans";
                        color: #1d1e20;
                        float: left;
                      }
                    }
                    .newassignroledanger {
                      background-color: #ffefed !important;
                      border: 1px solid #b12a18 !important;
                      width: 100% !important;
                      &.dropdown-toggle {
                        &::after {
                          border: solid #84888c;
                          border-width: 0 0.1em 0.1em 0;
                          display: inline-block;
                          padding: 0.15em;
                          transform: rotate(45deg);
                          -webkit-transform: rotate(45deg);
                          position: absolute;
                          right: 16px;
                          top: 16px;
                        }
                      }
                      .selectedRole {
                        font-family: "OpenSans";
                        color: #1d1e20;
                        float: left;
                      }
                    }
                    .newassigndroplist {
                      width: 100%;
                      max-height: 300px;
                      overflow-y: auto;
                      box-sizing: border-box;
                      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
                      &.dropdown-menu {
                        &.show {
                          .dropdown-item {
                            font-family: "OpenSans";
                            color: #1d1e20;
                            font-size: 14px;
                          }
                        }
                      }
                    }
                  }
                }
                .formGroupDeleteRP {
                  padding: 16px;
                  border-top: 1px solid #e0e3e6;
                  label {
                    margin-bottom: 0;
                    .btn-danger {
                      border-color: #d3412e;
                      &:disabled {
                        opacity: 0.5;
                        pointer-events: none;
                      }
                      &:hover {
                        background-color: #b12a18;
                      }
                    }
                  }
                  &.form-group {
                    margin-bottom: 0;
                  }
                }
              }
            }
            .newassignroledanger {
              border: 1px solid #b12a18 !important;
            }
          }
          .pos-body {
            .rolePerRPContent {
              .mainForm {
                .form-group {
                  .passwordLabel {
                    font-family: "OpenSans-Semibold";
                    font-size: 14px;
                    color: #1d1e20;
                    line-height: 12px;
                    // display: inline-block;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    // white-space: nowrap;
                  }
                  .departMentAssigned {
                    a {
                      font-family: "OpenSans-Bold";
                      color: #2caf92 !important;
                      font-size: 14px;
                      text-decoration: none;
                      .info-icn {
                        margin-right: 4px;
                        svg {
                          fill: #3f4347;
                        }
                      }
                    }
                  }
                  // .single-select-image {
                  //   &.dropdown {
                  //     &.show {
                  //       .dropdown-toggle {
                  //         text-align: center;
                  //         font-size: 14px;
                  //         color: #ffffff !important;
                  //         font-family: "OpenSans-Bold" !important;
                  //         font-weight: normal;
                  //         background: #2a394f !important;
                  //         border: none !important;
                  //         width: 100% !important;
                  //         box-sizing: border-box;
                  //         border-radius: 3px;
                  //         padding: 0px 16px !important;
                  //         //height: auto;
                  //         &::after {
                  //           content: none;
                  //         }
                  //       }
                  //     }
                  //     .dropdown-toggle {
                  //       text-align: center;
                  //       font-size: 14px;
                  //       color: #2caf92;
                  //       font-family: "OpenSans-Bold" !important;
                  //       font-weight: normal;
                  //       background: #ffffff;
                  //       border: none !important;
                  //       width: 100% !important;
                  //       box-sizing: border-box;
                  //       border-radius: 3px;
                  //       padding: 0px 16px !important;
                  //       //height: auto;
                  //       &::after {
                  //         content: none;
                  //       }
                  //     }
                  //     .dropdown-menu {
                  //       // max-width: fit-content;
                  //       &.user-assign-list {
                  //         inset: 0px auto auto 50% !important;
                  //       }
                  //       .dropdown-item {
                  //         cursor: default !important;
                  //         .user-assigned-item {
                  //           .user-assigned-name {
                  //             font-family: "OpenSans-Semibold";
                  //             // font-size: 16px;
                  //             line-height: 18px;
                  //             color: #1d1e20;
                  //             font-weight: 600;
                  //             // font-family: "OpenSans";
                  //             font-size: 14px;
                  //             // padding:5px 0 3px 0;
                  //           }
                  //           .user-assinged-posi {
                  //             // font-family: "OpenSans";
                  //             // font-size: 14px;
                  //             // line-height: 18px;
                  //             // color: #6a6e73;
                  //             // color: #6a6e73;
                  //             font-family: "OpenSans";
                  //             font-size: 12px;
                  //             line-height: 16px;
                  //             color: #6a6e73;
                  //             font-weight: normal;

                  //             // padding:3px 0 5px 0;
                  //           }
                  //         }
                  //       }
                  //     }
                  //   }
                  // }
                  .assigned-user-dropdown {
                    position: relative;
                    .assigned-user-toggle {
                      height: 32px;
                      padding: 0px 16px !important;
                      &.active {
                        text-align: center;
                        font-size: 14px;
                        color: #ffffff !important;
                        font-family: "OpenSans-Bold" !important;
                        font-weight: normal;
                        background: #2a394f !important;
                        border: none !important;
                        width: 100% !important;
                        box-sizing: border-box;
                        border-radius: 3px;
                      }
                    }
                    .user-assign-list {
                      position: absolute;
                      left: -45%;
                      z-index: 10;
                      width: 250px;
                      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
                      border: 1px solid #e0e3e6;
                      border-radius: 3px;
                      background: #ffffff;
                      padding: 0px;
                      margin: 0px;
                      max-height: 300px;
                      overflow: auto;
                      ul {
                        padding: 0;
                        margin-bottom: 0;
                        li {
                          padding: 12px 16px;
                          border-bottom: 1px solid #e0e3e6;
                          &:last-child {
                            border-bottom: 0;
                          }
                          .user-assigned-item {
                            .user-assigned-name {
                              font-family: "OpenSans-Semibold";
                              line-height: 18px;
                              color: #1d1e20;
                              font-weight: 600;
                              font-size: 14px;
                            }
                            .user-assinged-posi {
                              font-family: "OpenSans";
                              font-size: 12px;
                              line-height: 16px;
                              color: #6a6e73;
                              font-weight: normal;
                            }
                          }
                        }
                      }
                    }
                  }
                  // .roleTagSelect {
                  //   border: #2caf92 !important;
                  // }
                  .dangerRoleTagSelect {
                    border: 1px solid #b12a18 !important;
                  }
                  .deptCountLabel {
                    font-family: "OpenSans-Semibold";
                    font-size: 14px;
                    color: #1d1e20;
                    line-height: 23px;
                    // display: inline-block;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    // white-space: nowrap;
                  }
                }
                .border-line-main {
                  border-bottom: 1px solid #e0e3e6;
                  margin-bottom: 32px !important;
                  margin-top: 15px !important;
                }
                .nav {
                  &.nav-tabs {
                    .nav-link {
                      font-size: 16px;
                    }
                  }
                }
                .tab-content {
                  padding: 24px 0 0;
                  .search-form {
                    .form-group {
                      justify-content: space-between;
                      align-items: center;
                      .has-search {
                        position: relative;
                        svg {
                          position: absolute;
                          left: 12px;
                          top: 11px;
                        }
                        .permissionFilter {
                          padding-left: 32px;
                          width: 300px;
                          height: 40px;
                        }
                      }
                      .colExpandAllRP {
                        .plus-minus-toggle {
                          cursor: pointer;
                          font-family: "OpenSans-Bold";
                          font-size: 14px;
                          line-height: 18px;
                          text-decoration: none;
                          color: #2caf92;
                          position: relative;
                          width: 21px;
                          &:before,
                          &:after {
                            background: #2caf92;
                            content: "";
                            font-family: "OpenSans-Bold";
                            height: 3px;
                            left: -18px;
                            position: absolute;
                            top: 9px;
                            width: 12px;
                            transition: transform 500ms ease;
                          }
                          &:after {
                            transform-origin: center;
                          }
                          &.collapsed {
                            &:after {
                              transform: rotate(90deg);
                            }
                            &:before {
                              transform: rotate(180deg);
                            }
                          }
                        }
                      }
                    }
                  }
                  .allPermissionsCollapse {
                    // &:last-child {
                    //   div {
                    //     border: 1px solid red;
                    //   }
                    // }
                    .row-Action {
                      margin-bottom: -15px;
                      &:last-child {
                        border-bottom: 1px solid #e0e3e6 !important;
                      }
                      .subHeading {
                        img {
                          margin-right: 8px;
                          &.isRotated {
                            transform: rotate(90deg);
                          }
                        }
                        svg {
                          margin-right: 8px;
                          &.isRotated {
                            transform: rotate(90deg);
                            -webkit-transform: rotate(90deg);
                          }
                        }
                      }
                      .rolePermissonModuleName {
                        font-family: "OpenSans-Semibold";
                        font-size: 16px;
                        color: #1d1e20;
                        line-height: 22px;
                      }
                      .totalPermissionCount {
                        .totalpermissioncountimg {
                          visibility: hidden;
                        }
                        label {
                          font-family: "OpenSans";
                          font-size: 14px;
                          color: #6a6e73;
                          line-height: 18px;
                        }
                        .colSelectAllRolePermission {
                          text-align: right;
                          margin-left: -14px;
                          top: -14px;
                          font-family: "OpenSans-Bold";
                          font-size: 14px;
                          line-height: 18px;
                          .isEnabledSelectAllRolePermissionAnchor {
                            color: #2caf92;
                            text-decoration: none;
                          }
                          .isDisabledSelectAllRolePermissionAnchor {
                            color: #2caf92;
                            opacity: 0.5;
                            text-decoration: none;
                            pointer-events: none;
                          }
                        }
                      }
                      .rolePermissionTreeView {
                        padding: 24px;
                        margin-bottom: 36px;
                        border: 1px solid #e0e3e6;
                        margin-left: 26px;
                        border-radius: 3px;
                        .permissionNameHeading {
                          font-family: "OpenSans-Semibold";
                          background-color: #f4f6f8;
                          color: #1d1e20;
                          padding: 15px 16px;
                          font-size: 14px;
                          line-height: 18px;
                        }
                        // :not(.childDivTreeRolePermission) + .childDivTreeRolePermission {
                        //   .border-line {
                        //     border-bottom: 1px solid #fff !important;
                        //     }
                        // }
                        .childDivTreeRolePermission {
                          margin-left: 45px;
                          // :not(.childDivTreeRolePermission) + .childDivTreeRolePermission {
                          //   .border-line {
                          //     border-bottom: 1px solid #e0e3e6 !important;
                          //     }
                          // }
                          .form-check {
                            @include customCheckBoxFullHalfCheckedProp;
                            vertical-align: middle;
                          }
                          // label {
                          //   &.customCheckBoxClassforAdmin {
                          //     --color: gray !important;
                          //   }
                          //   .checkBoxFillColorForAdmin {
                          //     background-color: gray !important;
                          //   }
                          // }
                          .rolePermissonMenuName {
                            font-family: "OpenSans";
                            font-size: 14px;
                            color: #1d1e20;
                            line-height: 19px;
                            vertical-align: middle;
                            &.searched {
                              background-color: #defff8;
                              padding: 0 5px;
                            }
                          }
                          // .rolePermissonMenuNameSearched {
                          //   font-family: "OpenSans";
                          //   font-size: 14px;
                          //   color: #1d1e20;
                          //   line-height: 18px;
                          //   background-color: #defff8;
                          //   padding: 0 5px;
                          // }
                          .help-icon {
                            margin-left: 5px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
 
// Accounts Page Start ischal
.accountsPage {
  .body-sec {
    .navigation-menus {
      .navigationSort {
        .dropdown {
          &.bg-blue {
            .btn-primary {
              // border-color: #ced4da;
              margin-right: 8px;
            }
            .svgIcon {
              margin-right: 4px !important;
            }
          }
        }
      }
    }
    //Nischal css updated//
    .accounts-tableSmall {
      max-height: calc(100vh - 250px);
      overflow-y: auto;
      overflow-x: hidden;
      thead {
        th {
          vertical-align: middle;
          position: sticky;
          top: 0;
        }
        th:nth-child(1) {
          width: 16.5% !important;
        }
        th:nth-child(2) {
          width: 16.5% !important;
        }
        th:nth-child(3) {
          width: 13.5% !important;
          text-align: center;
        }
        th:nth-child(4) {
          width: 13.5%;
          text-align: center;
        }
        th:nth-child(5) {
          width: 13.5% !important;
          text-align: center;
        }
        th:nth-child(6) {
          width: 13.5% !important;
          text-align: center;
        }
        th:nth-child(7) {
          //width:12%!important;
          text-align: right !important;
        }

        @media (min-width: 1001px) and (max-width: 1200px) {
          th {
            font-size: 12.5px;
          }
          th:nth-child(1) {
            width: 18% !important;
          }
          th:nth-child(2) {
            width: 18% !important;
          }
          th:nth-child(3) {
            width: 12% !important;
            text-align: center;
          }
          th:nth-child(4) {
            width: 12%;
            text-align: center;
          }
          th:nth-child(5) {
            width: 12% !important;
            text-align: center;
          }
          th:nth-child(6) {
            width: 12% !important;
            text-align: center;
          }
          th:nth-child(7) {
            width: 16% !important;
            text-align: right !important;
          }
        }

        @media (min-width: 20px) and (max-width: 1000px) {
          th {
            font-size: 12.5px;
          }
          th:nth-child(1) {
            width: 17.5% !important;
          }
          th:nth-child(2) {
            width: 17.5% !important;
          }
          th:nth-child(3) {
            width: 12% !important;
            text-align: center;
          }
          th:nth-child(4) {
            width: 12% !important;
            text-align: center;
          }
          th:nth-child(5) {
            width: 12% !important;
            text-align: center;
          }
          th:nth-child(6) {
            min-width: 12%;
            text-align: center;
          }
          th:nth-child(7) {
            width: 16% !important;
            text-align: right !important;
          }
        }
      }
      tbody {
        tr {
          td {
            font-family: "OpenSans";
          }
          td:nth-child(3) {
            text-align: center;
          }
          td:nth-child(4) {
            text-align: center;
          }
          td:nth-child(5) {
            text-align: center;
          }
          td:nth-child(6) {
            text-align: center;
          }
          td:nth-child(7) {
            text-align: right;
          }
        }
      }
      // tbody{
      //   td{
      //     .hasInactiveBatch{
      //       display: flex;
      //       align-items: baseline;
      //       justify-content: baseline;
      //     }
      //   }
      // }
    }
    .accounts-tableWide {
      max-height: calc(100vh - 250px);
      overflow-y: auto;
      overflow-x: hidden;
      thead {
        th {
          vertical-align: middle;
          position: sticky;
          top: 0;
        }
        th:nth-child(1) {
          width: 17.5%;
        }
        th:nth-child(2) {
          width: 17.5%;
        }
        th:nth-child(3) {
          width: 13%;
          text-align: center;
        }
        th:nth-child(4) {
          width: 13%;
          text-align: center;
        }
        th:nth-child(5) {
          width: 13%;
          text-align: center;
        }
        th:nth-child(6) {
          width: 13%;
          text-align: center;
        }
        th:nth-child(7) {
          //width:12%!important;
          text-align: right;
        }

        @media (min-width: 1001px) and (max-width: 1200px) {
          th {
            font-size: 12.5px;
          }
          th:nth-child(1) {
            width: 23% !important;
          }
          th:nth-child(2) {
            width: 12% !important;
          }
          th:nth-child(3) {
            width: 12% !important;
          }
          th:nth-child(4) {
            width: 12% !important;
            text-align: center;
          }
          th:nth-child(5) {
            width: 12% !important;
          }
          th:nth-child(6) {
            width: 12% !important;
          }
          th:nth-child(7) {
            width: 16% !important;
            text-align: right !important;
          }
        }

        @media (min-width: 20px) and (max-width: 1000px) {
          th {
            font-size: 12.5px;
          }
          th:nth-child(1) {
            width: 27% !important;
          }
          th:nth-child(2) {
            width: 13% !important;
          }
          th:nth-child(3) {
            width: 12% !important;
            text-align: center;
          }
          th:nth-child(4) {
            width: 12% !important;
            text-align: center;
          }
          th:nth-child(5) {
            width: 12% !important;
            text-align: center;
          }
          th:nth-child(6) {
            min-width: 12%;
            text-align: center;
          }
          th:nth-child(7) {
            width: 17% !important;
            text-align: right !important;
          }
        }
      }
      tbody {
        tr {
          td {
            font-family: "OpenSans";
          }
          td:nth-child(3) {
            text-align: center;
          }
          td:nth-child(4) {
            text-align: center;
          }
          td:nth-child(5) {
            text-align: center;
          }
          td:nth-child(6) {
            text-align: center;
          }
          td:nth-child(7) {
            text-align: right;
          }
        }
      }
    }
  }
}

ul,
#myUL {
  list-style-type: none;
}

/* Remove margins and padding from the parent ul */
#myUL {
  margin: 0;
  padding: 0;
}

/* Hide the nested list */
.nested {
  display: none;
}

/* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */
.active {
  display: block;
}

// Addeded by Nischal tyagi Start here

/* Style the caret/arrow */
.caret {
  cursor: pointer;
  user-select: none; /* Prevent text selection */
}

 

/* Hide the nested list */
.nested {
  display: none;
}

/* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */
.active {
  display: block;
}

 
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #2caf92;
}
.rotateIconDisplayRole {
  visibility: hidden;
}

.hasoverlapMessage {
  font-size: 14px;
  margin-top: 15px;
  font-family: "OpenSans";
  font-style: italic;
  line-height: 19px;
  text-align: left;
  color: #d3412e;
}

 
 
 

 
 
 
 
 
 
 
 

.btn-primary {
  &.inner-sec-btn {
    padding: 0px !important;
    height: auto !important;

    div {
      padding: 10px 9px;
      height: 40px;
    }
  }
}

.cusptoflexPanel {
  display: flex;
  flex-direction: column;

  .cusflex {
    margin-bottom: 15px;
  }

  .cusflexgrid,
  .cusflex {
    display: flex;
    flex-direction: row;

    .col1,
    .col2 {
      margin-right: 10px;
      padding: 0px 0px;
    }

    .col1 {
      width: auto;
      white-space: nowrap;
    }

    .col2 {
      width: auto;
      white-space: nowrap;
    }

    .forTextBtn {
      background: #eaeaea;
    }

    .forTextBtn,
    .forText {
      border-radius: 3px;

      .forbtn,
      .forText {
        display: inline-block;
      }

      .fortxt {
        display: block;
        padding: 5px 0px;
      }

      .forbtn {
        padding: 2px 5px;
        min-width: 44px;
      }
    }

    .forImg {
      padding: 0px !important;

      img {
        background: #eaeaea;
        border-radius: 3px;
        padding: 8px 10px;
      }
    }
  }
}

 

 
.labelheader {
  font-weight: 800;
  font-size: 15px;
}

.fortwoline {
  padding: 6px 15px !important;

  .firstLine {
    display: block;
    margin-bottom: 5px;
  }

  .firstLine,
  .secondLine {
    display: block;
  }

  .secondLine {
    word-break: break-word;
    font-size: 13px !important;
    line-height: 22px;
    text-align: justify;
    .fixW {
      display: inline-block;
      min-width: 38px;
      text-align: center;
    }
  }

  .firstLine {
    font-size: 12px !important;
    margin-bottom: 5px;
  }

  .textLine {
    font-size: 13px !important;
    font-family: "OpenSans-Semibold";
    word-break: break-word;
    line-height: 25px;
  }
}

.hasInfo {
  svg {
    top: -1px;
    position: relative;
    left: 2px;
  }
}

.cusDangerBtn,
.cusSuccessBtn {
  display: inline-block;
  padding: 2px 5px;
  font-weight: 600;
  border-radius: 3px;
}

.cusDangerBtn {
  color: #b12b19;
  background: #ffefed;
}

.cusPrimaryBtn {
  color: #065a8c;
  background: #e5f5ff;
}

.cusSuccessBtn {
  color: #2caf92;
  background: #d8fff6;
}

 
 
 

 
.cusRightFull {
  width: 100% !important;
  justify-content: space-between;
  overflow: unset !important;
  display: flex;
  & > div:nth-child(1) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: baseline;

    .cusBadge {
      position: relative;
      margin-left: 15px;
    }
  }

  .cusBadgeApproved {
    background: #d8fff6 !important;
    color: #2caf92 !important;
  }

  .cusBadgePending {
    background: #fff3e8 !important;
    color: #863902 !important;
  }

  .cusBadgeDeclined {
    background: #ffefed !important;
    color: #b12a18 !important;
  }
}

.textCamelCase {
  text-transform: capitalize;
}

.customDropdownPanel {
  display: flex;
  font-family: "OpenSans-Semibold" !important;

  .btn {
    margin-left: 10px;
  }

  .customDropdown {
    position: relative;

    .hasForm {
      position: absolute;
      right: 0px;
      top: 40px;
      min-width: 350px;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
      border: 1px solid #e0e3e6;
      border-radius: 3px;
      background: #ffffff;
      transition: linear 0.2s;
      z-index: 9;
      font-size: 15px;
      font-weight: 300 !important;

      .form-group {
        padding: 10px;
        margin-bottom: 0px;
      }
      .seprator {
        margin-bottom: 5px !important;
      }
      .form-control {
        resize: none;
        height: unset !important;
        overflow: unset !important;
        font-size: 15px !important;
        font-family: "OpenSans-Semibold" !important;
      }

      ::placeholder {
        font-size: 15px !important;
      }

      .seprator {
        border-top: 1px solid #ddd;
        padding-top: 10px;
      }

      .btn {
        margin-left: 6px;
        height: 30px;
        border: 0px !important;
      }
    }

    .cusBtnDanger {
      border: 1px solid #f00 !important;
      color: #f00 !important;
      background: transparent !important;

      &:focus,
      &:hover {
        background: #364760 !important;
        border: 1px solid #364760 !important;
        color: #fff !important;
      }
    }
  }
}

.autocompleteWrapper {
  position: relative;

  & > div {
    width: 100%;
    position: relative;

    .reactAutocomplete {
      background: #fff;
      max-height: 200px;
      overflow: auto;
      border: 1px solid #ddd;
      width: 100%;
      position: absolute;
      z-index: 999;
    }
  }
}



 

.hasPrimaryBorder {
  .btn-outline-primary {
    border: 1px solid #2caf92 !important;

    &:active,
    &:focus,
    &:hover {
      border: 1px solid transparent !important;
    }
  }
}

.hasNewEntryError {
  border: 1px solid #f00 !important;
}

.cusScheduleGridTable {
  font-family: "OpenSans";
}

.cusPTOGridTable {
  font-family: "OpenSans";
  &.cusPTOtable {
    .table-responsive {
      overflow-x: visible !important;
    }
    .tbl1 {
      overflow: visible !important;
    }
  }
  .schduleInput {
    font-size: 13px;
  }
  .tbl1 {
    @media (min-width: 981px) {
      overflow: hidden;
    }
  }

  table {
    margin-bottom: 0px;

    th {
      text-transform: capitalize !important;
    }

    th,
    td {
      border-color: #dee2e6;
      padding: 15px 10px;

      .btn {
        height: 30px !important;
      }
    }

    thead {
      border-top: 1px solid transparent !important;
      th {
        border: 1px solid transparent !important;
      }
    }

    tbody {
      td {
        vertical-align: middle;
        .otarPosition {
          width: 155px;
          line-height: 18px;
        }
      }
      border-top: 1px solid #dee2e6 !important;
      .otarTdNoPadd {
        padding-top: 5px !important;
        .noMainUnique {
          text-align: center;
          white-space: nowrap;
          padding-top: 3px;
        }
      }
      .ptoTdNoPadd {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
      }
      .ptoTdHasPadd {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        padding-left: 2px !important;
        padding-right: 2px !important;
        vertical-align: middle;

        label {
          margin-bottom: 0px !important;
        }

        .ptoDatePicker,
        .scheduleptoDatePicker {
          .react-datepicker-wrapper {
            width: 100%;
          }
          .form-control {
            padding-left: 10px !important;
            background: #fff !important;
            border: 1px solid #ced4da;
          }
        }
        .ptoDatePicker {
          .form-control {
            height: 35px !important;
          }
        }
        .scheduleptoDatePicker {
          .form-control {
            height: 33px !important;
          }
        }
      }

      .nobrderTop {
        border-top: 1px solid #fff !important;
      }

      .otarStatus {
        padding: 10px 5px;
        strong {
          font-size: 11px;
          padding: 5px;
          svg {
            top: -1px;
            position: relative;
            left: 2px;
          }
        }
      }
      .otrReassignBox + .ptoTdNoPadd {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
      .otrReassignBox {
        padding: 5px 5px;
        .a,
        span,
        strong {
          color: #2caf92;
          cursor: pointer;
          transition: linear 0.3s !important;
          font-size: 13px;
          user-select: none;
          font-family: "OpenSans-Semibold" !important;
        }
        .tableHasAsignDropdown.show {
          .btn {
            background-color: #0c192d !important;
            color: #fff !important;
            & > strong {
              color: #fff !important;
            }
          }
        }
        .tableHasAsignDropdown.show {
          position: static;
          z-index: 9;
        }
        & > .assign-shift {
          margin-top: -5px;
          .dropdown {
            & > .btn {
              //padding: 0px !important;
              border: 0px !important;
              &:after {
                display: none;
              }
            }
          }
          .dropdown-menu {
            max-height: 289px !important;
            border: 0px !important;
            overflow: hidden !important;
            .list-area {
              min-height: 100% !important;
              max-height: 208px !important;
            }
            .dropdown-item {
              padding: 0px 0px !important;
              .details {
                width: 50%;
                margin-right: 18px;
                .name {
                  text-overflow: ellipsis;
                  overflow: hidden;
                  display: block;
                }
              }
              .showAvailableHours {
                .hours {
                  font-family: "OpenSans-Semibold";
                  font-size: 14px;
                  line-height: 18px;
                  color: #1d1e20;
                }
              }
            }
          }
        }
        // Added by Rohit
        .assign-shift {
          position: relative;
          .single-select-image {
            &.show {
              .assign-shift {
                .unassign {
                  background-color: red !important;
                }
              }
            }
            .dropdown-menu {
              left: auto !important;
              right: 0 !important;
              &.show {
                opacity: 1 !important;
                pointer-events: all !important;
                transform: translate(0px, -240px);
              }
              .confirmModal {
                width: 300px;
                .action-link {
                  .btn {
                    &.dropdown-item {
                      font-family: "OpenSans-Bold" !important;
                      height: 32px;
                      border: 0;
                      width: auto;
                      padding: 7px 20px !important;
                      white-space: nowrap;
                      margin-left: 12px;
                    }
                    &.btn-danger {
                      color: #fff;
                    }
                    &.wht-bg {
                      color: #3f4347 !important;
                    }
                  }
                }
              }
            }
            &.tableHasAsignDropdown {
              &.show {
                .dropdown-menu {
                  top: -30px !important;
                }
              }
            }
          }
        }
      }
      td {
        font-size: 14px;
        font-family: "OpenSans";

        .tableLockSymbol {
          padding-top: 5px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: baseline;
          & > span {
            padding-right: 3px;
          }
          & > img {
            height: 11px;
          }
        }
        .form-control {
          border-radius: 2px;
        }
        .tableHasDropdown {
          .btnHasNoPadd {
            padding: 0px !important;

            .btn {
              border: 0px !important;
              max-width: 77px;

              &:focus,
              &:active {
                outline: 0 none;
                box-shadow: 0 0 0 0 !important;
                border: 0px !important;
              }
            }
          }

          .btn {
            background: transparent;
            color: #364760;
            outline: 0 none;
            box-shadow: 0 0 0 0;
            border: 1px solid transparent;
            width: unset !important;
            font-size: 13px;
            position: relative;
            .mxW {
              padding: 0.375rem 0.3rem !important;
              width: 70px !important;
              text-align: center !important;
              justify-content: center;
            }
            .btnHandler {
              display: flex;
              padding: 0.375rem 0.75rem;
              img {
                margin-left: 3px;
              }
            }

            &:focus,
            &:active {
              outline: 0 none;
              box-shadow: 0 0 0 0 !important;
              border: 1px solid transparent;
            }

            &:after {
              display: none;
            }
          }
        }

        .OTRtabledd.show {
          position: static !important;
          z-index: 9;
          .rightDrop {
            width: 150px;
            .dropdown-item {
              padding: 15px 17px !important;
            }
          }
        }

        .tableHasDropdown.show {
          position: static;
          z-index: 9;

          & > .btn {
            background: #0c192d !important;
            color: #fff !important;
            outline: 0 none;
            box-shadow: 0 0 0 0 !important;
            border: 1px solid transparent !important;
          }
        }

        .tableHasDropdown {
          .dropDownHasForm {
            .dropDownHeader {
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid #dee2e6;
              padding: 0px 10px 0px 10px;
              align-items: center;
              justify-content: baseline;
              font-size: 13px;

              label {
                font-weight: bold;
                margin: 0px;
              }

              .xClose {
                width: 24px !important;
                padding: 0;
                text-align: center;
                line-height: 1;
                background: transparent !important;

                &:focus {
                  background: transparent;
                }
              }
            }

            .dropDownBody {
              padding: 10px;
              label {
                margin-top: 7px;
                font-size: 11px;
              }
              strong {
                display: block;
                padding: 5px 0 10px 0;
              }
            }

            .dropDownFooter {
              padding: 10px 10px 7px 10px;
              border-top: 1px solid #dee2e6;
              text-align: right;

              & > .btn {
                background-color: #2caf92 !important;
                color: #fff !important;
              }
            }
          }

          .dropdown-menu {
            width: 200px;
          }
        }

        .tableRightDropdown {
          & > .btn {
            padding: 0px 6px;
            svg {
              fill: #2caf92;
            }
            &:focus,
            &:active,
            &:visited {
              background: #0c192d !important;
              svg {
                fill: #fff;
              }
            }
            &:hover {
              background: #eee;
            }
          }
          .dropdown-menu {
            padding: 0px !important;
            width: auto;
            & > .dropdown-item {
              padding: 10px 12px;
              display: flex;
              align-items: center;
              &:focus,
              &:active,
              &:visited {
                background: #eee !important;
                color: #222 !important;
              }
            }
          }
        }

        .tableRightDropdown.show {
          position: relative;

          & > .btn {
            svg {
              fill: #fff;
            }
          }

          .dropdown-menu {
            position: absolute;
          }
        }
      }

      .ptoRequestEntries {
        position: relative;

        p {
          margin: 0;
          color: #dc3545;
          padding: 2px 5px;
          font-size: 11px;
        }
      }

      .ptoEditDate {
        top: auto !important;

        div {
          .react-calendar {
            top: auto !important;
            bottom: 100% !important;
          }
        }
      }

      .readOnly[readonly] {
        background: #fff;
      }
    }

    tfoot {
      .ptoTdNoPadd {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
      }

      .ptoTdHasPadd {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        vertical-align: middle;
      }

      td,
      th {
        border-top: 1px solid #dee2e6 !important;
      }
    }

    .btnAddEntry {
      background-color: transparent;
      color: #2caf92;
      border: 1px solid transparent;
      font-weight: bold;
      outline: 0 none;
      box-shadow: 0 0 0 0;
      padding-left: 7px;
      padding-right: 7px;

      &:after {
        display: none !important;
      }

      &:focus,
      &:hover {
        background-color: #0c192d !important;
        color: #fff !important;
        box-shadow: 0 0 0 0 !important;
        border: 1px solid transparent !important;
      }
    }
  }
}

.hierarchyCusAlert {
  display: flex;
  height: auto;
  min-height: 48px;

  .cusAlert {
    background-color: #fff3e8;
    margin-bottom: 20px;
    padding: 16px;
    border-radius: 3px;
    align-content: center;
    flex-wrap: wrap;
    font-family: "OpenSans";
    font-size: 14px;

    p {
      color: #863902;
      margin-bottom: 0;

      strong {
        font-family: "OpenSans-Semibold";
      }
    }
  }
}

.hierarchyDragableTable {
  position: relative;

  .tableThead {
    background: #dee2e6;
    padding: 10px;
    position: relative;
    z-index: 1;
  }

  .hasTable {
   // min-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 295px);
    font-size: 14px;
    font-family: "OpenSans";

    .tableTbody {
      .row {
        border-bottom: 1px solid #dee2e6;
        cursor: default;
        user-select: none;
        .tdBody {
          padding: 10px;
          // text-transform: capitalize;
          font-family: "OpenSans";
        }
      }
    }
  }

  .hasFixedAction {
    padding-bottom: 64px;
  }
  .fixed-action {
    padding: 0px 15px;
  }
}

.tableTbody {
  .row {
    border-bottom: 1px solid #dee2e6;
    cursor: default;
    user-select: none;
    overflow: hidden !important;

    .tdBody {
      padding: 10px;
      // text-transform: capitalize;
      font-family: "OpenSans";
    }
  }
}

.sortableHelper {
  z-index: 0;
  padding-top: 10px;
  font-size: 14px;
  font-family: "OpenSans";
  margin-left: 0px;
  // text-transform: capitalize;
  text-indent: -5px;
  opacity: 9 !important;
  border-right: 31px solid transparent;
  position: relative;
  .td1 {
    z-index: 2;
    font-family: "OpenSans";
  }
  .td2 {
    z-index: 2;
    text-indent: -2px;
    font-family: "OpenSans";
  }
  &:after,
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0px;
    width: 100%;
    z-index: 0;
  }
  &:before {
    top: 0px;
    height: 30px;
    background: #eee;
    border-top: 1px solid #dee2e6;
  }
  &:after {
    bottom: 0px;
    height: 30px;
    background: #eee;
    border-bottom: 1px solid #dee2e6;
  }
}
// Role Matrix By Nischal Start
.roleMatrixsearched {
  background-color: #defff8;
}
.fullWidthHeader {
  &::before {
    content: "";
    background: #e8eaed;
    width: 48px;
    height: 48px;
    position: absolute;
    top: 0;
    left: -48px;
  }
  &::after {
    content: "";
    background: #e8eaed;
    width: 738px;
    height: 48px;
    position: absolute;
    top: 0;
    right: -854px;
  }
}
.cardMainHeaderFullWidth {
  &:hover {
    .hasArrow {
      img {
        opacity: 1;
      }
    }
  }
  .hasArrow {
    img {
      opacity: 0;
    }
  }
}
.fullWidthSearchBar {
  margin-left: 0;
}
.roleMatrixMenuName {
  background-color: none;
}
.singleNode {
  margin-left: 15px !important;
}
.cardHeader:hover {
  background-color: #f5f5f5;
  border-radius: 3px;
}

  

.listAccordion {
  li > {
    .card-header {
      .hasCheckBox {
        .form-check {
          input[type="checkbox"] {
            appearance: none;
            -webkit-appearance: none;
            background-color: #fff;
            border: 1px solid #c7c8ca;
            padding: 7px;
            border-radius: 3px;
            display: inline-block;
            position: relative;
            top: 2px;
            outline: none;
            cursor: pointer;

            &:checked {
              background-color: #2caf92;
              border: 1px solid #2caf92;
            }
            &:checked + span::after {
              content: "";
              position: absolute;
              top: 16px;
              left: 7px;
              display: block;
              width: 6px;
              height: 10px;
              border: solid #fff;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
            &:disabled {
              background-color: #84888c !important;
              border: 1px solid #84888c !important;
            }
            &:indeterminate {
              background-color: #2caf92;
              border: 1px solid #2caf92;
            }
            &:indeterminate:after {
              content: "";
              position: absolute;
              top: 7px;
              left: 3px;
              display: block;
              width: 10px;
              height: 10px;
              border: solid #fff;
              border-width: 2px 0 0 0;
            }
          }
          vertical-align: middle;
          top: -7px;
        }
      }
    }
  }
}
 
.optionBox.active {
  border-bottom: 4px solid #2caf92 !important;
}
.optionBox {
  font-family: "Poppins-Medium";
  font-style: normal;
  width: 214px;
  height: 145px;
  background-color: #fff;
  border: 1px solid #e0e3e6;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 28px 16px;
  cursor: pointer;
  margin-right: 24px;
  .labelOptionBox {
    color: #3f4347 !important;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05px;
  }
  .showCount {
    font-family: "Poppins-Light";
    font-size: 54px;
    line-height: 64px;
    text-align: center;
    color: #1d1e20;
  }
}
 
 
.customDropdown {
  .acceptRiskRightPanel {
    background: transparent;
    color: #2caf92;
  }
}
 

.matrixTreeView {
  padding-top: 20px;

  .matrixTreeViewHeader {
    background: #dee2e6;
    padding: 10px;
  }

  .matrixTreeViewBody {
    .treeHeader {
      padding: 10px;
      border-bottom: 1px solid #dee2e6;
      .rollPermissions {
        padding-left: 22px;
        padding-top: 5px;
      }
    }

    ul {
      padding: 0px;
      margin: 0px;
      list-style: none;

      & > li {
        padding-left: 10px;
        cursor: default;
        border-top: 0px;
      }

      .parentNodehasChild {
        & > .treeHeader {
          cursor: pointer;
          padding-left: 10px;
        }
      }
    }
  }
}

.smileIdEmpImgLoader {
  position: relative;
  min-height: 320px;
  text-align: center;
  .matrixSpinnerCenter {
    position: absolute;
    background: rgba(179, 179, 179, 0.1);
    border-radius: 5px;
  }
  img {
    max-height: 350px;
    border-radius: 5px;
  }
}

.matrixSpinnerCenter {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1006;
}
.RollMatrixScroll {
  max-height: calc(100vh - 48px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  .tab-content {
    .navigation-menu {
      margin-bottom: 20px;
      height: 36px;
      .department-filter {
        .dropdown {
          .dropdown-menu {
            .dropdown-item {
              .form-check {
                @include customCheckBoxFullHalfCheckedProp;
                .form-check-label {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
.rollMatrixPanel {
  position: relative;
  .cusRow {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    .column6 {
      flex-basis: 100%;
    }
    .column1 {
      flex-basis: 10%;
      max-width: 120px;
      min-width: 120px;
    }
  }

  .matrixfixHeader {
    background: #dee2e6;
    padding: 15px 10px;
  }

  .hasFixedAction {
    padding-bottom: 64px;
  }

  .rollMatrixPanelScroll {
    .cusRow {
      .column1 {
        &:last-of-type {
          .collapse {
            &.border_r {
              border-right: none;
            }
          }
        }
      }
    }
    .border_l {
      border-left: 1px solid #dee2e6;
    }
    .border_r {
      border-right: 1px solid #dee2e6;
    }
    .mainAccordion {
      padding: 0px;
      margin: 0px;
      list-style: none;
      ul {
        padding: 0 0 0 15px;
        margin: 0px;
        list-style: none;
      }
    }
    .listAccordion {
      padding: 0px;
      margin: 0px;
      list-style: none;
      cursor: default !important;
      ul {
        padding: 0 0 0 0px;
        margin: 0px;
        list-style: none;
        text-align: center;
      }
    }
    .rollAccordion {
      & > li.matrixBottomShaddow {
        &.card-header:first-child {
          border-radius: 0 !important;
        }
        & > .card-header {
          position: sticky;
          width: 100%;
          top: 18px;
          z-index: 100;
          box-shadow: 0 0 0 0 !important;
          .cardMainHeader,
          .matrixAssignedPanel {
            position: relative;
            &:after {
              content: "";
              display: block;
              width: 100%;
              height: 17px;
              position: absolute;
              left: 0px;
              bottom: -1px;
              box-shadow: 0px 3px 8px -3px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }

      & > li:last-child.matrixBottomShaddow {
        border-bottom: 0px !important;
        & > ul {
          padding-bottom: 0px;
          :after,
          :before {
            display: none;
          }
        }
      }

      .matrixBottomShaddow {
        border-bottom: 1px solid #e0e3e6;
        & > ul {
          position: relative;
        }
      }
      li {
        text-transform: capitalize;
        .card-header {
          padding: 0 !important;
          background: #fff;
          .hasCheckBox {
            padding: 10px 10px !important;
            justify-content: center;
            &.form-check {
              @include customCheckBoxFullHalfCheckedProp;
            }
          }
          .matrixAssignedPanel {
            text-align: center;
          }
          .matrixAssignedPanel {
            text-align: center;
          }
          .matrixAssignedPanel {
            padding: 24px 0;
            height: 80px;
            .content {
              .content-heading {
                font-family: "OpenSans-Semibold";
                font-size: 14px;
                line-height: 18px;
                color: #1d1e20;
              }
              .content-subhead {
                font-family: "OpenSans";
                font-size: 12px;
                line-height: 16px;
                color: #3f4347;
              }
            }
          }
          .cardMainHeader {
            padding: 15px 15px;
            height: 80px;
            line-height: 80px;
            &.cardMainHeaderFullWidth {
              height: 80px;
            }
          }

          .matrixhasAdmin {
            .content {
              .content-heading {
                opacity: 0.6;
              }
              .content-subhead {
                opacity: 0.6;
              }
            }
          }

          .cardHeader {
            padding: 7px 10px;
            cursor: pointer;
            height: 50px;
            line-height: 50px;
          }

          .singleNode {
            cursor: default;
            margin-left: 15px !important;
          }

          .cardMainHeader,
          .cardHeader {
            display: flex;
            cursor: pointer;

            .hasArrow {
              display: flex;
              justify-content: center;
              margin-left: -7px;
              margin-right: 3px;
              .isRotated {
                transform: rotate(90deg);
              }
            }

            .hasContent {
              display: flex;
              flex-direction: column;
              justify-content: center;

              div {
                font-family: "OpenSans-Semibold";
                font-size: 16px;
                color: #1d1e20;
                line-height: 22px;
                margin-bottom: 4px;
              }

              span {
                font-family: "OpenSans";
                font-size: 12px;
                color: #3f4347;
                line-height: 16px;
              }

              span {
                display: inline-block;
              }

              label {
                display: inline-block;
                margin: 0px;
              }
            }

            .hasSensitive {
              flex-direction: row !important;
              align-items: center;

              label,
              svg {
                display: inline-block;
                margin: 0 0 0 6px;
                height: 20px;
                line-height: 15px;
              }
            }
          }
        }
        ul {
          &.collapse {
            li {
              .hasContent {
                span {
                  font-family: "OpenSans" !important;
                  font-size: 14px !important;
                  line-height: 18px !important;
                  color: #1d1e20 !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .rollMatrixAccordionHeader {
    background: #e8eaed;
    padding: 15px 0px;
    text-align: center;
    position: sticky;
    top: -28px;
    z-index: 110;
    height: 48px;

    .colPadd {
      padding-left: 10px;
      padding-right: 10px;
      .heading {
        font-family: "OpenSans-Semibold";
        font-size: 14px;
        color: #1d1e20;
        line-height: 18px;
      }
    }

    .min_ml10 {
      margin-left: -25px !important;
    }

    .min_mr10 {
      margin-right: 5px !important;
    }
    .min_ml {
      margin-left: -30px !important;
    }

    .min_mr {
      margin-right: 0px !important;
    }
  }
  .hasSearchRoles {
    padding: 6px 0 5px 0 !important;
  }
}

 
.otrPanelMain {
  .left-panel {
    width: 38% !important;
  }
  .right-panel {
    width: 62% !important;
  }
}

.hasOtgAssignModal {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e3e6;
  border-radius: 3px;
  z-index: 1000;
  position: fixed;
  top: auto;
  left: auto;
  margin: 0px;
  right: 72px;
  bottom: 40px;
  width: 400px;
  background-color: #fff;
}

.otrAssignModal {
  width: 100%;
  background-color: #fff;
  .otrModalTitle,
  .otrModalFooter {
    font-family: "Poppins-Medium";
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #1d1e20;
    .dropdown-item {
      width: auto;
      padding: 0px;
      background: transparent;
    }
  }

  .otrModalContainer {
    width: 100%;
    .otrModalBody {
      padding: 20px;
      text-align: left;
      .otgCusInput {
        & > .btn {
          border: 1px solid #ced4da !important;
          width: 100% !important;
          height: 40px !important;
          line-height: 20px !important;
          &:after {
            content: "" !important;
            color: #333 !important;
            display: block !important;
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }
      }
      .otgCusInput + .textHidden {
        height: 0px;
        padding: 0px !important;
        top: -8px;
        position: relative;
        border: 0px;
      }
      .form-group {
        margin-bottom: 15px !important;
        .form-label {
          font-size: 13px;
          font-family: "Poppins-Medium";
        }
      }
    }
    .form-group {
      margin-bottom: 15px !important;
      .form-label {
        font-size: 13px;
        font-family: "Poppins-Medium";
      }
    }
  }

  .otrModalTitle {
    border-bottom: 1px solid #e0e3e6;
    padding: 15px 20px;
  }
  .otrModalFooter {
    padding: 10px 20px;
    border-top: 1px solid #e0e3e6;
    & > .btn {
      background: #2caf92 !important;
      color: #fff !important;
      font-family: "OpenSans-Semibold" !important;
      height: 38px !important;
      padding: 10px 15px;
    }
  }
}

 

 
 
.noDeepLink {
  table {
    tr,
    td,
    th {
      cursor: default !important;
    }
  }
  a,
  .btn {
    :active,
    :visited:focus {
      background-color: transparent !important;
    }
  }
  .btn + .dropdown-menu {
    a.ifCusOver {
      pointer-events: none !important;
    }
  }
}

.formCheckInput {
  margin-top: 12px;
  &:checked {
    background: #2caf92 !important;
    border: 1px solid #2caf92 !important;
    &:after {
      content: "";
      position: absolute;
      top: 0px;
      left: 4px;
      display: block;
      width: 7px;
      height: 10px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}
.justifyBetween {
  justify-content: space-between;
}

.timeOffsetCusBg {
  .input-component {
    background: #f9f9f9;
    border: 1px solid #f6f6f7;
    min-width: 120px;
    margin-right: 10px;
    input {
      background: transparent;
      min-width: 120px;
    }
  }
}

.roleListDisabled {
  opacity: 0.4;
}

 

 
 
.wtcDateCalc {
  .react-datepicker-wrapper {
    width: 100% !important;
    .schduleInput {
      height: 40px;
    }
  }
}

 

.shiftTimeModal {
  font-size: 14px;
  font-weight: bold;
  p {
    margin-bottom: 0px;
    font-weight: normal;
    margin: 15px 0;
  }
  .shiftTimebodyTable {
    table {
      margin-top: -1px;
    }
  }
  .shiftTimeModalTable {
    table {
      width: 100%;
      td:nth-child(1),
      th:nth-child(1) {
        min-width: 100px;
        width: 35%;
      }
      td:nth-child(2),
      th:nth-child(2) {
        min-width: 100px;
        width: 35%;
      }
      td:nth-child(3),
      th:nth-child(3) {
        min-width: 85px;
        width: 15%;
      }
      td:nth-child(4),
      th:nth-child(4) {
        min-width: 85px;
        width: 15%;
      }
      td,
      th {
        padding: 5px;
        text-align: center;
        border: 1px solid #d0d9d9;
      }
      td {
        font-weight: 400;
      }
    }
  }
}

.modaloverlapOverlay {
  z-index: 99999;
  background: rgba(0, 0, 0, 0.3);
  .modal-content {
    box-shadow: 0px 0px 1px 11px #fff;
    border-radius: 1px;
    border: 0px;
  }
}

 
.tableHasCheckBox {
  z-index: 0;
  .form-check {
    input[type="checkbox"] {
      appearance: none;
      -webkit-appearance: none;
      background-color: #fff;
      border: 1px solid #c7c8ca;
      padding: 7px;
      border-radius: 3px;
      display: inline-block;
      position: relative;
      outline: none;
      cursor: pointer;

      &:checked {
        background-color: #2caf92;
        border: 1px solid #2caf92;
      }
      &:checked + span::after {
        content: "";
        position: absolute;
        top: 7px;
        left: 48%;
        display: block;
        width: 6px;
        height: 10px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
      &:disabled {
        background-color: #84888c !important;
        border: 1px solid #84888c !important;
      }
      &:indeterminate {
        background-color: #2caf92;
        border: 1px solid #2caf92;
      }
      &:indeterminate:after {
        content: "";
        position: absolute;
        top: 7px;
        left: 3px;
        display: block;
        width: 10px;
        height: 10px;
        border: solid #fff;
        border-width: 2px 0 0 0;
      }
    }
    vertical-align: middle;
    margin-bottom: 0px;
  }
}

.tdHasDropdown {
  table {
    td {
      overflow: unset !important;
    }
  }
}

 

.copytoClipBoardPanel {
  .copytoClipBoard {
    width: unset !important;
    float: right;
    span,
    a {
      display: inline;
    }
    span {
      color: #b12a18;
      font-size: 12px;
      padding-right: 5px;
      font-weight: bold;
      font-family: "Poppins-Medium";
    }
  }

  &:after {
    content: "";
    clear: both;
    float: none;
  }
}

 

.hasDropShadow {
  .dropdown-menu {
    background: #ffffff !important;
    border: 1px solid #e0e3e6 !important;
    box-sizing: border-box !important;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3) !important;
    border-radius: 3px !important;
    font-size: 14px !important;
    .dropdown-item {
      border: 0px !important;
    }
  }
}

.hasDropShadow.show {
  .btn {
    background: #364760 !important;
    color: #fff !important;
    border-color: #364760 !important;
    opacity: 1 !important;
  }
}

.lastColW {
  th:nth-last-of-type(1),
  th:nth-last-of-type(2) {
    text-align: center;
    width: 50px !important;
  }
}

.search-label + .custom-switch {
  .custom-control-label {
    padding-top: 8px !important;
  }
}

.LDPsetlastW9 {
  th:nth-child(1) {
    width: 20% !important;
  }
  th:nth-child(2) {
    width: 16% !important;
  }
  th:nth-child(3) {
    width: 16% !important;
  }
  th:nth-child(4) {
    width: 14% !important;
  }
  th:nth-child(5) {
    width: 13% !important;
  }
  th:nth-child(6) {
    width: 5% !important;
  }
  th:nth-child(7) {
    width: 5% !important;
  }
  td:nth-child(6),
  td:nth-child(7) {
    overflow: unset !important;
  }
}

.LDPsetlastW7 {
  th:nth-child(1) {
    width: 20% !important;
  }
  th:nth-child(2) {
    width: 20% !important;
  }
  th:nth-child(3) {
    width: 20% !important;
  }
  th:nth-child(4) {
    width: 10% !important;
  }
  th:nth-child(5) {
    width: 20% !important;
  }
  th:nth-child(6) {
    width: 5% !important;
  }
  th:nth-child(7) {
    width: 5% !important;
  }
  td:nth-child(6),
  td:nth-child(7) {
    overflow: unset !important;
  }
}

.LDPsetlastW4 {
  th:nth-child(1) {
    width: 20% !important;
  }
  th:nth-child(2) {
    width: 70% !important;
  }
  th:nth-child(3) {
    width: 5% !important;
  }
  th:nth-child(4) {
    width: 5% !important;
  }
  td:nth-child(3),
  td:nth-child(4) {
    overflow: unset !important;
  }
}
.colorB12A18 {
  color: #b12a18 !important;
}
.autoWidth.dropdown {
  .dropdown-menu {
    width: unset !important;
  }
}

 
.tctHasNoHeader {
  .table {
    thead {
      th {
        height: 0px !important;
        overflow: hidden !important;
      }
    }
  }
}

.managerPanel {
  border: 1px solid #e0e3e6;
  border-radius: 4px;
  .managerHeader {
    padding: 13px 25px !important;
    border-bottom: 1px solid #e0e3e6;
    .timeClickLabel {
      height: 40px;
      justify-content: flex-start;
      display: flex;
      align-items: center;
      .hasLabel {
        font-size: 17px;
        font-family: "Poppins-Medium";
        color: #1d1e20;
      }
    }
  }
  .managerBody {
    padding: 15px 25px;
    min-height: 250px;
  }
}
 
.hasNoPointer {
  pointer-events: none !important;
  color: #a9a9a9 !important;
  user-select: none;
}
 

 
 
 
 
.fixHeader100 {
  .table {
    th {
      z-index: 9;
    }
  }
}

.svgPaperclip {
  svg {
    transform: rotate(180deg);
  }
}
.RegPaperclip {
  svg {
    transform: rotate(137deg);
  }
}

.transactions-list.hasBorderBottom {
  padding-bottom: 40px !important;
  margin-bottom: 40px;
  border-bottom: 1px solid #e0e3e6;

  .transCol {
    font-size: 14px;
    font-family: "OpenSans";
    .trasfarHeader {
      height: 24px;
      line-height: 40px;
      margin-bottom: 20px;
      display: flex;
      position: relative;
      .transferSRNo {
        position: absolute;
        left: -20px;
        top: 0px;
        color: #1d1e20;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .transferRightArrow {
        display: flex;
        top: 0px;
        position: absolute;
        left: -65px;
        width: 20px;
        height: 100%;
        margin: auto;
        align-items: center;
        justify-content: center;
        svg {
          fill: #1d1e20;
        }
      }
      @media (min-width: 20px) and (max-width: 1020px) {
        .transferRightArrow {
          left: -23px;
          width: 15px;
        }
      }
      .singleSelect {
        width: 160px;
      }

      strong {
        display: inline-block;
        margin-right: 10px;
      }
    }

    .trFrom {
      justify-content: flex-end;
    }

    .trasfarBody {
      border: 1px solid #e0e3e6;
      padding: 32px 32px 40px;
      min-height: 320px;
      border-radius: 5px;
      position: relative;
      .transferSRNo {
        position: absolute;
        left: -20px;
        top: 0px;
        color: #1d1e20;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .transferRightArrow {
        display: flex;
        top: 0px;
        position: absolute;
        left: -65px;
        width: 20px;
        height: 100%;
        margin: auto;
        align-items: center;
        justify-content: center;
        svg {
          fill: #1d1e20;
        }
      }
      @media (min-width: 20px) and (max-width: 1020px) {
        .transferRightArrow {
          left: -23px;
          width: 15px;
        }
      }
      .form-group {
        .transferFromAmount {
          font-size: 15px;
          font-family: "OpenSans";
          color: #1d1e20;
        }
        .seprateLabel {
          display: flex;
          justify-content: space-between;
        }

        .form-label {
          font-family: "OpenSans-Semibold";
        }
      }
    }

    .singleSelect {
      .single-select-image {
        .filter-btn {
          width: 100%;
          border: 1px solid #ced4da;
          background-color: #fff;
          border-radius: 0.25rem;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          padding: 0px 8px;
          height: 36px;

          .txt-area {
            max-width: 80%;
          }

          &:focus {
            border-color: #364760 !important;

            .drop-arrow {
              svg {
                stroke: #1d1e20;
              }
            }
          }
        }

        .dropdown-menu {
          .list-area {
            min-height: auto;
            max-height: 250px;
          }
        }
      }

      .single-select-image {
        .dropdown-toggle {
          width: 100%;
          height: 36px;
          padding: 0px 8px;
          font-family: "OpenSans";
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

          .txt-area {
            color: #1d1e20;

            &.placeholder {
              color: #84888c;
            }

            .drop-arrow {
              svg {
                stroke: #1d1e20;
              }
            }
          }

          &:hover {
            background-color: #fff !important;
            border: 1px solid #2caf92 !important;

            .txt-area {
              color: #1d1e20;

              &.placeholder {
                color: #84888c;
              }
            }

            .drop-arrow {
              svg {
                stroke: #1d1e20;
              }
            }
          }

          &:focus {
            border: 1px solid #2caf92 !important;
          }
        }

        .dropdown-menu {
          .list-area {
            min-height: auto;
           
          }
        }

        &.show {
          .dropdown-toggle {
            background-color: #fff !important;
            border: 1px solid #2caf92 !important;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

            .drop-arrow {
              svg {
                stroke: #1d1e20;
              }
            }
          }
        }
      }
    }
  }
  .leftCol {
    padding-right: 40px;
  }
  .rightCol {
    padding-left: 40px;
  }
  @media (min-width: 20px) and (max-width: 1020px) {
    .leftCol {
      padding-right: 0px;
    }
    .rightCol {
      padding-left: 0px;
    }
  }
}
.hasDDMaxHeight {
  max-height: 300px;
  overflow: auto;
}
.hasDDFullWidth {
  width: 100% !important;
}
.transferControl {
  font-family: "OpenSans";
  font-size: 15px;
  color: #1d1e20;
}
.hasAmount {
  text-align: right;
  padding: 10px 6px !important;
  &::placeholder {
    text-align: left;
  }
}

.regAccHeader {
  width: auto !important;
  .single-select-image {
    .filter-btn {
      div:nth-child(1) {
        margin-right: 5px;
      }
    }
  }
}
.hasDisabledArea {
  pointer-events: none;
  opacity: 1;
  .btn,
  .form-control {
    opacity: 0.8;
  }
  label,
  strong,
  span,
  svg {
    opacity: 0.8;
  }
  .disabled-area {
    opacity: 1;
  }
}
 

/********************Ripple effect******/
.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.3s, opacity 1s;
  }

  &:active {
    &:after {
      transform: scale(0, 0);
      opacity: 0.3;
      transition: 0s;
    }
  }
}

// body {
//   background-color: #fff;
//   font-family: "Poppins";
//   font-size: 12px;
//   margin: 0px;
//   padding: 0px;
//  // overflow-x: hidden;
// }
.notes-mynotes-section{
  //margin-top: 1rem;
   
    margin-bottom: 0rem;
    margin-right: 0px;
   background-color: red;
   
}
.login-bg {
  overflow-x: hidden;
  background-color: #0E223F;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  .invalid-feedback {
    display: block !important;
    color: #dc3545;
    margin-top: 1px;
    font-size: 12px;
    //position: absolute;
  }
}

/*************Calender Start***************/
.calender-container {
  position: relative;
  margin: 0 8px 0 8px;

  .calender-display {
    margin: 0;
    width: auto;
    padding: 0 10px 0 10px;
    line-height: normal;
    height: 36px;
    line-height: 36px;
    align-items: center;
    font-family: "OpenSans-Semibold";
    font-size: 14px;
    color: #1d1e20;
    padding-left: 10px;
    //cursor: pointer;

    &.open {
      background-color: #364760;
      color: #fff;
      border-radius: 3px;

      svg {
        fill: #fff;
        margin-right: 4px;
      }

      &:hover {
        background-color: #364760;
      }
    }

    &:hover {
      background-color: #f4f6f8;
      border-radius: 3px;
    }

    svg {
      fill: #84888c;
      margin-right: 4px;
    }

    .prev-Date,
    .seperator,
    .next-Date,
    .current-Date {
      vertical-align: middle;
    }

    .chevron-down {
      svg {
        fill: none;
        margin-left: 4px;
      }
    }
  }

  .calender {
    position: absolute;
    top: 38px;
    z-index: 11;
    left: 0;
  }
}

.calender {
  position: relative;
  top: -23px;
  z-index: 11;

  .all-dates {
    position: absolute;
    top: 280px;
    width: 298px;
    left: 1px;
    font-family: "Poppins-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    background-color: #fff;
    padding: 16px 0px;
    border-top: 1px solid #e0e3e6;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    cursor: pointer;
  }

  .react-calendar {
    background: #ffffff;
    border: 1px solid #e0e3e6;
    box-sizing: border-box;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    min-width: 300px;
    position: absolute;

    .react-calendar__navigation {
      margin-bottom: 0;

      .react-calendar__navigation__arrow {
        &:hover {
          background-color: #f0f0f0;
        }

        &.react-calendar__navigation__prev-button {
          &::after {
            content: "";
            appearance: none;
            background: none;
            line-height: 1.7rem;
            text-align: center;
            cursor: pointer;
            position: absolute;
            top: 15px;
            left: 10px;
            width: 0;
            padding: 0;
            border: 0.45rem solid transparent;
            z-index: 1;
            height: 10px;
            width: 10px;
            text-indent: -999em;
            overflow: hidden;
            border-right-color: #ccc;
          }
        }

        &.react-calendar__navigation__prev2-button {
          display: none;
        }

        &.react-calendar__navigation__next-button {
          &::after {
            content: "";
            appearance: none;
            background: none;
            line-height: 1.7rem;
            text-align: center;
            cursor: pointer;
            position: absolute;
            top: 15px;
            right: 10px;
            width: 0;
            padding: 0;
            border: 0.45rem solid transparent;
            z-index: 1;
            height: 10px;
            width: 10px;
            text-indent: -999em;
            overflow: hidden;
            border-left-color: #ccc;
          }
        }

        &.react-calendar__navigation__next2-button {
          display: none;
        }
      }

      .react-calendar__navigation__label {
        // pointer-events: none;
        &:hover {
          background-color: #f0f0f0;
        }

        .react-calendar__navigation__label__labelText {
          color: #1d1e20;
          font-family: "Poppins-Medium";
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.16px;
          // &.react-calendar__navigation__label__labelText--from {
          // }
        }
      }
    }

    .react-calendar__viewContainer {
      .react-calendar__month-view {
        .react-calendar__month-view__weekdays {
          background-color: #f4f6f8;
          margin: 24px;
          color: #6a6e73;
          font-family: "OpenSans";
          font-style: normal;
          font-weight: normal;
          border-radius: 0.3rem;
          font-size: 12px;
          line-height: 16px;
          text-transform: capitalize;

          .react-calendar__month-view__weekdays__weekday {
            abbr[title] {
              text-decoration: none;
              cursor: default;
            }
          }
        }

        .react-calendar__month-view__days {
          margin: 24px;

          .react-calendar__tile {
            &.react-calendar__month-view__days__day {
              font-family: "OpenSans";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              color: #1d1e20;

              //margin: 0.166rem;
              &:hover {
                border-radius: 0.3rem;
                background-color: #f0f0f0;
              }
            }

            &.react-calendar__tile--now {
              background-color: #364760 !important;
              //background-color: #2caf92;
              color: #fff !important;
              border-radius: 0.3rem;

              &:hover,
              &:focus {
                border-radius: 0.3rem;
                background-color: #364760;
                //background-color: #2caf92;
              }

              &.react-calendar__tile--active {
                //background-color: #364760;
                //background-color: #2caf92;
                color: #fff;
                border-radius: 0.3rem;

                &:active {
                  background-color: #2caf92;
                }
                &:hover {
                  border-radius: 0.3rem;
                  background-color: #2caf92;
                }
                &:focus {
                  border-radius: 0.3rem;
                  //background-color: #364760;
                  background-color: #2caf92;
                }
              }
            }

            &.react-calendar__month-view__days__day--weekend {
              color: #1d1e20;
              &.react-calendar__tile--now {
                color: #fff !important;
              }
            }

            &.react-calendar__month-view__days__day--neighboringMonth {
              color: #84888c;
            }

            &.react-calendar__tile--active {
              //background: #dadada;
              background: #2daf92 !important;
              border-radius: 0.3em;
              color: #fff !important;

              &:hover {
                //background: #dadada;
                background: #2daf92;
                color: #fff;
              }
            }
            &:disabled {
              background-color: transparent;
              color: #ccc;
              &:hover {
                background-color: transparent;
              }
            }
          }
        }
      }

      .react-calendar__year-view {
        .react-calendar__year-view__months {
          margin: 24px;

          .react-calendar__tile {
            &.react-calendar__year-view__months__month {
              font-family: "OpenSans";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              color: #1d1e20;

              &:hover {
                border-radius: 0.3rem;
                background-color: #f0f0f0;
              }
            }

            &.react-calendar__tile--now {
              //background: #2caf92;
              background: #364760 !important;
              color: #fff !important;
              border-radius: 0.3rem;

              &:hover {
                border-radius: 0.3rem;
                //background-color: #2caf92;
                background-color: #364760;
              }
            }
          }
        }
      }

      .react-calendar__decade-view {
        .react-calendar__decade-view__years {
          margin: 24px;

          .react-calendar__tile {
            &.react-calendar__decade-view__years__year {
              font-family: "OpenSans";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              color: #1d1e20;

              &:hover {
                border-radius: 0.3rem;
                background-color: #f0f0f0;
              }
            }

            &.react-calendar__tile--now {
              background: #364760 !important;
              //background: #2caf92;
              color: #fff !important;
              border-radius: 0.3rem;

              &:hover {
                border-radius: 0.3rem;
                background-color: #364760;
                //background-color: #2caf92;
              }
            }
          }
        }
      }

      .react-calendar__century-view {
        .react-calendar__century-view__decades {
          margin: 24px;

          .react-calendar__tile {
            &.react-calendar__century-view__decades__decade {
              font-family: "OpenSans";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              color: #1d1e20;

              &:hover {
                border-radius: 0.3rem;
                background-color: #f0f0f0;
              }
            }

            &.react-calendar__tile--now {
              background: #364760 !important;
              //background: #2caf92;
              color: #fff !important;
              border-radius: 0.3rem;

              &:hover {
                border-radius: 0.3rem;
                background-color: #364760;
                //background-color: #2caf92;
              }
            }
          }
        }
      }
    }
  }
}
.smileIDCalender{
  .all-dates{
    top: 340px;
  }
}
/*************Calender Ends***************/

/*************Badges Start***************/
.red-badges,
.badge,
.blue-bg {
  margin-left: 5px;
  display: inline-block;
  padding: 3px 6px;
  background: #ffefed;
  border-radius: 3px;
  color: #b12a18;
  font-size: 12px;
  line-height: 18px;
  //font-family: "Poppins-Medium";
  font-family: "OpenSans-Semibold";
  font-style: normal;
  height: 24px;

  &.blue-bg {
    background: #e5f5ff;
    color: #065a8c;
  }

  &.green-bg {
    background: #d8fff6;
    color: #2caf92;
  }

  &.orange-bg {
    background: #fff3e8;
    color: #863902;
  }
  &.gray-bg {
    background: #e8eaed;
    color: #000;
  }
}

.badge {
  font-family: "OpenSans-Semibold";
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16px;
  background-color: #f4f6f8;
  color: #3f4347!important;
  padding: 4px 6px;

  &.color-red {
    background-color: #ffefed;
    color: #b12a18;
  }

  &.color-green {
    background-color: #defff8;
    color: #0e9074;
  }

  &.color-orange {
    background-color: #ffefed;
    color: #b12a18;
  }

  &.color-blue {
    background-color: #e5f5ff;
    color: #065a8c;
  }

  &.color-grey {
    background-color: #f4f6f8;
    color: #3f4347;
  }
}

.tab-badge {
  font-family: "OpenSans-Semibold";
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16px;
  background-color: #f4f6f8;
  color: #3f4347;
  padding: 3px 7px;
  border-radius: 50%;
  margin-left: 4px;

  &.color-red {
    background-color: #ffefed;
    color: #b12a18;
  }

  &.color-green {
    background-color: #defff8;
    color: #0e9074;
  }

  &.color-orange {
    background-color: #ffefed;
    color: #b12a18;
  }

  &.color-blue {
    background-color: #e5f5ff;
    color: #065a8c;
  }

  &.color-grey {
    background-color: #f4f6f8;
    color: #3f4347;
  }

  &.color-grey-beta {
    background-color: #e0e3e6;
    color: #3f4347;
    font-size: 11px !important;
    line-height: 18px !important;
    padding: 4px !important;
  }
}

/*************Badges Ends ***************/
 
.dropdown {
  &.bg-blue {
    .btn-primary {
      width: 100%;
      background-color: transparent;
      //border-color: black;
      border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      color: #1d1e20;
     // font-family: "OpenSans-Semibold";
    height:37.6px;
      svg {
        fill: #1d1e20;
        margin-right: 4px;
      }

      &:hover {
        background-color: #364760 !important;
        color: #fff !important;
        border-color: #364760 !important;

        svg {
          fill: #fff;
        }
      }
    }

    &.show {
      .dropdown-header {
        font-family: "OpenSans-Semibold";
        font-size: 10px;
        padding: 8px 17px 4px;
        line-height: 16px;
        color: #6a6e73;
      }

      .dropdown-item {
        border: none !important;

        svg {
          display: none;
        }
      }

      .active {
        background-color: transparent;
        font-family: "OpenSans-Semibold" !important;

        svg {
          display: block;
        }
      }
      .txt-area {
        max-width: 80%!important;
      }
      .btn-primary {
        background-color: #364760 !important;
        color: #fff !important;
        border-color: #364760 !important;

        svg {
          fill: #fff;
        }
      }
    }
    .btn {
    //   font-size: 14px!important;
    //   font-family: "OpenSans"!important;
    //   line-height: 18px;
    //   border-radius:  0.375rem;
    //   cursor: pointer;
    //   height: 38px;
    //   padding: 0px 16px!important;
    // //  border: none;
    //   letter-spacing: 0px;
    //   transition: all 0.5s ease;
    //   color: #3f4347;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  }

  // button {
  //   &::after {
  //     margin-left: 0.625em;
  //   }
  // }
}

.pos-rel {
  position: relative;

  .bck-drop {
    background-color: transparent;
    top: 0;
    right: 0;
    left: 0;
    bottom: 48px;
    z-index: 9;
    position: absolute;
    &.zIndex-1 {
      z-index: 1;
    }
  }
}

.pos-abs {
  position: absolute;
  z-index: 1;

  &.right-align {
    right: 0;
  }

  &.btm-margin-71 {
    bottom: 71px;
  }
}

.back-drop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  width: 35%;
  top: 0px;
  right: 0px;
  bottom: 0px;
}

.pos-fxd {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.index-1000 {
    z-index: 1006;
  }

  &.index-1010 {
    z-index: 1002;
  }
}


.pos-fxd2 {
  position: fixed;
  top: 0;
left: 70%;
  //left : calc(50.33% - 10px);
  transform: translate(-70%, 0);

  right: 000;
  bottom: 0;

  &.index-1000 {
    z-index: 1006;
  }

  &.index-1010 {
    z-index: 1002;
  }
}


.pos-fxd2full {
  position: fixed;
  top: 0;
//left: 70%;
  //left : calc(50.33% - 10px);
  transform: translate(-70%, 0);
width: 100%;
  right: 000;
  bottom: 0;

  &.index-1000 {
    z-index: 1006;
  }

  &.index-1010 {
    z-index: 1002;
  }
}


.pageLoader {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  left: 0;
  align-items: center;
  color: #2daf92;
  display: none;
  justify-content: center;
  bottom: 0;

  .spinner-border {
    width: 4rem;
    height: 4rem;
  }
}

.link {
  color: #065a8c;
}

/************scrollbar**************/
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
  background: #e5e5e5;
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #bababa;
  box-shadow: 0 0 1px #bababa;
  -webkit-box-shadow: 0 0 1px #bababa;
}

/*************Page layout****************/
.wht-bg {
  background-color: #fff;

  &.shadow-bg {
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.16), 0 2px 0 0 #f2f2f2;
  }
}

.flt-left {
  float: left;
}

.txt-center {
  text-align: center;
}


.card {
  background-color: #ffffff;
  border: 1px solid #e0e3e6;
  box-shadow: 0px 0px 4px rgba(44, 62, 88, 0.08);
  border-radius: 3px;
  transition: all 0.5s ease;
  transition: transform 0.3s, opacity 1s;

  .card-body {
    //padding: 40px 48px;
  }

  .card-heading {
    color: #364760;
    font-family: "Poppins-Medium";
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 32px;

    .sub-card-heading {
      font-size: 12px;
      margin-top: 5px;
      font-family: "Poppins-Medium";
    }

    .sub-card-section {
      font-size: 12px;
      margin-top: 15px;
      font-family: "Poppins-Medium";
      line-height: 18px;
      border-bottom: 1px solid #e5e5e6;

      .enableOtp {
        color: #1a0dab;
        cursor: pointer;
      }

      .disabledOtp {
        color: #1a0dab;
        cursor: default;
        opacity: 0.6;
      }

      p {
        margin-bottom: 5px!important;
      }

      ul {
        padding: 0px;
        margin-bottom: 10px;
        list-style: outside;
        margin-left: 20px;
      }
    }

    &.login-header {
      background-color: rgba(0, 0, 0, 0.03);
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }

    background-color: #fff;
    border-bottom: 0px;
  }
}


/***************single select image with search*******************/
.single-select-image {
  &.dropdown {
    .btn-primary {
      &.blue-bg {
        background-color: transparent;
        border-color: #fff;
        color: #2caf92;

        &::after {
          content: none;
        }

        &:focus,
        &:hover,
        &:not(:disabled):not(.disabled):active {
          border-color: #364760;
          background-color: #364760;
          color: #fff;

          .placeholder {
            color: #fff;
          }
        }
      }

      &.filter-btn {
        padding: 0px 16px;
        color: #1d1e20;
       width:170px;
       background-color:white;
        .drop-arrow {
          position: absolute;
          right: 5px;

          svg {
            fill: none;
            color: #1d1e20;
          }
        }

        &:hover {
          .drop-arrow {
            svg {
              fill: none;
              color: #fff;
            }
          }

          .placeholder {
            color: #fff;
          }
        }

        .txt-area {
          max-width: 100%;
          white-space: nowrap;
          //overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &::after {
        content: none;
      }
    }

    &.show {
      .btn-primary {
        background-color: #ffffff;
        color: #2caf92;
        border-color: #2caf92;

        &.blue-bg {
          border-color: #364760;
          background-color: #364760;
          color: #fff;
        }

        .drop-arrow {
          svg {
            fill: none;
            color: #fff;
          }
        }

        .placeholder {
          color: #fff;
        }

        .drop-arrow-icon {
          svg {
            fill: none;
            color: #fff;
          }
        }
      }
    }

    .dropdown-menu {
      width: 300px;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      border: 1px solid #e0e3e6;
      background: #ffffff;
      padding: 0px;
      //transform: translate(-40px, 58px) !important; // added by Rohit
     
      .search-sec {
        padding: 12px 16px;
        border-bottom: 1px solid #e0e3e6;

        .form-control {
          font-family: "OpenSans";
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          width: 100%;
          text-align: left;
        }
      }

      .list-area {
        min-height: 250px;
        max-height: 250px;
        overflow-x: hidden;
        overflow-y: auto;
       // background-color: red;
        .dropdown-item {
          padding: 13px 17px 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .list-section {
          border-bottom: 1px solid #e0e3e6;
          margin-bottom: 12px;
          font-family: "OpenSans";
          font-size: 14px;
          line-height: 18px;

          .__react_component_tooltip {
            width: 250px;
            white-space: break-spaces;
          }

          .sec-ttl {
            color: #6a6e73;
            font-weight: 600;
            font-size: 10px;
            letter-spacing: 1px;
            text-transform: uppercase;
            padding: 12px 16px;
          }

          .dropdown-item {
            padding: 0px;

            .sec-ttl {
              border-top: 1px solid #e0e3e6;
            }

            .d-flex {
              padding: 12px 16px;
            }

            .middle-column {
              flex-grow: 1;
              display: flex;
              flex-direction: column;
          }
          
            img {
              margin-right: 12px;
              border-radius: 50%;
              width: 36px;
              height: 36px;
              &.default {
                border: 1px solid #ccc;
              }
            }

            .name {
              color: #1d1e20;
              font-weight: 600;
              display: flex;

              .person-name {
                max-width: 184px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .all-user {
                max-width: 220px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .pos-name {
              color: #6a6e73;
              font-weight: normal;
              max-width: 220px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &:hover,
            &:focus {
              background-color: transparent;
            }
          }

          &:last-child {
            border-bottom: 0px;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

/***************Form section*******************/
.frm-section {
  .form-lbl {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
    //font-family: "Poppins-Medium";
    font-family: "OpenSans-Semibold";
  }

  .form-group {
    label {
      color: #2a394f;
      //font-family: "Poppins-Medium";
      font-family: "OpenSans-Semibold";
      font-size: 14px;
      text-transform: capitalize;
    }

    .form-control {
      border: 1px solid #c4cdd5;
      border-radius: 2px;
      font-size: 12px;
      padding: 10px 12px;
    }
  }

  .action {
    margin-top: 16px;
    display: block;
  }

  .custom-control {
    &.custom-radio {
      .custom-control-label {
        &:after,
        &:before {
          top: 1px;
        }
      }

      .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #2daf92;
        background-color: #2daf92;
      }
    }
  }

  .code-wrapper {
    .code {
      border: 1px solid #cccccc;
      border-radius: 2px;
      max-height: 47px;
      max-width: 47px;
      font-size: 30px;

      &.focus {
        border: 1px solid #2daf92;
      }
    }

    .code:not(:last-child) {
      margin-right: 15px;
    }
  }

  .progress {
    margin-top: 10px;
    height: 4px;

    &:before {
      left: calc(33% - 3px);
    }

    &:after {
      right: calc(33% - 3px);
      content: "";
      height: inherit;
      background: transparent;
      display: block;
      border-color: #fff;
      border-style: solid;
      border-width: 0 6px 0;
      position: absolute;
      width: calc(33% + 6px);
      z-index: 10;
    }

    .bg-warning {
      background-color: #d3412e !important;
    }

    .bg-info {
      background-color: #fca147 !important;
    }

    .bg-success {
      background-color: #1acaa3 !important;
    }

    .default {
      background-color: transparent !important;
    }
  }

  .progress-lbl {
    color: #84888c;
    position: absolute;
    top: 3px;
    left: -5px;
  }
}

.input-range__track {
  height: 4px;
  background: #e8eaed;
}

.input-range__track--active {
  background: #2caf92;
}

.input-range__slider {
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e3e6;
}

/************Form validation**************/
.was-validated {
  .form-control {
    &.invalid {
      border-color: #dc3545 !important;
      background-image: none !important;
      //background-image: url(./Common/Assets/Images/cross.svg) !important;
    }

    &.valid {
      //background-image: none;
      border-color: #1acaa3 !important;
      background-image: none !important;
      //background-image: url(./Common/Assets/Images/check.svg) !important;
    }

    &:valid {
      background-image: none !important;
    }
  }

  .invalid-feedback {
    display: block !important;
    color: #dc3545;
    margin-top: 1px;
    font-size: 12px;
    //position: absolute;
  }
}

.invalidusername {
  border-color: #dc3545 !important;
  background-image: none !important;
  //background-image: url(./Common/Assets/Images/cross.svg) !important;
}

.valid {
  //background-image: none;
  border-color: #1acaa3 !important;
  background-image: none !important;
  //background-image: url(./Common/Assets/Images/check.svg) !important;
}

// .valid {
//   background-image: none !important;
// }

.invalid-username {
  display: block !important;
  color: #dc3545;
  margin-top: 1px;
  font-size: 12px;
  //position: absolute;
}
/***********Login**************/
.help-captcha {
  .modal-header {
    .modal-title {
      color: #364760;
      font-family: "Poppins-Medium";
      font-size: 24px;
      line-height: 24px;
    }
    .close {
      span {
        color: #84888c;
        font-weight: normal;
      }
    }
  }
}
.login {
  .login-container {
    height: calc(100vh - 25px);
    margin-bottom: 25px;
    &.login {
      height: calc(100vh - 65px);
    }
    .action-link {
      font-family: "OpenSans";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #065a8c;
      &:hover {
        color: #065a8c;
        text-decoration: underline;
      }
    }
  }
  .help {
    color: #6a6e73;
    font-family: "OpenSans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    span {
      margin-left: 5px;
      margin-right: 5px;
    }
    .btn-primary {
      background-color: transparent;
      font-family: "OpenSans";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      padding: 0px;
      color: #065a8c;
      border: 0px;
      &:hover,
      &:focus,
      .active,
      &:not(:disabled):not(.disabled):active {
        background-color: transparent;
        color: #065a8c;
        border: 0px;
        text-decoration: underline;
      }
    }
  }

  .brand-img {
    text-align: center;
    margin-bottom: 32px;
  }

  .lg-btn {
    margin: 32px auto 0px;
    display: block;
    width: 80%;

    .fa-lock {
      margin-right: 5px;
    }
  }

  .divider {
    margin: 0px 5px;
  }

  .card-description {
    font-family: "OpenSans";
    font-size: 14px;
    color: #1d1e20;
    line-height: 18px;
    ul {
      list-style-type: disc;
    }
    .enableOtp {
      font-family: "OpenSans-Semibold";
      color: #2caf92;
      cursor: pointer;
    }
    .disabledOtp {
      font-family: "OpenSans-Semibold";
      color: #2caf92;
      cursor: default;
      opacity: 0.6;
    }
  }
}

.Forgot-action {
  text-align: center;
  margin-top: 32px;

  .btn {
    &:nth-child(2) {
      margin-left: 15px;
    }
  }
}

.forgot-password {
  .card {
    width: 466px;
  }
}
 
.base-blue {
  background-color: #364760;
  height: 100vh;
  
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .bg-opacity {
    background-color: rgba(21, 37, 61, 0.9);
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
  }
}

.base-white {
  background-color: transparent;
  height: 100vh;

  .card {
    //box-shadow: none;
    .card-heading,
    .card-footer {
      border: 0px;
      background-color: transparent;
    }
  }

  &.marketing {
    background-color: #fff;
  }
}

.login-body {
  height: 100vh;
  color: #fff;
  justify-content: center;
  padding: 65px;

  .title {
    font-family: "Poppins-Medium";
    font-size: 28px;
    margin-bottom: 24px;
    line-height: 18px;
  }

  .desc {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 2rem;
  }

  &.height-1 {
    height: 100vh;
  }
}

/***********Page Layout***********/
section {
  padding: 0 0 0;
}

/*********************Tab************/
.nav-tabs {
  padding-bottom: 1px;
  border-color: #e0e3e6;

  .nav-link {
    border: 0px;
    border-bottom: 3px solid #fff;
    font-family: "OpenSans-Semibold";
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    transition: all 0.5s ease;
    color: #6a6e73;
    outline: 0;
    padding: 0px 0px 19px 0px;
    margin-right: 24px;

    &.active,
    &:focus,
    &:hover {
      border-color: #2caf92;
      color: #1d1e20;
    }
  }
}

.tab-content {
 // padding: 24px 0px 0px;

  .section {
    margin-bottom: 48px;

    .sec-ttl {
      font-family: "Poppins-Medium";
      font-style: normal;
      font-size: 20px;
      line-height: 26px;
      color: #1d1e20;
      padding-bottom: 20px;
      border-bottom: 1px solid #e0e3e6;

      .sub-ttl {
        font-family: "OpenSans";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #6a6e73;
      }
    }

    .controlls {
      padding-top: 20px;
      padding-bottom: 28px;
      border-bottom: 1px solid #e0e3e6;
      .d-flex {
        &.margin-top {
          margin-top: 15px;
        }
      }
      &.pto-frequency {
        .date-picker {
          margin-top: 10px;
          left: 15px;
          width: 200px;
          border-radius: 3px;
          border: 1px solid #caced2;
          font-style: normal;
          font-weight: normal;
          font-family: "OpenSans";
          font-size: 14px;

          .react-datepicker-wrapper {
            .form-control {
              height: 38px;
              padding: 9px 20px 10px 50px;
              border: 0px;

              &.react-datepicker-ignore-onclickoutside {
                background-color: #fff;
                border: 0px;
              }
            }
          }
        }

        .calender-icon,
        .drop-arrow {
          position: absolute;
          top: 8px;
          left: 19px;
          z-index: 9;
        }

        .drop-arrow {
          right: 20px;
          left: auto;
        }

        .slt-frequency {
          width: 200px;
          margin-top: 10px;

          .btn-primary {
            background-color: transparent;
            background: #ffffff;
            border: 1px solid #caced2;
            width: 100%;
            box-sizing: border-box;
            border-radius: 3px;
            font-style: normal;
            font-weight: normal;
            font-family: "OpenSans";
            text-align: left;
            color: #1d1e20;
            width: 100%;

            &::after {
              position: absolute;
              right: 20px;
              top: 19px;
            }

            &.placeholder {
              color: #84888c;
            }
          }
          &.holidayAttribution {
            width: 270px;
            // padding-left: 0.5rem;
          }
        }
      }

      &.mdy-pad-btm {
        padding-bottom: 20px;
      }
      &.holiday-attribution {
        .holidayAttributionLabel {
          margin-top: 1rem;
          padding-right: 0.5rem;
          font-family: "OpenSans";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }
        .slt-frequency {
          width: 270px;
          margin-top: 10px;

          .btn-primary {
            background-color: transparent;
            background: #ffffff;
            border: 1px solid #caced2;
            width: 100%;
            box-sizing: border-box;
            border-radius: 3px;
            font-style: normal;
            font-weight: normal;
            font-family: "OpenSans";
            text-align: left;
            color: #1d1e20;
            width: 100%;

            &::after {
              position: absolute;
              right: 20px;
              top: 19px;
            }

            &.placeholder {
              color: #84888c;
            }
          }
          .dropdown-menu.show {
            font-size: 15px;
            font-family: "OpenSans";
          }
        }
      }

      .inline-row {
        display: inline-block;
        margin-right: 20px;

        .controlls-ttl {
          margin-bottom: 8px;
        }
      }

      .controlls-ttl {
        font-family: "OpenSans";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #1d1e20;
      }

      .custom-input {
        padding-top: 20px;

        &.mdy-pad-top {
          padding-top: 0px;
        }

        &.mdy-pad {
          padding-left: 56px;
        }

        &.mdy-pad1 {
          padding-left: 4px;
        }

        &.form-group {
          margin-bottom: 0px;
        }

        label {
          font-family: "OpenSans";
          font-size: 14px;
          line-height: 18px;
          color: #1d1e20;
          padding-left: 12px;
        }

        input {
          width: 60px;
          height: 40px;
          font-family: "OpenSans";
          display: inline-block;
          font-size: 14px;
          line-height: 18px;
          color: #1d1e20;
          text-align: center;
        }
      }

      .typeahead {
        position: relative;
        max-width: 600px;
        margin-top: 8px;

        .form-control {
          border-color: #caced2;

          &:focus {
            box-shadow: none;
          }
        }

        .rbt-input-multi {
          &.focus {
            box-shadow: none;
          }
          .rbt-token.rbt-token-disabled {
            background: #d4d8dd !important;
          }
        }

        .rbt-input-main {
          font-size: 14px;
          font-family: "OpenSans";
           
        }

        .rbt-token {
          background: #e8eaed;
          border-radius: 100px;
          color: #3f4347;
          font-size: 12px;
          font-family: "OpenSans";
          font-weight: 600;
          margin-right: 8px;
          padding: 6px 26px 6px 15px;

          // &:disabled{
          //   background: red !important;
          // }

          .rbt-token-remove-button {
            font-size: 12px;
            font-family: "OpenSans";
            font-weight: 600;
            right: 3px;
            top: 0px;
          }
        }

        .dropdown-item {
          font-family: "OpenSans";
          font-size: 12px;

          &:hover,
          &.active,
          &:focus {
            background-color: #e8eaed;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

// .custom-switch {
//   .custom-control-input {
//     width: 48px;
//     height: 28px;
//     outline: 0px;
//   }

//   .custom-control-label {
//     font-family: "OpenSans";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 18px;
//     padding-left: 20px;
//    // padding-top: 10px;

//     &::before {
//       width: 48px;
//       height: 28px;
//       border: 0px;
//       background: #e8eaed;
//       border-radius: 100px;
//     }

//     &::after {
//       width: 20px; //Changed from 24px to 20px
//       height: 20px; //Changed from 24px to 20px
//       background-color: #fff;
//       border-radius: 100%;
//       top: calc(0.25rem + 4px); //added by Rohit
//       left: calc(-2.25rem + 4px); //added by Rohit
//     }
//   }

//   .custom-control-input:checked ~ .custom-control-label::before {
//     background: #2caf92;
//   }

//   .custom-control-input:checked ~ .custom-control-label::after {
//     background-color: #fff;
//     transform: translateX(1rem);
//   }

//   &.sm-controll {
//     margin-top: 4px;

//     .custom-control-input {
//       width: 36px;
//       height: 20px;
//     }

//     .custom-control-label {
//       padding-left: 0px;

//       &:before {
//         width: 36px;
//         height: 20px;
//         box-shadow: 0 0 0 0.2rem transparent; // edited by Rohit
//         cursor: pointer; // edited by Rohit
//       }

//       &:after {
//         // width: 18px;
//         // height: 18px;
//         // top: calc(0.25rem + 1px);
//         // edited by Rohit
//         width: 14px;
//         height: 14px;
//         top: calc(0.25rem + 3px);
//       }
//     }

//     .custom-control-input:checked ~ .custom-control-label::after {
//       transform: translateX(0.9rem);
//     }
//   }
// }
// .custom-switch {
//   .custom-control-input:disabled + .custom-control-label {
//     &:before {
//       background: #e8eaed !important;
//       border-radius: 100px !important;
//     }
//     &:after {
//       border-radius: 100% !important;
//       top: calc(0.25rem + 4px) !important;
//       left: calc(-2.25rem + 4px) !important;
//     }
//   }
// }

/********Toast message*********/
.Toastify {
  .Toastify__toast-container {
    width: 464px;
    z-index: 9999999901;
  }

  .Toastify__close-button {
    color: #a5a5a5;
    padding: 5px;

    &:hover {
      color: #1d1e20;
    }
  }

  .Toastify__toast--success,
  .Toastify__toast--error {
    background-color: #fff;
    border: 1px solid #e0e3e6;
    border-left: 4px solid #2caf92;
    width: 464px;
    border-radius: 3px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);

    font-family: "OpenSans";
    font-style: normal;
    font-weight: 600;
    color: #1d1e20;
    word-wrap: break-word;
    white-space: break-spaces;
  }

  .Toastify__toast--error {
    border-left: 4px solid #fca147;
  }

  .Toastify__toast {
    min-height: 50px;
    padding-left: 15px;
  }
}



/****************Slider****************/
.RangeSlider {
  //border-top: 1px solid #e0e3e6;
  margin-top: 32px;

  //padding-top: 32px;
  .infoText {
    color: #6a6e73;
    font-family: "OpenSans";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    padding: 10px 0px;
    float: right;
  }

  .dollarVal {
    position: absolute;
    top: 2px;
    left: 5px;
    padding: 10px 0px;
  }

  .slider-ttl {
    font-family: "Poppins-Medium";
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    color: #1d1e20;
    margin-bottom: 16px;
  }

  .form-group {
    position: relative;
  }

  .form-control {
    padding: 10px 8px;
    background: #ffffff;
    border: 1px solid;
    border-color: #fff;
    box-sizing: border-box;
    border-radius: 3px;
    width: 60px;
    text-align: center;
    font-family: "OpenSans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

    &.time-picker {
      padding: 0px;

      .react-time-picker__inputGroup {
        min-width: auto;
        padding: 0px;
      }
    }

    &.dollar {
      padding: 10px 14px;
      width: 80px;
    }
  }

  .addRange {
    font-family: "OpenSans-Semibold";
    font-style: normal;
    font-size: 14px;
    border: 0px;
    line-height: 18px;
    color: #2caf92;
    cursor: pointer;
    display: inline-block;
    background-color: transparent;
    width: auto;

    &.form-control {
      color: #2caf92 !important;
    }
  }

  .action-btn {
    display: none;

    .fa-trash,
    .fa-divide {
      border-radius: 3px;
      background: #f4f6f8;
      padding: 8px;
      cursor: pointer;

      &:hover {
        background: #e8eaed;
      }
    }

    svg {
      fill: #2caf92;
    }

    .fa-trash {
      margin-right: 8px;
    }

    &.invalid {
      .fa-trash {
        margin-right: 0px;
      }

      .fa-divide {
        display: none;
      }
    }
  }

  tr:hover {
    .action-btn {
      display: flex;
    }
  }

  .form-group {
    margin-bottom: 0px;
  }

  td {
    vertical-align: middle;
    text-align: center;
  }

  td:nth-child(1),
  td:nth-child(3) {
    width: 60px;
  }
  td:nth-child(4) {
    width: 115px;
  }
  td:nth-child(5) {
    width: 100px;
  }

  tr:nth-of-type(even) {
    background-color: #f4f6f8;
  }

  tr:nth-of-type(even) {
    background-color: #f4f6f8;
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: #fff;
    }

    tr:last-child {
      .hideSlider {
        & .input-range {
          & .input-range__track {
            & .input-range__slider-container:nth-child(3) {
              & .input-range__slider {
                display: none;
              }
            }
          }
        }
      }

      .showSlider {
        & .input-range {
          & .input-range__track {
            & .input-range__slider-container:nth-child(3) {
              & .input-range__slider {
                display: none;
              }
            }
          }
        }
      }
    }

    tr:first-child {
      td {
        border-top: 0px;
      }

      & td:first-child {
        .form-control {
          border: 0px;
          background: transparent;
        }
      }

      & td {
        & .input-range {
          & .input-range__track {
            & .input-range__slider-container:nth-child(2) {
              & .input-range__slider {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  thead {
    tr {
      background: #e8eaed;
      height: 48px;

      th {
        padding: 0px;
        text-align: center;
        ///font-family: "Poppins-Medium";
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.16px;
        color: #1d1e20;
        vertical-align: middle;
        text-transform: none;
      }
    }
  }

  .input-range__label--min,
  .input-range__label--max,
  .input-range__label--value,
  .input-range__label--value {
    display: none;
  }

  .table {
    margin-bottom: 0px;

    tr {
      &:hover {
        .form-control {
          border-color: #caced2;

          &:focus {
            border-color: #2caf92 !important;
          }
        }
      }
    }
  }
}

// Budget Setting
.budget-settings-main {
  .page-heading {
    .__react_component_tooltip {
      max-width: 230px !important;
      text-align: left !important;
    }
    .recalculate-budgets-wrapper {
      position: relative;
      .btn {
        color: #fff;
        background-color: #065a8c !important;
        border: 1px solid #065a8c !important;
        &:hover {
          color: #fff;
          background-color: #063551 !important;
          border: 1px solid #063551 !important;
        }
        &:active,
        &:focus {
          color: #fff;
          background-color: #065a8c !important;
          border: 1px solid #065a8c !important;
        }
      }
    }
  }
}

.budget-settings {
  width: 100%;
  min-height: calc(100vh - 186px);
  border: 1px solid #e0e3e6;
  border-radius: 3px;

  &.holidays {
    min-height: calc(100vh - 146px);

    .info-badges {
      background: #e5f5ff;
      border-radius: 3px;
      padding: 12px 16px;
      margin-bottom: 16px;
      color: #065a8c;
      font-size: 14px;
      line-height: 18px;
      font-family: "OpenSans";

      .btn-primary {
        margin-top: 10px;
      }
    }
  }
.left-panel
{
width:38% ;
background-color: #f4f6f8;

}
 

  .right-panel {
    width: 62%;
    position: relative;

    .search-header {
      &.auto-complete {
        width: 90%;
      }
    }

    .pos-header {
      border: 1px solid #e0e3e6;
      border-top: 0px;
      border-right: 0px;
      padding: 0px 72px;
      height: 73px;
      border-left: 0px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);

      .pos-ttl {
        font-family: "Poppins-Medium";
        font-style: normal;
        font-size: 24px;
        white-space: nowrap;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #1d1e20;

        .form-control {
          color: #1d1e20;
          font-family: "Poppins-Medium";
          font-style: normal;
          font-size: 24px;
          line-height: 24px;
          height: 40px;

          &.input-component {
            padding-top: 0px;
            padding-bottom: 0px;
            width: auto;
            max-width: 84%;

            &.bdr-green {
              border-color: #2caf92;
            }

            input {
              outline: none;
              border: 0px;
              max-width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .service-status {
          margin-left: 5px;
          display: inline-block;
          padding: 3px 6px;
          background: #ffefed;
          border-radius: 3px;
          color: #b12a18;
          font-size: 12px;
          line-height: 18px;
          font-family: "Poppins-Medium";
          font-style: normal;
          border: 1px solid #ffd3ce;
        }
      }

      .pos-rel {
        .pos-abs {
          z-index: 2;
        }
      }

      .more {
        border: 0px;
      }
    }

    .pos-body {
      padding: 15px 72px;
      height: calc(100vh - 261px);
      overflow: auto;
      font-family: "OpenSans";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #1d1e20;

      //padding-bottom: 80px;
      textarea {
        resize: none;
      }

      &.dataChange {
        padding-bottom: 80px;
      }

      .form-group {
        margin-bottom: 8px;
      }

      .FactorConfiguration {
        .form-group {
          margin-bottom: 24px;
        }
      }

      .form-control-plaintext,
      .form-control {
        font-size: 14px;
        line-height: 18px;
        color: #1d1e20;
        &.min-digits {
          width: 52px;
          margin-right: 5px;
        }
      }

      .col-form-label {
        font-family: "OpenSans-Semibold";

        svg {
          fill: #6a6e73;
          margin-left: 4px;

          &:hover {
            fill: #1d1e20;
          }
        }
      }

      .dropdown {
        .btn {
          width: 100%;
          text-align: left;
          font-size: 14px;
          line-height: 18px;
          color: #1d1e20;
          font-family: "OpenSans" !important;
          font-weight: normal;
          background: #ffffff;
          border: 1px solid #fff;
          box-sizing: border-box;
          border-radius: 3px;
          padding: 0px 8px;

          &:after {
            margin: 8px 0px;
            float: right;
            content: none;
          }

          &:disabled {
            opacity: 0.5 !important;
          }
          &:hover {
            background-color: #e8eaed;
          }
        }

        &.show {
          .btn {
            border-color: #2caf92;

            &:after {
              content: "";
            }

            &:hover {
              background-color: #fff;
            }
          }

          .dropdown-menu {
            background: #ffffff;
            border: 1px solid #e0e3e6;
            box-sizing: border-box;
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            padding: 0px;
            right: 0 !important;
            max-height: 200px;
            overflow: auto;
            margin-top: 0px;

            .dropdown-item {
              padding: 9px 17px;
              font-family: "OpenSans";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              color: #1d1e20;
              cursor: pointer;

              &:hover {
                background-color: #f4f6f8;
              }
            }
          }
        }
      }

      .border-line {
        border-bottom: 1px solid #e0e3e6;
        // margin-bottom: 14px;
        // margin-top: 16px;
        display: none;
      }

      .totalcount {
        display: block;
        position: inherit;
        padding-left: 29px;
        font-family: "OpenSans-Semibold";
        font-style: normal;
        font-size: 10px;
        line-height: 18px;
      }

      .react-checkbox-tree label:hover {
        background: none;
      }

      .selectallchild {
        color: #2caf92;
        margin-left: 86%;
        font-family: "OpenSans-Semibold";
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
      }

      .rct-collapse.rct-collapse-btn {
        cursor: pointer;
        margin-top: -18px;
      }

      .rct-title {
        font-weight: bold;
        font-family: "OpenSans-Semibold";
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
      }

      .rct-options {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        margin-left: -5.5rem;
        text-align: right;
        margin-top: -30px !important;
      }

      .rct-option-expand-all {
        color: #2caf92;
        font-family: "OpenSans-Semibold" !important;
        font-style: normal !important;
        font-size: 14px !important;
        line-height: 18px !important;
      }

      .rct-option-collapse-all {
        color: #2caf92;
        font-family: "OpenSans-Semibold" !important;
        font-style: normal !important;
        font-size: 14px !important;
        line-height: 18px !important;
      }
    }

    .new-budget {
      margin: 14px 0px 14px 17px;
      font-family: "OpenSans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #2caf92;
    }
  }
}

.recalculate-modal {
  .react-confirm-alert {
    .react-confirm-alert-body {
      h1 {
        font-family: "OpenSans-Semibold" !important;
        font-size: 16px;
        letter-spacing: 0.16px;
        margin-bottom: 0;
        padding-bottom: 1rem;
        border-bottom: 1px solid #e0e3e6;
      }
      .recalculate-budget-desc {
        font-family: "OpenSans";
        font-size: 14px;
        color: #863902;
        line-height: 18px;
        background-color: #fff3e8;
        padding: 14px;
        margin: 14px 0;
        .bold {
          font-family: "OpenSans-Semibold";
          margin-bottom: 10px;
        }
      }
      .react-confirm-alert-button-group {
        .leave-without-recalc {
          font-family: "OpenSans-Semibold" !important;
          font-size: 12px !important;
          color: #fff !important;
          line-height: 18px;
          background: #2caf92 !important;
        }
        .return-to-settings {
          font-family: "OpenSans-Semibold" !important;
          font-size: 12px !important;
          color: #2caf92 !important;
          line-height: 18px;
          background-color: #fff;
        }
      }
    }
  }
}
// HID select
.hid-select {
  .btn {
    font-family: "Poppins-Medium";
    font-style: normal;
    font-size: 16px;
    height: 32px;
    background: #e5f5ff;
    border-radius: 3px;
    color: #065a8c;
    padding: 0px 8px;
    margin-right: 8px;
    width: 80px;
    border: none;

    .drop-arrow {
      position: relative;
      left: 2px;
      right: 0;
    }

    &::after {
      content: none;
    }

    &:hover {
      background-color: #d1edff !important;
      color: #065a8c !important;
    }

    &:active,
    &:focus {
      background: #e5f5ff;
      border-radius: 3px;
      color: #065a8c;
    }
  }

  .dropdown-menu {
    z-index: 1002;
    min-width: 80px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e3e6;
    border-radius: 3px;
    background: #ffffff;
    padding: 0px;
    margin: 0px;
    overflow-y: scroll;
    max-height: 500px;
    width: 50px;

    .dropdown-item {
      font-family: "OpenSans";
      font-size: 14px;
      padding: 10px 17px 5px;
      color: #1d1e20;
      font-weight: normal;
      line-height: 18px;
     
 
    // background-color: red;
      &:hover,
      &:active,
      &:focus {
        background-color: #e9ecef;
        color: #1d1e20;
        outline: 0px;
      }
    }
  }

  &.show {
    .btn {
      background-color: #364760 !important;
      color: #fff;
      border-color: #364760;
      &:hover {
        color: #fff !important;
      }
    }
  }
}

.tooltip {
  .tooltip-inner {
    max-width: 233px;
    background-color: #364760;
    border-radius: 3px;
    font-family: "OpenSans";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    padding: 4px 8px;
    color: #ffffff;
    text-align: left;
    opacity: 1;
  }
  &.bs-tooltip-bottom {
    .arrow {
      &:before {
        border-bottom-color: #364760;
      }
    }
  }
  &.bs-tooltip-right {
    .arrow {
      &:before {
        border-right-color: #364760;
      }
    }
  }
  &.tooltip-custom {
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    .arrow {
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .tooltip-inner {
      min-width: 160px;
      //text-align: center;
    }
  }
  &#letf-nav {
    left: 8px !important;
  }
}

.more-action {
  &.dropdown {
    &.show {
      .more {
        background: #364760;
        color: #fff;
        border-color: #364760;
      
        svg {
          fill: #fff;
        }
      }
    }
    #scrollable-dropdown-menu .tt-dropdown-menu {
      max-height: 150px;
      overflow-y: auto;
      
    }


    .dropdown-menu {
      background: #fff;
      border: 1px solid #e0e3e6;
      border-radius: 3px;
      box-shadow: 0 3px 8px #0000001a;
      margin: 0;
      padding: 0;
      .dropdown-item {
        font-family: "OpenSans";
        font-size: 14px;
        padding: 13px 17px 12px;
        color: #1d1e20;
        font-weight: normal;
        line-height: 18px;
        border-bottom: 1px solid #e0e3e6;

        img {
          margin-right: 5px;
        }

        &:hover,
        &:active,
        &:focus {
          background-color: #f4f6f8;
          color: #1d1e20;
          outline: 0px;
        }
      }
    }
  }

  &.date-selector {
    display: inline-block;

    .btn-primary {
      background: #ffffff;
      border: 1px solid #caced2;
      box-sizing: border-box;
      border-radius: 3px;
      color: #1d1e20;
      font-family: "OpenSans";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      width: 119px;
      padding: 0px 12px;
      text-align: left;

      &::after {
        content: none;
      }

      img {
        padding-right: 10px;
      }

      .arrow {
        position: absolute;
        top: 14px;
        right: 0;
      }

      &.show,
      &:focus,
      &:active,
      &:not(:disabled):not(.disabled):active {
        color: #1d1e20;
        border-color: #caced2;
      }
    }

    .dropdown-menu {
      min-width: auto;
      right: 0 !important;
    }
  }
}

.action-group {
  .btn {
    margin-left: 16px;
  }

  .divider {
    border-left: 1px solid #e0e3e6;
    height: 40px;
    margin-left: 24px;
    margin-right: 8px;
  }
}

.auto-complete {
  &:first-child div {
    display: block !important;
    z-index: 9;
  }

  input {
    background: #ffffff;
    border: 1px solid #caced2;
    box-sizing: border-box;
    border-radius: 3px;
    color: #1d1e20;
    font-family: "Poppins-Medium";
    padding: 0px 8px;
    font-size: 24px;
    line-height: 26px;
    display: block;
    width: 100%;
    height: 40px;

    &:focus {
      outline: 0px;
      border-color: #2caf92;
    }

    & ~ div {
      background: #ffffff;
      border: 1px solid #e0e3e6;
      box-sizing: border-box;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1) !important;
      border-radius: 3px !important;
      padding: 0px !important;

      div {
        padding: 9px 17px;
        font-family: "OpenSans";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #1d1e20;
        cursor: pointer;

        &:hover {
          background-color: #f4f6f8 !important;
        }
      }
    }

    &::placeholder {
      color: #84888c;
    }
  }
}

.fixed-action {
  background: #e5f5ff;
  border-top: 1px solid #e0e3e6;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 3px 0px;
  position: absolute;
  bottom: 0px;
  height: 64px;
  //left: 0px;
  right: 0px;
  width: 100%;
  //padding: 0px 72px;
  z-index: 2;

  .message {
    color: #065a8c;
    font-family: "OpenSans-Semibold";
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    padding: 10px 0px;
  }

  .d-flex {
    height: 64px;
  }

  &.relative {
    position: relative;
    border-top: 0px;
    padding: 0px 16px;
  }
}

.react-time-picker__wrapper {
  border: none !important;

  .react-time-picker__inputGroup {
    min-width: none !important;
    padding: none !important;
  }
}

.form-control-plaintext,
.form-control {
  padding: 5px 8px;
}

.form-control-plaintext {
  &:focus {
    outline: 0px;
    box-shadow: none;
  }
}

.form-control {
  &:focus {
    outline: 0px;
    border-color: #2caf92;
    box-shadow: none;
  }

  &::placeholder {
    color: #84888c;
  }
}

.placeholder {
  color: #84888c;
}

 
/***********Multi select search*************/
.multiSelectSearch {
  display: block;
  width: 100%;

  .budgetCoa {
    min-width: 400px !important;
  }
  .fieldArea {
    min-width: 250px;
    display: inline-block;

    .chip {
      padding: 4px 8px;
      border-radius: 3px;

      .chipSelect {
        background: #e0e3e6;
        border-radius: 100px;
        font-family: "OpenSans";
        font-size: 12px;
        line-height: 16px;
        padding: 5px 8px 4px;
        display: inline-block;
        margin-right: 4px;
        margin-bottom: 4px;
        vertical-align: bottom;

        .cross,
        .chipVal {
          display: inline-block;
          vertical-align: middle;
        }

        .cross {
          margin-left: 8.2px;
          padding: 0px 4px;
          background: transparent;
          border: 0px;
          outline: 0px;
          cursor: pointer;
          height: 15px;
          vertical-align: top;
        }

        &:hover {
          background: #caced2;
        }
      }
    }

    .coaSearch {
      position: relative;
      z-index: 2;
      display: none;

      .dropDownPanel {
        position: absolute;
        top: 0px;
        background: #ffffff;
        border: 1px solid #e0e3e6;
        box-sizing: border-box;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        width: 100%;

        .SearchInput {
          padding: 9px 17px;
          border-bottom: 1px solid #e0e3e6;
        }

        ul {
          margin: 0px;
          padding: 0px;
          list-style: none;
          max-height: 150px;
          overflow: auto;
          .section-ttl {
            text-transform: uppercase;
            font-size: 10px;
            font-family: "OpenSans-Semibold";
            padding: 10px 17px 0px;
          }

          li {
            padding: 10px 17px;

            .custom-checkbox {
              padding-left: 0px;

              .custom-control-label {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-left: 32px;

                &:before {
                  left: 0;
                }

                &:after {
                  left: 0;
                }
              }

              .custom-control-input {
                &:checked {
                  & ~ .custom-control-label {
                    color: #1d1e20;
                    font-family: "OpenSans-Semibold";
                  }
                }

                &:focus {
                  outline: 0px;
                }
              }
            }

            label {
              color: #1d1e20;
              font-size: 14px;
            }
          }
        }
        &.inner-label{
          .custom-control-input{
            position: static !important;
          }
          .custom-control-label{
            display: none;
          }
        }
      }

      .footer {
        border-top: 1px solid #e0e3e6;
        padding: 5px 0px;
      }
    }

    .addCoa {
      background: transparent;
      padding: 6.4px;
      border: 0px;
      outline: 0px;

      svg {
        fill: #2caf92;
      }

      &:hover {
        background: #f4f6f8;
      }
    }
  }

  &.opened {
    .coaSearch {
      display: block;
    }

    .chip {
      background: #e8eaed;

      .chipSelect {
        background: #caced2;
      }
    }
  }
}

.custom-checkbox {
  min-height: 16px;
  padding-left: 32px;

  .custom-control-label {
    &:before {
      top: 0.1rem;
      border-radius: 3px;
      border: 1px solid #caced2;
    }

    &:after {
      top: 0.1rem;
    }
  }

  .custom-control-input {
    &:checked,
    &:indeterminate {
      & ~ .custom-control-label {
        &::before {
          color: #fff;
          border-color: #2caf92;
          background-color: #2caf92;

          &:focus {
            outline: 0px;
            box-shadow: none;
          }
        }
      }
    }
    &:disabled{
      &:checked{
        & ~ .custom-control-label{
          &::before{
            background-color: rgba(44, 175, 146, 0.5);
            border-color: rgba(44, 175, 146, 0.5);
          }
        }
      }
    }
  }
}

.FactorConfiguration {
  background: #ffffff;
  border: 1px solid #e0e3e6;
  box-sizing: border-box;
  border-radius: 3px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 0px;
  margin-bottom: 1rem;

  .title {
    color: #1d1e20;
    font-size: 16px;
    line-height: 22px;
    font-family: "Poppins-Medium";
    padding: 16px 24px;
    border-bottom: 1px solid #e0e3e6;
  }

  .selection {
    padding: 24px 24px 0px;

    .percentage,
    .doller {
      color: #6a6e73;
      font-size: 14px;
      line-height: 18px;
      font-family: "OpenSans";
      position: absolute;
      right: 25px;
      padding: 8px 0px;
    }

    .doller {
      right: 82px;
    }

    .form-group {
      margin-bottom: 24px;
    }

    .form-control {
      width: 85px;
      float: right;

      &.modify-pad {
        padding-left: 25px;
      }
    }
  }
}

.removeBorder {
  border-color: #fff;
  &.min-content-width {
    width: 10% !important;
    text-align: center !important;
    min-width: 45px;
    background: #f9f9f9;
    &:hover {
      border: 1px solid #e8e8e8;
    }
  }
  &.max-content-width {
    width: 17% !important;
    text-align: center !important;
  }
  &.max-content-width2 {
    width: 25% !important;
    text-align: center !important;
  }

  &:focus {
    border-color: #2caf92;
  }
}

 
 

 
 

.modal-footer {
  button.bulkCopyAlertButton {
    background: #364760 !important;
    color: #fff !important;
    border-color: #364760 !important;
  }
}

 



.bdy-sec {
  margin-top: 23px;

  .page-sub-ttl {
    color: #1d1e20;
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;
  }
}

 
.single-select-dropdown-right-Important{
  .single-select{
    &.dropdown {
      .dropdown-menu{
        right :0 !important;
      }
    }
  }
}
.single-select {
  &.dropdown {
    position: relative;

    .btn {
      width: 100%;
      text-align: center;

      &:after {
        margin: 8px 0px;
        float: right;
        color: white;
      }

      &.btn-primary {
        //background: #e8eaed;
        border: 1px solid #e0e3e6;
        box-sizing: border-box;
      //  border-radius: 3px;
        color: #1d1e20;
        font-family: "OpenSans";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;

        .placeholder {
          color: #84888c;
        }
      }
    }

    &.show {
      .btn-primary {
        background: #ffffff;
        color: #1d1e20;
      }
    }

    .dropdown-menu {
      background: #ffffff;
      right: 0px; 
      min-width: 100%;
      border: 1px solid #e0e3e6;
      box-sizing: border-box;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      padding: 0px;

      .search-sec {
        border-bottom: 1px solid #e0e3e6;
        padding: 8px 16px;
      }

      .list-area {
        max-height: 200px;
        overflow-y: auto;
      }

      .dropdown-item {
        color: #1d1e20;
        font-family: "OpenSans";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        padding: 9px 16px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        &:focus {
          //background-color: #f8f9fa;
          background-color: #e9ecef;
          color: #1d1e20;
        }

        &:hover {
          background-color: #e8eaed;
          color: #1d1e20;
        }
      }
    }
  }
}

.confirmModal {
  background: #ffffff;
  border: 1px solid #e0e3e6;
  box-sizing: border-box;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
  width: 350px;
  max-width: 350px;
  white-space: break-spaces;

  .title {
    font-family: "Poppins-Medium";
    align-items: center;
    letter-spacing: 0.16px;
    color: #1d1e20;
    padding: 16px;
  }

  .description {
    font-family: "OpenSans";
    font-weight: normal;
    align-items: center;
    color: #1d1e20;
    padding: 0px 16px 16px;
    word-break: break-word;
  }

  .action-link {
    border-top: 1px solid #e0e3e6;
    padding: 12px 16px;
    text-align: right;
    display: flex;
    justify-content: flex-end;

    .btn {
      white-space: nowrap;
      margin-left: 12px;

      &.dropdown-item {
        width: auto;
        padding: 7px 20px;
      }

      &.btn-danger,
      &.btn-primary,
      &.wht-bg {
        font-family: "OpenSans-Bold";
        height: 32px;
        border: 0px;
      }

      &.btn-danger,
      &.btn-primary {
        color: #fff;
      }

      &.btn-danger {
        background-color: #b12a18 !important;
      }
    }

    .username {
      font-family: "Poppins-Medium";
      font-style: normal;
      font-size: 14px;
      line-height: 22px;
      justify-content: start;

      .name {
        width: 110px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        padding-left: 5px;
      }
    }

    &.justify-content-start {
      justify-content: start;
    }
  }
}

.confirmModalNonTable {
  position: absolute;
  left: 243px;
  top: 338px;
  background: #ffffff;
  border: 1px solid #e0e3e6;
  box-sizing: border-box;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
  width: 348px;
  color: #1d1e20;

  .title {
    font-family: "Poppins-Medium";
    letter-spacing: 0.16px;
    padding: 16px;
  }

  .description {
    font-family: "OpenSans";
    font-weight: normal;
    padding: 0 15px 12px;
  }

  .action-link {
    border-top: 1px solid #e0e3e6;
    padding: 12px 15px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;

    .btn {
      margin-left: 12px;

      &.wht-bg {
        background-color: transparent;
        height: auto;
        padding: 0 20px;
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.jobprocess-popup-width {
  width: 1050px !important;
}
/** CSS for rect overlaymodal **/
.react-confirm-alert-overlay {
  z-index: 1200;
  .react-confirm-alert {
    .react-confirm-alert-body {
      font-family: "OpenSans";
      font-style: normal;
      font-size: 14px;
      width: 400px;
      padding: 15px;
      text-align: left;
      background: #fff;
      border-radius: 3px;
      border: 1px solid #e0e3e6;
      box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
      color: #1d1e20;
      z-index: 1205;

      h1 {
        font-family: "OpenSans-Semibold";
        font-size: 16px;
        letter-spacing: 0.16px;
      }

      .react-confirm-alert-button-group {
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
        padding-top: 10px;
        flex-direction: row-reverse;
        border-top: 1px solid #e0e3e6;

        & > button:nth-child(1) {
          font-family: "OpenSans";
          font-weight: 600;
          background: #d3412e;
          border-radius: 3px;
        }

        & > button:nth-child(2) {
          font-family: "OpenSans";
          font-weight: 600;
          background: none !important;
          color: #1d1e20;
        }
      }
    }
    
  }
  &.horizontalScrollAlert {
    .react-confirm-alert-button-group {
      .leave-without-recalc {
        background: #2caf92 !important;
      }
    }
  }
}

.picker {
  grid-column: 5/10;
  grid-row: 2/4;
  // background: cornflowerblue;

  input {
    border: none;
    display: block;
  }
}

 

 

.delete-Confirm {
  .confirmModal {
    position: absolute;
    top: 40px;
    right: 0;
    left: auto;
    z-index: 1000;
  }
}

 

.date-picker {
  position: relative;

  .calender-selector-icon {
    position: absolute;
    top: 11px;
    left: 22px;
  }

  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      .form-control {
       // padding-left: 31px;
        //height: 40px;

        &:disabled {
          background-color: #e9ecef !important;
        }
        &:focus,
        &.react-datepicker-ignore-onclickoutside {
          border-color: #e8eaed;
          background-color: #e8eaed;
        }

        &:disabled {
          background-color: transparent;
        }
      }
    }
  }

  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      margin-top: 0px;
      z-index: 100;
      .react-datepicker {
        background: #ffffff;
        border: 1px solid #e0e3e6;
        box-sizing: border-box;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        min-width: 300px;

        .react-datepicker__navigation {
          background: none;
          line-height: 1.7rem;
          text-align: center;
          cursor: pointer;
          position: absolute;
          top: 18px;
          width: 0;
          padding: 0;
          border: 0.45rem solid transparent;
          z-index: 1;
          height: 10px;
          width: 10px;
          text-indent: -999em;
          overflow: hidden;
        }

        .react-datepicker__triangle {
          display: none;
        }
        .react-datepicker__navigation--previous {
          left: 10px;
          border-right-color: #000;
      }
        .react-datepicker__navigation--next {
          right: 10px;
          border-left-color: #000;
      }

        .react-datepicker__month-container {
          min-width: 100%;

          .react-datepicker__header {
            padding-top: 16px;
            background-color: #fff;
            border-bottom: 0px;

            & > .react-datepicker__day-names {
              background-color: #f4f6f8;
              margin: 16px;
              color: #6a6e73;
              font-family: "OpenSans";
              font-style: normal;
              font-weight: normal;
              border-radius: 3px;
              font-size: 12px;
              line-height: 16px;
            }

            .react-datepicker__current-month {
              color: #1d1e20;
              font-family: "Poppins-Medium";
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.16px;
            }
          }

          .react-datepicker__month {
            .react-datepicker__day {
              font-family: "OpenSans";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              color: #1d1e20;
              &.react-datepicker__day--disabled {
                cursor: default;
                color: #ccc;
              }

              &.react-datepicker__day--outside-month {
                color: #84888c;
              }

              &.react-datepicker__day--selected,
              &.react-datepicker__day--keyboard-selected {
                background-color: #2caf92;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

 
 
 
  
 
 
input[type="time"]::-webkit-calendar-picker-indicator {
  margin-left: -5px;
  padding: 0 -2px 0 0;
  &:focus {
    outline: none !important;
  }
}

 
 
 
.form-check {
  &.halfCheck {
    .form-check-input {
      appearance: none;
      -webkit-appearance: none;
      background-color: #fff;
      border: 1px solid #c7c8ca;
      padding: 8px;
      border-radius: 3px;
      display: inline-block;
      position: relative;
      top: 2px;
      outline: none;
      background-color: #2caf92 !important;
      border: 1px solid #2caf92 !important;

      &:after {
        content: "";
        position: absolute;
        top: 7px !important;
        left: 3px !important;
        display: block;
        width: 10px !important;
        height: 10px !important;
        border: solid #fff;
        border-width: 2px 0 0 0 !important;
        transform: rotate(0deg) !important;
      }
    }
  }

  &.fullCheck {
    .form-check-input {
      appearance: none;
      -webkit-appearance: none;
      background-color: #fff;
      border: 1px solid #c7c8ca;
      padding: 8px;
      border-radius: 3px;
      display: inline-block;
      position: relative;
      top: 2px;
      outline: none;
      background-color: #2caf92 !important;
      border: 1px solid #2caf92 !important;

      &:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 5px;
        display: block;
        width: 6px;
        height: 10px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }

  &.noCheck {
    .form-check-input {
      appearance: none;
      -webkit-appearance: none;
      background-color: #fff !important;
      border: 1px solid #c7c8ca !important;
      padding: 8px;
      border-radius: 3px;
      display: inline-block;
      position: relative;
      top: 2px;
      outline: none;
    }
  }
}
.p-none {
  pointer-events: none !important;
}
.c-default {
  cursor: default !important;
}
.alert-danger {
  color: #b12a18 !important;
  background-color: #ffefed !important;
  border-color: #b12a18 !important;
}
.wfres {
  .more-action {
    .more {
      background-color: #f1f2f4 !important;
      // border: 1px solid #6a6e73;
      color: #8c8e91 !important;
      box-shadow: 0 0 1px 1px #f1f2f4 !important;
      // box-shadow: 0 0 1px 1px #6a6e73 !important;
      pointer-events: none !important;
    }
  }
}
.bulkFalse {
  border: none !important;
}
.halfCheck {
  border: 1px dashed #0e9074 !important;
  div.halfCheck {
    border: none !important;
  }
}
.fullCheck {
  border: 1px solid #0e9074 !important;
  div.fullCheck {
    border: none !important;
  }
}
.noCheck {
  .more {
    border: none !important;
  }
}

 
 
.form-group {
  .form-control {
    &:read-only {
      background-color: #f3f3f4;
      border-color: #f3f3f4;
      opacity: 1;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}
 
 

 
 
 
 
//******** All Modal Starts ********//
.idle-bckdrp {
  z-index: 999998 !important;
}

.modal-body {
  .dropdown {
    .dropdown-menu {
      .dropdown-item {
        &:focus {
          background-color: #e9ecef !important;
        }
      }
    }
  }
  .import-from-dropdown {
    .dropdown-menu {
      ul {
        li {
          &:focus {
            background-color: #e9ecef !important;
          }
        }
      }
    }
  }
}

.change-role-modal {
  .modal-content {
    .modal-header {
      font-family: "Poppins-Medium";
      padding: 20px 32px;

      .close {
        color: #84888c;
        padding: 1.3rem 1rem;
      }
    }
    .modal-body {
      padding: 24px 32px;
      .form-group {
        .form-label {
          color: #1d1e20;
          font-family: "OpenSans-Semibold";
          font-size: 14px;
          line-height: 16px;
        }
        .dropdown {
          &.bg-blue {
            .btn-primary {
              width: 100%;
              border-color: #dee2e6 !important;
              background-color: #fff !important;
              color: #1d1e20 !important;
              &:hover {
                //background-color: #fff !important;
                color: #1d1e20 !important;
                border-color: #dee2e6 !important;
              }
              &:focus {
                border-color: #2caf92 !important;
              }
            }
          }
          .drop-arrow {
            svg {
              stroke: #1d1e20 !important;
            }
          }
          .dropdown-menu {
            width: 100%;
            .list-area {
              min-height: 180px !important;
              max-height: 180px !important;
            }
          }
        }
      }
    }
    .modal-footer {
      padding: 20px 32px;
    }
  }
}

.add-user-modal {
  .modal-content {
    .modal-header {
      font-family: "Poppins-Medium";
      padding: 20px 32px;

      .close {
        color: #84888c;
        padding: 1.3rem 1rem;
      }
    }
    .modal-body {
      padding: 24px 32px;
      .form-section {
        .body-section {
          margin-bottom: 24px;
          &.read-only {
            .form-control {
              pointer-events: none;
            }
          }
          .validation-error {
            label {
              color: #b12a18 !important;
            }
            .form-control {
              border-color: #b12a18;
            }
            .validation-message {
              font-family: "OpenSans";
              font-size: 12px;
              line-height: 16px;
              color: #b12a18;
              margin-top: 8px;
              display: block;
            }
          }
          &:nth-child(1),
          &:nth-child(3) {
            .form-group {
              width: 48%;
            }
          }
          &:nth-child(2) {
            .form-group {
              width: 100%;
            }
          }
          .form-group {
            margin-bottom: 0;
            label {
              font-family: "OpenSans-Semibold";
              font-size: 14px;
              line-height: 16px;
              color: #1d1e20;
              letter-spacing: 0.16px;
            }
            .form-control {
              font-family: "OpenSans";
              font-size: 14px;
              line-height: 18px;
              color: #1d1e20;
            }
          }
        }
      }
    }
    .modal-footer {
      padding: 20px 32px;
    }
  }
}

.idle-timeout-modal {
  z-index: 999999 !important;
  .modal-content {
    .modal-header {
      font-family: "Poppins-Medium";
      padding: 20px 32px;

      .close {
        color: #84888c;
        padding: 1.3rem 1rem;
      }
    }

    .modal-body {
      padding: 24px 32px;
      .description {
        font-family: "OpenSans";
        font-size: 14px;
        color: #1d1e20;
      }
    }

    .modal-footer {
      padding: 20px 32px;
      .btn {
        height: 32px;
        cursor: pointer;
      }
    }
  }
}

 

 
//****Mobile Navigation Starts****//
.nav-area-mobile {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  .head-nav {
    background: #0c192d;
    width: 100%;
    height: 56px;
    align-items: center;
    padding: 0 16px;
    border-bottom: 1px solid rgba(126, 148, 181, 0.12);
    .nav-icon {
      margin-right: 12px;
    }
 
    .module-name-selected {
      font-family: "poppins";
      font-size: 16px;
      line-height: 18px;
      color: #fff;
    }
    .notification-icon {
      margin-left: auto;
    }
    .user-image {
      cursor: pointer;
      position: relative;
      margin-left: 16px;
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      .user-image-wrapper {
        overflow: visible;
        position: relative;
        img {
          position: relative;
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
        .pips {
          z-index: 1;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          border: 1px solid #fff;
          position: absolute;
          bottom: 3px;
          right: 3px;
          &.red {
            background: #d3412e;
          }
          &.orange {
            background: #fca147;
          }
          &.blue {
            background: #15a4fa;
          }
        }
        svg {
          width: 32px;
          height: 32px;
          color: #f4f6f8;
        }
        .user-profile-list {
          position: absolute;
          right: 0;
          width: 180px;
          padding: 12px 0;
          background: #ffffff;
          border: 1px solid #e0e3e6;
          box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
          border-radius: 3px;
          z-index: 1000;
          transition: all 0.6s ease;
          a {
            font-family: "OpenSans";
            font-size: 14px;
            line-height: 18px;
            color: #1d1e20;
            .profile-list {
              padding: 12px 24px;
            }
          }
        }
      }
    }
  }
  .nav-open {
    background: #0c192d;
    height: calc(100vh - 57px);
    padding: 20px 0;
    overflow: auto;
    .nav-link {
      &.active {
        background-color: #16253e !important;
        .icon-width {
          img[src] {
            filter: invert(58%) sepia(100%) saturate(323%) hue-rotate(117deg) brightness(89%) contrast(103%);
            //opacity: 0.7;
          }
          svg {
            color: #17bda3;
          }
        }
        .lable {
          font-family: "OpenSans-Semibold";
          color: #1acaa3;
        }
      }
      .icon-width {
        margin-right: 12px;
        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
        svg {
          color: #7e94b5;
          width: 22px;
          height: 22px;
          padding-top: 2px;
        }
      }
      .lable {
        font-family: "OpenSans";
        font-size: 16px;
        line-height: 22px;
        color: #fff;
      }
      .arrow {
        &.rotate {
          svg {
            transform: rotate(90deg);
          }
        }
      }
      .mobile-sub-menu {
        margin-left: 36px;
        margin-top: 32px;
        .sub-menu-heading {
          font-family: "Poppins";
          font-size: 12px;
          line-height: 12px;
          color: #7e94b5;
          letter-spacing: 0.5px;
          margin-bottom: 12px;
        }
        a {
          text-decoration: none;
          padding: 5px 0;
          .nav-item-menu {
            font-family: "OpenSans";
            font-size: 16px;
            line-height: 22px;
            color: #fff;
            text-decoration: none;
            margin-bottom: 12px;
            // padding: 5px 0;
            &:hover {
              text-decoration: none;
            }
            &.active {
              color: #1acaa3;
            }
          }
        }
      }
    }
    .separator-space {
      height: 40px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .nav-area-mobile {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .nav-area {
    display: none;
  }
  .body-sec {
   // margin-top: 56px;
  }
}

@media only screen and (max-width: 414px) {
  // .nav-area {
  //   display: none;
  // }
  .navigation-menus {
    margin: 24px 0 24px 0;
    height: auto !important;
    align-items: flex-start !important;
    flex-direction: column !important;
    display: none !important;
    .reportGrpCont {
      flex-direction: column !important;
      .reportGrpSelector,
      .ehid-picker {
        margin-bottom: 10px;
      }
    }
    // .navigationSettings {
    //display: none;
    // }
  }
  .Module-list {
    display: block !important;
    .accordion {
      .card {
        .collapse {
          .card-body {
            .navigation-menus {
              display: block !important;
              .period-selector {
                width: 60%;
                display: inline-block;
              }
              .navigationSettings {
                width: 20%;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
  .chart-block {
    .chart-group {
      flex-direction: column;
    }
    .period-selector {
      display: none;
    }
  }
  .data-visualizer {
    .data-container {
      .period-selector {
        display: none;
      }
    }
    .data-details-container {
      // padding: 20px 32px;
      // border-bottom: 1px solid #e0e3e6;
      display: block !important;
      .navigation-menus {
        .navigationSettings {
          display: block !important;
        }
      }
    }
    canvas {
      padding: 0 !important;
    }
  }
}

 
 

 

 
/***************Timesheet/Schedule user view(for PDF)********************/
.pdf-show-relativePos {
  position: relative;
  background-color: #fff;
  &.fixed-heading {
    .container-fluid {
      padding: 0px;
      .userViewTable {
        padding: 0px 48px;
        margin-top: 96px;
        height: calc(100vh - 96px);
        overflow: auto;
        .section {
          padding: 24px 0px;
          margin: 0px;
          &.rm-top-padding {
            padding-top: 0px;
          }
        }
      }
      .heading-fixed-container {
        background-color: #fff;
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        z-index: 9;
        padding: 28px 48px 0px;
      }
    }
  }

  .download-pdf {
    .dropdown-menu {
      button {
        background: transparent;
        border: 0px;
        outline: 0px;
        font-family: "OpenSans";
        font-size: 14px;
        padding: 0px;
        color: #1d1e20;
        font-weight: normal;
        line-height: 18px;
      }
    }
  }
  .table-view {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -9;
    .table {
      &.pdf-view {
        width: 750px;
        margin-left: 22px;
        margin-top: 20px;
        font-size: 12px;
        thead {
          tr {
            background-color: rgb(0, 0, 0);
            th {
              width: 12%;
              color: rgb(255, 255, 255);
              font-weight: normal;
              border-right: 0px;
              border-bottom: 0px;
              text-align: center;
              padding: 5px 10px;
            }
          }
        }
        tbody {
          tr {
            td {
              color: #000;
              border-right: 0px;
              border-bottom: 0px;
              text-align: center;
              padding: 5px 10px;
            }
          }
        }
      }
    }
  }
  .react-bootstrap-table {
    .table {
      tbody {
        tr {
          td {
            vertical-align: top;
          }
        }
      }
    }
  }
}
 
 
.small-width {
  .pdf-show-relativePos {
    &.fixed-heading {
      .schedule-main {
        .container-fluid {
          .heading-fixed-container {
            padding: 28px 24px 0px;
          }
          .userViewTable {
            padding: 0px;
          }
        }
      }
    }
  }
}

// ag-grid css for stickey header
.ag-theme-balham {
  .infinite-scroll-component {
    .ag-root-wrapper {
      position: inherit;
      .ag-root {
        position: inherit;
        .ag-header {
          position: absolute;
          top: 0px;
        }
        .ag-body-horizontal-scroll {
          top: 48px;
          position: absolute;
          // height: 10px !important;
          // max-height: 10px !important;
          // min-height: 10px !important;
          // .ag-body-horizontal-scroll-viewport {
          // height: 10px !important;
          // max-height: 10px !important;
          // min-height: 10px !important;
          // .ag-body-horizontal-scroll-container {
          // height: 10px !important;
          // max-height: 10px !important;
          // min-height: 10px !important;
          // }
          // }
        }
        .ag-body-viewport {
          margin-top: 53px;
          min-height: calc(100vh - 310px);
          overflow-y: auto;
          overflow-x: hidden;
          //max-height: calc(100vh - 310px);
        }
      }
    }
  }
}
.cursorPointer{
  cursor: pointer;
}
// This is for Calendar month view height fix - NW-5559(HotFix)
.react-calendar {
  .react-calendar__viewContainer {
    .react-calendar__month-view {
      .react-calendar__month-view__days {
        height: 220px;
      }
    }
  }
}
.smileIDCalender{
  .all-dates{
    top: 340px;
  }
}
.deactivePositionDropdown{
  .dropdown-menu{
    z-index: 2;
  }
}
.reviewCol{
  width: 300px !important;
}
 
 

.TabFooter-Pay {
  position: absolute;
  bottom: 0px;
  min-width: 100%;
  right: 0;
  z-index: 9;


  .bulkSelectFooter {
      height: 64px;
      background: #ebf0f4;
      padding: 0 20px;
     
      display: flex;
      align-items: center;

      .form-check {
          @include customCheckBoxFullHalfCheckedProp;
      }

      .shift-selected-count {
          font-family: "OpenSans-Semibold";
          font-size: 12px;
          color: #000;
          line-height: 18px;
      }

      .FooterHorLine {
          border: 0.01em solid gray;
          margin-right: 10px;
          height: 16px;
          margin-left: 10px;
          background-color: gray;
      }

      .action-group {
          .btn {
              color: #fff;
             
          }

          .assign-shift {
              .dropdown-menu {
                  box-shadow: none;
                  border: none;

                  &.show {
                      top: -364px !important;
                      left: -98px !important;
                      opacity: 1 !important;
                      pointer-events: all !important;
                  }

                  .confirmModal {
                      width: auto !important;
                      position: absolute;
                      bottom: -362px;

                      .action-link {
                          a {
                              &.wht-bg {
                                  color: #3f4347 !important;
                              }
                          }
                      }
                  }
              }
          }
      }

      .closeButton {
          font-size: 24px;
          color: #fff;

          &:hover {
              opacity: 0.5;
          }
      }
  }
}

/************react-bootstrap-table use in order page**************/

.react-bootstrap-table {
  font-size: 14px;
  line-height: 18px;
  color: #1d1e20;
  

  table {
   // border: 0px;
    line-height: 18px;
    //border: 1px solid #e0e3e6;
  }

  thead {
    tr {
      th {
        height: 24px;
        vertical-align: middle;
        background: #d8e3f4;
       // background-color: "#d8e3f4"!important;
        font-family: "OpenSans-Semibold"; 
        border: 0px;
        white-space: nowrap;
       // padding: 0px 16px;
        color: black;
        font-style: normal;
       font-size: 14px;
        line-height: 18px;
        text-transform: none;
       
      }
    }
  }

  tbody,
  tfoot {
    tr {
      border-bottom: 1px solid #e0e3e6;

      td,
      th {
        height: 36px;
        vertical-align: middle;
        border: 0px;
        text-align: left;
        white-space: nowrap;
        padding: 0px 5px!important;
        color: #1d1e20;
        font-size: 14px;
        line-height: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
       

        .form-control {
          min-height: calc(1.5em + .5rem + calc(var(--bs-border-width)* 2));
          padding: .25rem .5rem;
          font-size: .875rem;
          border-radius: var(--bs-border-radius-sm);
    
      
      
        }

        .table-active {
          --bs-table-striped-bg: var(--bs-table-active-bg);
          --bs-table-striped-color: var(--bs-table-active-color);
        }
      }

      &:hover {
       // background-color: #e8eaed;
      }

      .table-active {
        --bs-table-striped-bg: var(--bs-table-active-bg);
        --bs-table-striped-color: var(--bs-table-active-color);
      }
&.parent-expand-foo{

  background-color: red!important;
}
      // &.parent-expand-foo {
      //   border-bottom: 0px;
      //   background-color: violet;

      //   & + tr {
      //     .reset-expansion-style {
      //      // padding: 0px 20px;
      //      padding: 14px 0px 14px 27px;
      //       .row-expand-slide-enter-done {
      //        // border: 1px solid #e0e3e6;
      //        // border-radius: 3px;
      //       // background-color: red;
      //       }
      //     }

      //     &:hover {
      //       background-color: transparent;
      //     }
      //   }
      //   &  th {
      //     background-color: red;

      //   }
      // }
    }
  }

  tfoot {
    tr {
      border-top: 1px solid #e0e3e6;

      th {
        color: #2caf92;
      }
    }
  }
 
 
}
 

  .react-bootstrap-table-pagination {
     
    margin-top: auto !important;
    align-items: center !important;
    display: flex !important;
    position: fixed;
    bottom: 0;
   width: 100%;
    margin-right: 0px;
     font-family: "OpenSans";
     font-style: normal;
   background-color: #FFFFFF;
     font-size: 12px;
     padding-top: 0.5%;
     padding-bottom: 2%;
   
  *, *:before, *:after {
    box-sizing: border-box;
  }

  .text-muted {
    --bs-text-opacity: 1;
    color: var(--bs-secondary-color) !important;
    margin-left: 2%;
}
  .btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #1d3be2;
    --bs-btn-border-color: #1d3be2;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #1932c0;
    --bs-btn-hover-border-color: #172fb5;
    --bs-btn-focus-shadow-rgb: 63, 88, 230;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #172fb5;
    --bs-btn-active-border-color: #162caa;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #1d3be2;
    --bs-btn-disabled-border-color: #1d3be2;

  }
  
  
  .btn {
    --bs-btn-padding-x: .75rem;
    --bs-btn-padding-y: .375rem;
    --bs-btn-font-family: ;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-bg: #ffffff;
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: var(--bs-border-radius);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075);
    --bs-btn-disabled-opacity: .65;
    --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   
  }

  .dropdown-menu {
    --bs-dropdown-zindex: 1000;
    --bs-dropdown-min-width: 10rem;
    --bs-dropdown-padding-x: 0;
    --bs-dropdown-padding-y: 0.5rem;
    --bs-dropdown-spacer: 0.125rem;
    --bs-dropdown-font-size: 1rem;
    --bs-dropdown-color: var(--bs-body-color);
    --bs-dropdown-bg: var(--bs-body-bg);
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-border-radius: var(--bs-border-radius);
    --bs-dropdown-border-width: var(--bs-border-width);
    --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    --bs-dropdown-divider-margin-y: 0.5rem;
    --bs-dropdown-box-shadow: var(--bs-box-shadow);
    --bs-dropdown-link-color: var(--bs-body-color);
    --bs-dropdown-link-hover-color: var(--bs-body-color);
    --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
    --bs-dropdown-link-active-color: #fff;
    --bs-dropdown-link-active-bg: #1d3be2;
    --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
    --bs-dropdown-item-padding-x: 1rem;
    --bs-dropdown-item-padding-y: 0.25rem;
    --bs-dropdown-header-color: #6c757d;
    --bs-dropdown-header-padding-x: 1rem;
    --bs-dropdown-header-padding-y: 0.5rem;
    /* position: absolute; */
    z-index: var(--bs-dropdown-zindex);
    display: none;
    min-width: var(--bs-dropdown-min-width);
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    margin: 0;
    margin-top: auto!important;
    bottom: 0!important;;
    font-size: var(--bs-dropdown-font-size);
    color: var(--bs-dropdown-color);
    text-align: left;
    list-style: none;
    background-color: var(--bs-dropdown-bg);
    background-clip: padding-box;
    border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
    border-radius: var(--bs-dropdown-border-radius);
}
    .react-bootstrap-table-pagination-total {
      span {
       
        font-weight: 600;
      }
    }
   
    .react-bootstrap-table-pagination-list {
   //  width: 10%;
  //  width: auto;
  //    @media (min-width: 992px) {
  //     .col-lg-6 {
  //         flex: 0 0 auto;
  //         width: 100%;
  //     }
  // }
  // @media (min-width: 768px) {
  //     .col-md-6 {
  //         flex: 0 0 auto;
  //         width: 85%;
  //     }
  // }


//   @media (min-width: 576px) {
//     .col-sm-6 {
//         flex: 0 0 auto;
//         width: 50%;
//     }
// }

.react-bootstrap-table-page-btns-ul{
//background-color: red;
margin-right: 0px;
border: 0 !important;
gap: .25rem !important;
padding-right: .10rem !important;
//padding-left: 28% !important;
width:100%;
flex: 3 0 auto;
}
      
      .pagination {
        margin-left: auto !important;
        margin-bottom: 0px;
       // justify-content: flex-end;
        margin-right: 0px;
          
  
      //   .page-item{
   
      //    // width: 100%;
          
      //     //  padding: 10px; 
      //     //     width: 100px; 
      //     //     height: 85%; 
      //     //     font-size: larger; 
      //     //     text-align: center; 
      //     //     background-color: rgb(173, 6, 165); 
      //     //     border: 1px solid #ccc; 
      //     //     margin: 5px; 
      //     &:first-child {
      //       position: relative;
      //       // display: flex;
      //       // flex-wrap: wrap;
      //       align-items: stretch;
           
        
      //     }
  
      //     &:last-child {
      //       //gap: .25rem !important;
          
      //      // margin-left: auto !important;
      //      // width:30%;
      //      // align-items: stretch;
      //      right: 0px;
      //      float: right;
      //     }
      //     // .page-link{
      //     //     font-weight: 600;
      //     //     font-size: 14px;
      //     //     line-height: 18px;
      //     //     color: #84888C;
      //     //     border-color: #fff;
      //     //     background-color: #fff;
      //     //     border-radius: 4px;
      //     //     padding: 5px 10px;
      //     // }
        
      //     // }
      // }  
      // &.active{
      //   .page-link{
      //     text-align: right;
      //         z-index: auto!important;
      //       color: var(--#{$prefix}pagination-active-color);
      //       @include gradient-bg(var(--#{$prefix}pagination-active-bg));
      //       border-color: var(--#{$prefix}pagination-active-border-color);
      //   }
      // }
       
      }
    }
    
  }
  




// .react-bootstrap-table-pagination{
//   position: fixed;
//   grid-column: 2;
//   //right: auto;
//   right: 0;
//   width: 100%;
//   min-width: 82%;
//   z-index: 1;
//   bottom: 0;
//   font-family: "OpenSans";
//   font-style: normal;
//   .total-list{
//       .react-bs-table-sizePerPage-dropdown{
//           .dropdown-toggle{
//               color: #3F4347;
//               box-shadow: none;
//               font-family: "OpenSans";
//               font-style: normal;
//               background: #FFFFFF;
//               border: 1px solid #E0E3E6;
//               border-radius: 3px;
//               font-weight: 600;
//               font-size: 14px;
//               line-height: 18px;
//               span{display: none;}
//               &:hover{
//                   color: #3F4347;
//                   border-color: #2CAF92;
//               }
//           }
//           .dropdown-menu{
//               min-width: 100%;
//               box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
//               border: 1px solid #e0e3e6;
//               border-radius: 3px;
//               background: #ffffff;
//               padding: 0px;
//               .dropdown-item{
//                   padding: 0px;
//                   a{
//                       padding: 8px 17px 7px;
//                       display: block;
//                       font-family: "OpenSans";
//                       font-size: 14px;
//                       color: #1d1e20;
//                       font-weight: normal;
//                       line-height: 18px;  
//                       text-decoration: none;                                      
//                   }
//                   &:hover{
//                       background-color: #f4f6f8;
//                       a{
//                           color: #1d1e20;
//                       }                                       
//                   }                                    
//               }
//           } 
//           &.show{
//               .dropdown-toggle{
//                   border-color: #2CAF92;
//               }
//           }
//       }
//       .count-show{
//           font-weight: 600;
//           font-size: 14px;
//           line-height: 18px;
//           color: #3F4347;
//           margin-left: 8px;
//       }
//   }
//   .pagination-list{
//       margin-left: 15px;
//       .pagination{
//           margin-bottom: 0px;
//           .page-item{
//               .page-link{
//                   font-weight: 600;
//                   font-size: 14px;
//                   line-height: 18px;
//                   color: #84888C;
//                   border-color: #fff;
//                   background-color: #fff;
//                   border-radius: 4px;
//                   padding: 5px 10px;
//               }
//               &.active{
//                   .page-link{
//                       color: #84888C;
//                       border-color: #fff;
//                       color: #2CAF92;
//                       background-color: #fff;
//                       border-color: #2CAF92;
//                   }
//               }
//           }
//       }
//   }
//   .search-total{                        
//       font-weight: 600;
//       font-size: 14px;
//       line-height: 18px;
//       color: #3F4347;
//   }
// }
.drop-select .btn {
  background: #ffffff;
  border: none;
  border-radius: 3px;
  color: #065a8c;
  font-family: Poppins-Medium;
  font-size: 16px;
  font-style: normal;
  height: 32px;
  margin-right: 8px;
  padding: 0 8px;
  width: 80px;
  .dropdown, .dropleft, .dropright, .dropup {
    position: relative;
}
}

.single-select.dropdown .btn.btn-primary {
  .single-select.dropdown .btn.btn-primary {
    box-sizing: border-box;
    color: #1d1e20;
    font-family: "OpenSans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    height: 25px;
    /* margin-top: 2%; */
    line-height: 14px;
    /* align-items: center; */
    text-align: center;
  }
}

.form-select1 {
  --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
  display: block;
  width: 100%;

 // padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.transactions-list{
   
  // padding:32px 32px 24px 32px;
   .toggle-button{
     &.entry{
       margin-bottom: 10px;
       .btn-group{
         .btn{
           background-color:#fff;
           color: #1d1e20;
           opacity: 0.5;
           border-color:#2CAF92;
           padding:5px 10px;
           height: auto;
           &:hover, &.active{
             opacity: 1;
             border-color:#2CAF92;
             background-color:#2CAF92;
             color: #fff;
           }
           &:focus{
             box-shadow: none;
           }
           &.btn-primary{
             &.focus{
               box-shadow: none;
             }
           }
           &.disabled{
             &:hover, &.active{
               background-color: #fff;
               color: #1d1e20;
               opacity: 0.5;
               border-color: #2CAF92;
               cursor: not-allowed;
             }
           }
         }
       }
     }
   }
   .action-btn{
     button {
       &:focus, &:active {
         background-color: #fff;
       }
     }
     .link-btn{
       margin-left: 16px;
       margin-right:16px;
     }
     .total-amount{
       color: #1D1E20;
       font-family: "OpenSans-Semibold";
       font-size: 14px;
       line-height: 18px;
       margin-right: 92px;
     }
   }        
   .react-bootstrap-table{
     table{
       margin-bottom: 4px;
       thead{
         th{
          //  overflow: visible;
          //  background: #848992;
          //  min-width: 14%;
          //  width:auto;
          //  padding: 0 8px;
          //  color:white;
          font-weight: normal;
          background-color: "#d8e3f4"!important;
          //var(--bs-light) !important;
          color: black!important;
          // var(--bs-secondary) !important;
          font-weight: 500;
           &.ehid{
             width:120px;
           }
           &.expand-cell-header{
             width:50px;
           }
           &.amount {
             text-align: right;
           }
           .default-view{
             border-top: 1px solid #e0e3e6;
             width:100%;
             height: auto;
             padding: 20px 20px;
           }
           &.description{
             min-width:20%;
             width: auto;
           }                
           &:last-child{
             min-width: 75px;
             width:75px;
             text-align: right;
           }
           .rolePermissiondropdown{
             .dropdown-toggle{
               text-align: center;
               padding: 5px;
               height:30px;
               .icon{
                 svg{
                   margin-right: auto;
                   fill: #1d1e20 !important;
                 }
               }
               .arrow-icon{
                 svg{
                   margin-right:0px;
                   stroke: #3f4347;
                   fill: transparent !important;
                 }                      
               }                    
               &:hover{
                 .icon{
                   svg{
                     fill: #fff !important;
                   }
                 }
                 .arrow-icon{
                   svg{
                     stroke: #fff;
                   }
                 }                     
               }
             }
             .header{
               padding: 17px 16px;
               border-bottom: 1px solid #e0e3e6;
               font-family: "OpenSans-Semibold";
               color: #1d1e20;
               
               font-size: 14px;
               line-height: 18px;
               letter-spacing: 0.16px;
               margin-bottom: 10px;
             }
             .dropdown-menu{
               .dropdownItems{
                 padding-bottom: 8px;
                 .dropdown-item{
                   padding: 7px 17px;
                   .form-check-label{
                     top: -2px !important;
                   }
                 }
               }                    
             }
             &.show{
               .dropdown-toggle{
                 .icon{
                   svg{
                     fill: #fff !important;
                   }
                 }
                 .arrow-icon{
                   svg{
                     stroke: #fff;
                   }
                 }                    
               }
             }
           }
           .action-grp{
             .more{
               height: auto;
               margin-left: 5px;
               font-size: 12px;
               padding: 5px 5px;
               line-height: 12px;
               color: #2caf92;
               border-color: #2caf92;
               background: #ffffff;
               &:hover, &:focus{
                 background: #364760;
                 border-color: #364760;
                 color: #fff;
               }
             }
           }
           &.selection-cell-header{
             width:50px;
           }
         }
         tr{
           &.assignCoaHeader{
             background: #E8EAED;
             th{
               opacity: 0;
               &:first-child{
                 opacity: 1;
               }
             }
           }
         }
       }
       tbody{
         td{
           overflow: visible;
           padding: 0 8px;
           .static-form-control{
             font-family: "OpenSans";
             font-style: normal;
             font-weight: 400;
             font-size: 14px;
             line-height: 18px;
             padding: 10px 8px;
             height: 36px;
             color: #1D1E20;
             background-color: #fff;
             background-clip: padding-box;
             border: 1px solid #ced4da;
             border-radius: 0.25rem;
             transition: border-color 0.15s ease-in-out,
             box-shadow 0.15s ease-in-out;
           }
           .form-control{
             width:100%;
             font-family: "OpenSans";
             font-style: normal;
             font-weight: 400;
             text-align: left;
             font-size: 14px;
             line-height: 18px;
             color: #1d1e20;
           }
           .more-action{
             &.confirmation-modal{
               .dropdown-menu{
                 border:0px;
                 box-shadow: none;
               }
             }
           }
           &:last-child{
             text-align: right;
           }
           .single-select-image{
             .filter-btn{
               width: 100%;
               border: 1px solid #ced4da;
               background-color: #fff;
               border-radius: 0.25rem;
               transition: border-color 0.15s ease-in-out, 
               box-shadow 0.15s ease-in-out;
               padding: 0px 8px;
               height: 32px;
               .txt-area{
               padding-top: 1%;
                 max-width: 75%;
                 width: 100px;
               }
               &:focus{
                 background-color: #364760 !important;
                 color: #fff !important;
                 border-color: #364760 !important;
                 .drop-arrow{
                   svg{
                     stroke:#fff;
                   }
                 }
               }
               .drop-arrow{
                 right: 8px;
                 svg{
                   margin-right: 0px;
                 }
               }
             }
             .dropdown-menu{
               .list-area{
                 min-height: auto;
                 max-height: 250px;
               }                    
             }                  
             &.coa-select{
               .rbt-input-hint{
                 opacity: 0;
               }                    
               .dropdown-menu{
                 width:300px !important;                      
                 .dropdown-item{
                   border-bottom: 0px;
                   &.load-more{
                     text-align: center;
                     background-color: #2caf92;
                     color:#fff;
                     padding-top: 8px;
                     padding-bottom: 8px;
                     &:hover{
                       background-color: #0E9074;
                     }
                   }
                 }
                 margin-bottom: 20px;
               }
             }
           }
           &.amount{
             .form-control{
               text-align: right;
               &::placeholder{
                 text-align: left;
               }
             }                  
           }
           &.coa-col{
             .availble-width{
               width: calc(100% - 26px);
             }
             .custom-checkbox{
               padding-left: 16px;
               .custom-control-label{
                 &::before{
                   left: -16px;
                 }
                 &::after{
                   left: -16px;
                 }
               }
             }
           }
           .custom-autocomplete{
             position: relative;
             .form-control{
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
             }
             .suggestions{
               box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
               border: 1px solid #e0e3e6;
               border-radius: 3px;
               background: #ffffff;
               padding: 0px;
               margin: 0px;
               position: absolute;
               width: 250px;
               max-height: 250px;
               overflow: auto;
               z-index: 2;
               top: 36px;
               left: 0px;
               li{
                   font-family: "OpenSans";
                   font-size: 14px;
                   padding: 13px 17px 12px;
                   color: #1d1e20;
                   font-weight: normal;
                   line-height: 18px;
                   border-bottom: 1px solid #e0e3e6;
                   &.active, &:hover, &:focus{
                     background-color: #f4f6f8;
                     color: #1d1e20;
                     outline: 0px;
                   }
               }
             }
           }                
         }
         tr{
          .table-active {
            --bs-table-striped-bg: var(--bs-table-active-bg);
            --bs-table-striped-color: var(--bs-table-active-color);
          }
         }
       }
     }
   }
   &.associated-invoices{
     .sec-ttl{
       font-family: "OpenSans-Semibold";
       font-style: normal;
       font-weight: 600;
       font-size: 16px;
       line-height: 22px;
       color: #000000;
       margin-bottom: 16px;
     }
     .paid-status{
       background: #DEFFF8;
       border-radius: 3px;
       color: #0E9074;
       padding:4px 6px;
       width: 75px;
       text-align: center;
     }
     .react-bootstrap-table{
       text-align: left;
       .table{
         margin-bottom: 0px;
       }
       thead{
         th{
           &:last-child{
             min-width: auto;
             width: auto;
             text-align: left;
           }
           &.account{
             max-width: 200px;
           }
         }              
       }
       tbody{
         tr{
           td{
             &.expand-cell{
               .chevronExpended{
                 img{
                   transform: rotate(90deg);
                   transition: transform 500ms;
                 }
               }
             }
             &:last-child{
               text-align: left;
             }                  
           }
           &.parent-expand-foo{

            background-color: violet!important;
          }
          //  &.parent-expand-foo{
          //   // border-bottom: 1px solid #e0e3e6;
          //  }
           .reset-expansion-style{
            padding: 14px 0px 14px 27px;
               .row-expand-slide-enter-done{
                 border: 0px;
                 border-radius: 0px;
                 .row-expansion-style{         
                   //padding: 0px;              
                   .react-bootstrap-table{                          
                     thead, tbody{
                       tr{
                         th{
                           height:48px;
                         }
                         th, td{
                           text-align: left;
                           // &.coa-width{
                           //   //width:300px;
                           // }
                         }
                         &:last-child{
                           border-bottom:10px;
                         }
                       }
                     }
                   }
                 }
               }                  
           } 
           .table-active {
            --bs-table-striped-bg: var(--bs-table-active-bg);
            --bs-table-striped-color: var(--bs-table-active-color);
          }               
         }
       }
     }
   }
   &.inpute{
     .react-bootstrap-table{
       table{
         tbody{
           td{
             .form-control, .custom-btn{
               font-family: "OpenSans";
               color: #1D1E20;
               font-size: 14px;
               line-height: 18px;
               letter-spacing: 0px;
               font-weight: 400;
               width: 100%;
               border: 1px solid #ced4da;
               background-color: #fff;
               border-radius: 0.25rem;
               transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
               padding: 0px 8px;
               height: 36px;
               display: inline-block;                    
               //text-align: left;
               vertical-align: middle;
             }                  
               .more-action{
                 &.single-select-image{
                   .btn{
                     font-family: "OpenSans";
                     color: #1D1E20;
                     font-size: 14px;
                     line-height: 18px;
                     letter-spacing: 0px;
                     font-weight: 400;
                     width: 100%;
                     border: 1px solid #ced4da;
                     background-color: #fff;
                     border-radius: 0.25rem;
                     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                     padding: 0px 8px;
                     height: 36px;
                     display: inline-block;                    
                     //text-align: left;
                     vertical-align: middle;
                   }
                 }
               }
             .custom-btn{
               text-align: left;
               &.placeholder{
                 color: #84888c;
               }
             }
           }
         }
       }
     }
   }
   .split-assign-coa{
     position: relative;
     .assignCoa{
       position: absolute;
       top:13px;
       left: 53px;
       &.action-grp{
         .more{
           height: auto;
           margin-left: 5px;
           font-size: 12px;
           padding: 5px 5px;
           line-height: 12px;
           color: #2caf92;
           border-color: #2caf92;
           background: #ffffff;
           &:hover, &:focus{
             background: #364760;
             border-color: #364760;
             color: #fff;
           }
         }
       }
     }
     .react-bootstrap-table{
       .table{
         th{
           &.selection-cell-header{
             text-align: center;
           }
         }
         td{
           &.selection-cell{
             text-align: center;
           }
         }
        
       }
     }
   }
 }
 
 @keyframes loadingAnimation {
   0% {
     background-position: 100% 50%;
   }
   100% {
     background-position: 0% 50%;
   }
 }
 .loader-responsive{
   .performanceList{
     .filter-sec{
         padding: 20px;
       .header{
         .loadingAnimation{
           //background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
           background: linear-gradient(100deg, #e6e7e8 30%, #f6f7f8 50%, #e6e7e8 70%);
           background-size: 400%;
           animation: loadingAnimation 1.2s ease-in-out infinite;
         }
       }
       &.upload-section{
         .tableList{
           width: 100%;
           ul{
             li{              
               .list{
                 width: 50%;
                 padding-left:0px;
                 padding-right: 5%;
                 .loadingAnimation{
                   height: 75px;
                   width:100%;
                 }                  
                   &:last-child{
                     padding-right: 0%;
                     padding-left: 5%;
                   }
               }
               &:last-child{
                 border-bottom:0px;
               }
             }
           }
         }
       }
     }
     .transactions-list{
       .tableHeader{
        font-weight: normal;
        background-color: var(--bs-light) !important;
        color: var(--bs-secondary) !important;
        font-weight: 500;
       }
       .tableList{
         ul{
           li{
             height: 24px;
             .list{
               .loadingAnimation{
                 width:90%;
               }
                 width: 15%;
                 &:last-child{
                   width:10%;
                   .loadingAnimation{
                     width:90%;
                     margin-left: 10%;
                   }
                 }
             }
             &:last-child{
               border-bottom:0px;
             }
           }
         }
       }
     }
   }
 }
 .back-drop{ 
   width: 65%;
   right:auto;
         right:0px;
   &.viewer-panel{
     right:auto;
     right:0px;
     transition: opacity 0.3s ease-out;
     background:#ffffff;
     width:65%;
      
     &.opacity{
       opacity: 0.4;
     }
    //  .footer-section{
    //      margin-top: 50px;
    //      position: fixed;
    //      background: #888888;
    //      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
    //      width:65%;
    //      z-index: 1;
    //      right:auto;
    //      right:0px;
    //      bottom: 0px;
    //      height:72px;
    //      padding: 0px 32px;
    //      border-radius: 0px 0px 3px 0px;
    //      //border-top: 1px solid #e0e3e6;
    //      .custom-checkbox{
    //        margin-right: 15px;          
    //        .custom-control-label{
    //          cursor: pointer;
    //          font-family: "OpenSans-Bold";
    //          font-weight: normal;
    //          font-size: 14px;
    //          line-height: 18px;
    //          letter-spacing: 0.2px;
    //          transition: all 0.5s ease;
    //          color: #FFFFFF;
    //          &::before{
    //            background-color: transparent;
    //          }
    //        }
    //        .custom-control-input:indeterminate ~ .custom-control-label::before {
    //          border-color: #2daf92;
    //          background-color: #2daf92;
    //        }
    //        .custom-control-input:checked ~ .custom-control-label::before {
    //          border-color: #2daf92;
    //          background-color: #2daf92;
    //        }
    //      }
    //      .btn{
    //        margin-top: 18px;
    //        margin-left: 8px;
    //        margin-right:8px;
    //        color: #FFFFFF;
    //        font-weight: bold;
    //        &.cross{
    //          width: 40px;
    //          height: 40px;
    //          padding: 0px;
    //          margin-left: 18px;
    //          line-height: 0px;
    //        }
    //      }
    //      .more-action{
    //        &.dropdown{
    //          .more{
    //            background-color: transparent;
    //            svg{
    //              fill:#fff;
    //            }
    //            &:hover, &:active, &:focus, &:not(:disabled):not(.disabled):active{
    //              background-color: #2caf92;
    //              color: #fff;
    //              border-color: #2caf92;
    //            }           
    //          }
    //        }          
    //      }
    //  }    
     //border-right: 1px solid #e0e3e6;
     .header-panel{
       height: 88px;
       padding:0px 32px;
       background: #333F4F;
       box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
       .page-ttl{
         color: #FFFFFF;
         font-family: "OpenSans";
         font-style: normal;
         font-weight: 600;
         font-size: 20px;
         line-height: 24px;
       }
       .toggle-button{
         .btn-group{
           .btn{
             background-color:transparent;
             border-color: rgba(255, 255, 255, 0.2);
             padding:10px;
             svg{
               opacity: 0.5;
               fill: #fff;
             }
             &:hover, &.active{
               border-color:#2CAF92;
               opacity: 1;
               svg{
                 opacity: 1;
               }
             }
           }
         }
       }
       .action{
         .btn{
           height: 40px;
         }
       }
     }
     .file-name{
       padding: 0px 20px;
       height:50px;
       color: #1D1E20;
       font-family: "OpenSans";
       font-style: normal;
       font-weight: 600;
       font-size: 20px;
       line-height: 24px;
     }
     .scroll-area{
       position: relative;
       height: calc(100vh - 88px);
       padding: 0px 32px 15px; 
       overflow-y: auto;
       overflow-x: hidden;
       &.doc-sec{
         overflow-x: auto;
         padding: 0px 0px 15px;
           .custom-page-action{
             &.doc-header{
               margin: 10px 0px;
               padding: 0px 32px;
               background: #333F4F;
               box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
               height:45px;            
             }
             .doc-panel{
               padding: 0px 32px;
             }
           }       
       }
       &.modal-open{
         overflow-y: hidden;
       }
       .footer-enable{
         margin-bottom: 90px;
       }
       .react-pdf__Document{
         .custom-checkbox{
           .custom-control-label{
             cursor: pointer;
             &::before{
               background-color: transparent;
             }
           }
           .custom-control-input:indeterminate ~ .custom-control-label::before {
             border-color: #2daf92;
             background-color: #2daf92;
           }
           .custom-control-input:checked ~ .custom-control-label::before {
             border-color: #2daf92;
             background-color: #2daf92;
           }
         }      
         .react-pdf__Page{       
           &:last-child{
             border-bottom:0px;
           }
           canvas{
             //transition: all 0.6s ease;
             margin: 0 auto;
             // width: 100% !important;
             // height: auto !important;
             //min-width: 100%;
           }
         }
         .custom-page-action{
           height:32px;
           margin:10px 0px;
           .page-ttl, .page-count{
             color: #CACED2;
             letter-spacing: 0.16px;
             font-family: "OpenSans";
             font-style: normal;
             font-weight: 600;
             font-size: 14px;
             line-height: 18px;
             white-space: nowrap;
             overflow: hidden;
             text-overflow: ellipsis;
             max-width: 33%;
           }
           .page-ttl{
             .custom-control-label{
               width: 100%;
             }
           }
           .page-count{
             font-size: 12px;
             line-height: 18px;
             font-weight: normal;
           }
           .action{
             .btn{
               height:32px;              
             }
             .more-action{
               margin-left: 15px;
               .btn-outline-primary{
                 background:transparent;
                 border-color: transparent;
                 color: #2CAF92;
                 font-family: "OpenSans";
                 font-style: normal;
                 font-weight: bold;
                 font-size: 14px;
                 line-height: 18px;
                 // &:hover, &:active, &:not(:disabled):not(.disabled):active {
                 //   background: #364760;
                 //   border-color: #364760;
                 // }
               }
               &.show, &:hover, &:active, &:not(:disabled):not(.disabled):active{
                 .btn-outline-primary{               
                   background: #364760;
                   border-color: #364760;
                   color:#fff;
                 }                
               }
               &.dropdown{
                 .dropdown-menu{
                   .dropdown-header{
                     font-family: "OpenSans-Semibold";
                     color: #6A6E73;
                     font-size: 10px;
                     line-height: 16px;
                     letter-spacing: 1px;
                     padding: 13px 17px 12px;
                     text-transform: uppercase;
                   }
                   .dropdown-item{
                     border-bottom:0px;
                   }
                 } 
               } 
             }
           }
         }
         .grid-view{
           display: inline-block;       
           margin-right: 32px;
           margin-bottom: 7px;        
           .custom-page-action{
             .action{
               position: relative;
             }
             .page-count{
               max-width: 50%;
             }
           }
           .react-pdf__Page{
             width:181px;
             height:213px;
             overflow: hidden;
             canvas{
               width: 100% !important;
               height:auto !important;
             }
           }        
         }
       }
       .grid-sec{
         border-bottom: 1px solid rgba(224, 227, 230, 0.2);
       }
       .custom-error-msg{
         padding: 2px 0px;
         font-family: "OpenSans";
         font-size: 14px;
         line-height: 18px;
         color: #fff;
         margin-right: 15px;
       }
       .email-attachement{
         margin: 10px 0px;
         .link-btn{
           font-size: 16px;
           line-height: 18px;
           height: auto;
         }
         .info{
           margin-top:2px;
           color:#cfa72a;
           font-size: 12px;
           line-height: 18px;
           font-weight: normal;
           letter-spacing: 0.16px;
           font-family: "OpenSans-Bold";
           //font-family: "OpenSans";
           font-style: normal;
         }
         &.doc-view{
           margin: 20px 32px;
           padding: 10px 0px 0px;
           border-top: 1px solid rgba(224, 227, 230, 0.2);
         }
       }
       .zoom-btn{
         position: fixed;
         bottom: 70px;
         left: 40%;
         border-radius: 3px;
         box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
         .btn{
           //padding: 0px 10px;
           padding: 0px 6px;
           height: 30px;
         }
       }
     }       
   }
   &.confirm-modal{
     position: fixed;
     width:100%;
     left: 0px;
     right:0px;
     top:0px;
     bottom: 0px;
     .upload-link{
       position: relative;
       &.mrgn-top{
         margin-top: 0.5rem;
       }
       .btn{
         height: auto;
       }
       .form-control-file{
           top:0px;
           opacity: 0;
           cursor: pointer;
           font-size: 0;
           position: absolute;            
       }          
     }
   }
 }

 .back-drop-left{ 
  width: 35%;
  left:auto;
  left:0px;
  &.viewer-panel-left{
    
    left:0;
    transition: opacity 0.3s ease-out;
    background:#ffffff;
    width:35%;
     
    &.opacity{
      opacity: 0.4;
    }
    .header-panel{
      height: 88px;
      padding:0px 32px;
      background: #333F4F;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
      .page-ttl{
        color: #FFFFFF;
        font-family: "OpenSans";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
      .toggle-button{
        .btn-group{
          .btn{
            background-color:transparent;
            border-color: rgba(255, 255, 255, 0.2);
            padding:10px;
            svg{
              opacity: 0.5;
              fill: #fff;
            }
            &:hover, &.active{
              border-color:#2CAF92;
              opacity: 1;
              svg{
                opacity: 1;
              }
            }
          }
        }
      }
      .action{
        .btn{
          height: 40px;
        }
      }
    }
    .file-name{
      padding: 0px 20px;
      height:50px;
      color: #1D1E20;
      font-family: "OpenSans";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }
    .scroll-area{
      position: relative;
      height: calc(100vh - 88px);
      padding: 0px 32px 15px; 
      overflow-y: auto;
      overflow-x: hidden;
      &.doc-sec{
        overflow-x: auto;
        padding: 0px 0px 15px;
          .custom-page-action{
            &.doc-header{
              margin: 10px 0px;
              padding: 0px 32px;
              background: #333F4F;
              box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
              height:45px;            
            }
            .doc-panel{
              padding: 0px 32px;
            }
          }       
      }
      &.modal-open{
        overflow-y: hidden;
      }
      .footer-enable{
        margin-bottom: 90px;
      }
      .react-pdf__Document{
        .custom-checkbox{
          .custom-control-label{
            cursor: pointer;
            &::before{
              background-color: transparent;
            }
          }
          .custom-control-input:indeterminate ~ .custom-control-label::before {
            border-color: #2daf92;
            background-color: #2daf92;
          }
          .custom-control-input:checked ~ .custom-control-label::before {
            border-color: #2daf92;
            background-color: #2daf92;
          }
        }      
        .react-pdf__Page{       
          &:last-child{
            border-bottom:0px;
          }
          canvas{
            //transition: all 0.6s ease;
            margin: 0 auto;
            // width: 100% !important;
            // height: auto !important;
            //min-width: 100%;
          }
        }
        .custom-page-action{
          height:32px;
          margin:10px 0px;
          .page-ttl, .page-count{
            color: #CACED2;
            letter-spacing: 0.16px;
            font-family: "OpenSans";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 33%;
          }
          .page-ttl{
            .custom-control-label{
              width: 100%;
            }
          }
          .page-count{
            font-size: 12px;
            line-height: 18px;
            font-weight: normal;
          }
          .action{
            .btn{
              height:32px;              
            }
            .more-action{
              margin-left: 15px;
              .btn-outline-primary{
                background:transparent;
                border-color: transparent;
                color: #2CAF92;
                font-family: "OpenSans";
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                // &:hover, &:active, &:not(:disabled):not(.disabled):active {
                //   background: #364760;
                //   border-color: #364760;
                // }
              }
              &.show, &:hover, &:active, &:not(:disabled):not(.disabled):active{
                .btn-outline-primary{               
                  background: #364760;
                  border-color: #364760;
                  color:#fff;
                }                
              }
              &.dropdown{
                .dropdown-menu{
                  .dropdown-header{
                    font-family: "OpenSans-Semibold";
                    color: #6A6E73;
                    font-size: 10px;
                    line-height: 16px;
                    letter-spacing: 1px;
                    padding: 13px 17px 12px;
                    text-transform: uppercase;
                  }
                  .dropdown-item{
                    border-bottom:0px;
                  }
                } 
              } 
            }
          }
        }
        .grid-view{
          display: inline-block;       
          margin-right: 32px;
          margin-bottom: 7px;        
          .custom-page-action{
            .action{
              position: relative;
            }
            .page-count{
              max-width: 50%;
            }
          }
          .react-pdf__Page{
            width:181px;
            height:213px;
            overflow: hidden;
            canvas{
              width: 100% !important;
              height:auto !important;
            }
          }        
        }
      }
      .grid-sec{
        border-bottom: 1px solid rgba(224, 227, 230, 0.2);
      }
      .custom-error-msg{
        padding: 2px 0px;
        font-family: "OpenSans";
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        margin-right: 15px;
      }
      .email-attachement{
        margin: 10px 0px;
        .link-btn{
          font-size: 16px;
          line-height: 18px;
          height: auto;
        }
        .info{
          margin-top:2px;
          color:#cfa72a;
          font-size: 12px;
          line-height: 18px;
          font-weight: normal;
          letter-spacing: 0.16px;
          font-family: "OpenSans-Bold";
          //font-family: "OpenSans";
          font-style: normal;
        }
        &.doc-view{
          margin: 20px 32px;
          padding: 10px 0px 0px;
          border-top: 1px solid rgba(224, 227, 230, 0.2);
        }
      }
      .zoom-btn{
        position: fixed;
        bottom: 70px;
        left: 40%;
        border-radius: 3px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
        .btn{
          //padding: 0px 10px;
          padding: 0px 6px;
          height: 30px;
        }
      }
    }       
  }
  &.confirm-modal{
    position: fixed;
    width:100%;
    left: 0px;
    right:0px;
    top:0px;
    bottom: 0px;
    .upload-link{
      position: relative;
      &.mrgn-top{
        margin-top: 0.5rem;
      }
      .btn{
        height: auto;
      }
      .form-control-file{
          top:0px;
          opacity: 0;
          cursor: pointer;
          font-size: 0;
          position: absolute;            
      }          
    }
  }
}
 .more-actionnew {
  &.dropdown {
    &.show {
      .more {
        background: #364760;
        color: #fff;
        border-color: #364760;
      
        svg {
          fill: #fff;
        }
      }
    }
    #scrollable-dropdown-menu .tt-dropdown-menu {
      max-height: 150px;
      overflow-y: auto;
    }


    .dropdown-menu {
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
      border: 1px solid #e0e3e6;
      border-radius: 3px;
      background: #ffffff;
      padding: 0px;
      margin: 0px;
      
      .dropdown-item {
        font-family: "OpenSans";
        font-size: 14px;
        padding: 13px 17px 12px;
        color: #1d1e20;
        font-weight: normal;
        line-height: 18px;
        border-bottom: 1px solid #e0e3e6;

        img {
          margin-right: 5px;
        }

        &:hover,
        &:active,
        &:focus {
          // background-color:;
          // color: #1d1e20;
          // outline: 0px;
        }
      }
    }
  }

  &.date-selector {
    display: inline-block;

    .btn-primary {
      background: #ffffff;
      border: 1px solid #caced2;
      box-sizing: border-box;
     // border-radius: 3px;
      color: #1d1e20;
      font-family: "OpenSans";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      //line-height: 19px;
      width: 119px;
      padding: 0px 12px;
      text-align: left;

      &::after {
        content: none;
      }

      img {
        padding-right: 10px;
      }

      .arrow {
        position: absolute;
        top: 14px;
        right: 0;
      }

      &.show,
      &:focus,
      &:active,
      &:not(:disabled):not(.disabled):active {
        color: #1d1e20;
        border-color: #caced2;
      }
    }

    .dropdown-menu {
      min-width: auto;
      right: 0 !important;
    }
  }
}

// .border-bottom {
//   border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
// }
 
// .react-tabs__tab {
//   --bs-nav-link-padding-x: 1rem;
//     --bs-nav-link-padding-y: .5rem;
//     --bs-nav-link-font-weight: ;
//     --bs-nav-link-color: var(--bs-body-color);
//     --bs-nav-link-hover-color: var(--bs-link-hover-color);
//     --bs-nav-link-disabled-color: var(--bs-secondary-color);
//     display: flex;
//     flex-wrap: wrap;
//     padding-left: 0;
//     margin-bottom: 0;
//     list-style: none;
//   // display: inline-block!important;
//   // border: 0px solid transparent!important;
//   // // border-bottom:none;
//   // // bottom: -1px;
//   // // position: relative!important;
//   // // list-style: none!important;
//   // // padding: 6px 12px 6px 12px!important;
//   // // cursor: pointer!important;
//   // --bs-nav-underline-gap: 2.5rem;
//   //   --bs-nav-underline-border-width: .125rem;
//   // //  --bs-nav-underline-link-active-color: var(--bs-body-color);
//   //   gap: var(--bs-nav-underline-gap);
//   //   border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
//   //   font-weight: 700;
// }
// .is-selected {

//   // text-decoration-line: underline;
//   // text-underline-offset: 12px;
//   display: inline-block!important;
//  // border: 1px solid transparent!important;
//  //  border-bottom: 2px solid blue!important;
//  // bottom: -2px;
//   position: relative!important;
//   list-style: none!important;
//   //padding: 6px 12px!important;
//   cursor: pointer!important;
//   --bs-nav-underline-border-width: .125rem;
//   //font-weight: 700;
//   border-color: var(--bs-primary);
//   --bs-nav-link-hover-color: var(--bs-link-hover-color);
//     }

//     .react-tabs__tab:focus {
//         outline: #065a8c;  
//       border-bottom: 2px solid blue!important;
//      // font-weight: 700;
//   }

//   .react-tabs__tab:hover {
//     // outline: none;  
//     // border-bottom: 1px solid blue!important;
//  // text-decoration: underline;
//   //text-underline-offset: 13px;
//   //bottom: -1px;
//   //padding: 6px 12px;
//   border-bottom: var(--bs-nav-underline-border-width) solid transparent;
// }
//   .react-tabs__tab-list {
//     border-bottom: 1px solid ;
//     margin: 0 0 10px;
//     padding: 0;
//     border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
// }
// .react-tabs__tab-panel {
//   display: block!important;
// }


 .txtarea {
  white-space: pre-wrap !important;
  word-wrap: break-word;   
 }

 .btn-sml { height: 4vh; }

 .progress, .progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #6cb86c!important;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}
 
.badge.block-badge {
  display: block !important;
}
.date-time {
  float: left;
  margin: 10px;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333;
}


.react-bootstrap-table {
  overflow-x: visible;
}

#tbltrackertemplate  tbody tr {
  cursor: pointer;
}

#workstationDiv {
  overflow-x: visible!important;
}
.react-bootstrap-table .table {
  width: calc(100% - 3px);
}



.more-actionnew {
  &.dropdown {
    &.show {
      .more {
        background: #364760;
        color: #fff;
        border-color: #364760;
      
        svg {
          fill: #fff;
        }
      }
    }
    #scrollable-dropdown-menu .tt-dropdown-menu {
      max-height: 150px;
      overflow-y: auto;
      
    }


    .dropdown-menu {
      background: #fff;
      border: 1px solid #e0e3e6;
      border-radius: 3px;
      box-shadow: 0 3px 8px #0000001a;
      margin: 0;
      padding: 0;
      .dropdown-item {
        font-family: "OpenSans";
        font-size: 14px;
        padding: 13px 17px 12px;
        color: #1d1e20;
        font-weight: normal;
        line-height: 18px;
        border-bottom: 1px solid #e0e3e6;

        img {
          margin-right: 5px;
        }

        &:hover,
        &:active,
        &:focus {
          background-color: #f4f6f8;
          color: #1d1e20;
          outline: 0px;
        }
      }
    }
  }

  &.date-selector {
    display: inline-block;

    .btn-primary {
      background: #ffffff;
      border: 1px solid #caced2;
      box-sizing: border-box;
      border-radius: 3px;
      color: #1d1e20;
      font-family: "OpenSans";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      width: 119px;
      padding: 0px 12px;
      text-align: left;

      &::after {
        content: none;
      }

      img {
        padding-right: 10px;
      }

      .arrow {
        position: absolute;
        top: 14px;
        right: 0;
      }

      &.show,
      &:focus,
      &:active,
      &:not(:disabled):not(.disabled):active {
        color: #1d1e20;
        border-color: #caced2;
      }
    }

    .dropdown-menu {
      min-width: auto;
      right: 0 !important;
    }
  }
}


.table-responsive .dropdown,
.table-responsive .btn-group,
.table-responsive .btn-group-vertical {
    position: static!important;

}
.posstatic {
position: static!important;
}
.table-responsive1 {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.btn1:disabled, .btn1.disabled, fieldset:disabled .btn1 {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: #defff8;
  border-color: #defff8;
  opacity: var(--bs-btn-disabled-opacity);
}



.table-responsive1 {
  overflow-x: visible!important;
  -webkit-overflow-scrolling: touch;
}


.default-theme-toggle{
  padding: 11px 17px;
}
// .back-droppdf{ 
//   width: 35%;
//   &.viewer-panel{
//     transition: opacity 0.3s ease-out;
//     background:#333F4F;
//     width: 25%;
//     &.opacity{
//       opacity: 0.4;
//     }
//     .footer-section{
//         position: fixed;
//         background: #333F4F;
//         box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
//         width:45%;
//         z-index: 1;
//         right:auto;
//         left:0px;
//         bottom: 0px;
//         height:72px;
//         padding: 0px 32px;
//         border-radius: 0px 0px 3px 0px;
//         //border-top: 1px solid #e0e3e6;
//         .custom-checkbox{
//           margin-right: 15px;          
//           .custom-control-label{
//             cursor: pointer;
//             font-family: "OpenSans-Bold";
//             font-weight: normal;
//             font-size: 14px;
//             line-height: 18px;
//             letter-spacing: 0.2px;
//             transition: all 0.5s ease;
//             color: #FFFFFF;
//             &::before{
//               background-color: transparent;
//             }
//           }
//           .custom-control-input:indeterminate ~ .custom-control-label::before {
//             border-color: #2daf92;
//             background-color: #2daf92;
//           }
//           .custom-control-input:checked ~ .custom-control-label::before {
//             border-color: #2daf92;
//             background-color: #2daf92;
//           }
//         }
//         .btn{
//           margin-left: 8px;
//           margin-right:8px;
//           color: #FFFFFF;
//           font-weight: bold;
//           &.cross{
//             width: 40px;
//             height: 40px;
//             padding: 0px;
//             margin-left: 18px;
//             line-height: 0px;
//           }
//         }
//         .more-action{
//           &.dropdown{
//             .more{
//               background-color: transparent;
//               svg{
//                 fill:#fff;
//               }
//               &:hover, &:active, &:focus, &:not(:disabled):not(.disabled):active{
//                 background-color: #2caf92;
//                 color: #fff;
//                 border-color: #2caf92;
//               }           
//             }
//           }          
//         }
//     }    
//     //border-right: 1px solid #e0e3e6;
//     .header-panel{
//       height: 88px;
//       padding:0px 32px;
//       background: #333F4F;
//       box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
//       .page-ttl{
//         color: #FFFFFF;
//         font-family: "OpenSans";
//         font-style: normal;
//         font-weight: 600;
//         font-size: 20px;
//         line-height: 24px;
//       }
//       .toggle-button{
//         .btn-group{
//           .btn{
//             background-color:transparent;
//             border-color: rgba(255, 255, 255, 0.2);
//             padding:10px;
//             svg{
//               opacity: 0.5;
//               fill: #fff;
//             }
//             &:hover, &.active{
//               border-color:#2CAF92;
//               opacity: 1;
//               svg{
//                 opacity: 1;
//               }
//             }
//           }
//         }
//       }
//       .action{
//         .btn{
//           height: 40px;
//         }
//       }
//     }
//     .file-name{
//       padding: 0px 20px;
//       height:50px;
//       color: #1D1E20;
//       font-family: "OpenSans";
//       font-style: normal;
//       font-weight: 600;
//       font-size: 20px;
//       line-height: 24px;
//     }
//     .scroll-area{
//       position: relative;
//       height: calc(100vh - 88px);
//       padding: 0px 32px 15px; 
//       overflow-y: auto;
//       overflow-x: hidden;
//       &.doc-sec{
//         overflow-x: auto;
//         padding: 0px 0px 15px;
//           .custom-page-action{
//             &.doc-header{
//               margin: 10px 0px;
//               padding: 0px 32px;
//               background: #333F4F;
//               box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
//               height:45px;            
//             }
//             .doc-panel{
//               padding: 0px 32px;
//             }
//           }       
//       }
//       &.modal-open{
//         overflow-y: hidden;
//       }
//       .footer-enable{
//         margin-bottom: 90px;
//       }
//       .react-pdf__Document{
//         .custom-checkbox{
//           .custom-control-label{
//             cursor: pointer;
//             &::before{
//               background-color: transparent;
//             }
//           }
//           .custom-control-input:indeterminate ~ .custom-control-label::before {
//             border-color: #2daf92;
//             background-color: #2daf92;
//           }
//           .custom-control-input:checked ~ .custom-control-label::before {
//             border-color: #2daf92;
//             background-color: #2daf92;
//           }
//         }      
//         .react-pdf__Page{       
//           &:last-child{
//             border-bottom:0px;
//           }
//           canvas{
//             //transition: all 0.6s ease;
//             margin: 0 auto;
//             // width: 100% !important;
//             // height: auto !important;
//             //min-width: 100%;
//           }
//         }
//         .custom-page-action{
//           height:32px;
//           margin:10px 0px;
//           .page-ttl, .page-count{
//             color: #CACED2;
//             letter-spacing: 0.16px;
//             font-family: "OpenSans";
//             font-style: normal;
//             font-weight: 600;
//             font-size: 14px;
//             line-height: 18px;
//             white-space: nowrap;
//             overflow: hidden;
//             text-overflow: ellipsis;
//             max-width: 33%;
//           }
//           .page-ttl{
//             .custom-control-label{
//               width: 100%;
//             }
//           }
//           .page-count{
//             font-size: 12px;
//             line-height: 18px;
//             font-weight: normal;
//           }
//           .action{
//             .btn{
//               height:32px;              
//             }
//             .more-action{
//               margin-left: 15px;
//               .btn-outline-primary{
//                 background:transparent;
//                 border-color: transparent;
//                 color: #2CAF92;
//                 font-family: "OpenSans";
//                 font-style: normal;
//                 font-weight: bold;
//                 font-size: 14px;
//                 line-height: 18px;
//                 // &:hover, &:active, &:not(:disabled):not(.disabled):active {
//                 //   background: #364760;
//                 //   border-color: #364760;
//                 // }
//               }
//               &.show, &:hover, &:active, &:not(:disabled):not(.disabled):active{
//                 .btn-outline-primary{               
//                   background: #364760;
//                   border-color: #364760;
//                   color:#fff;
//                 }                
//               }
//               &.dropdown{
//                 .dropdown-menu{
//                   .dropdown-header{
//                     font-family: "OpenSans-Semibold";
//                     color: #6A6E73;
//                     font-size: 10px;
//                     line-height: 16px;
//                     letter-spacing: 1px;
//                     padding: 13px 17px 12px;
//                     text-transform: uppercase;
//                   }
//                   .dropdown-item{
//                     border-bottom:0px;
//                   }
//                 } 
//               } 
//             }
//           }
//         }
//         .grid-view{
//           display: inline-block;       
//           margin-right: 32px;
//           margin-bottom: 7px;        
//           .custom-page-action{
//             .action{
//               position: relative;
//             }
//             .page-count{
//               max-width: 50%;
//             }
//           }
//           .react-pdf__Page{
//             width:181px;
//             height:213px;
//             overflow: hidden;
//             canvas{
//               width: 100% !important;
//               height:auto !important;
//             }
//           }        
//         }
//       }
//       .grid-sec{
//         border-bottom: 1px solid rgba(224, 227, 230, 0.2);
//       }
//       .custom-error-msg{
//         padding: 2px 0px;
//         font-family: "OpenSans";
//         font-size: 14px;
//         line-height: 18px;
//         color: #fff;
//         margin-right: 15px;
//       }
//       .email-attachement{
//         margin: 10px 0px;
//         .link-btn{
//           font-size: 16px;
//           line-height: 18px;
//           height: auto;
//         }
//         .info{
//           margin-top:2px;
//           color:#cfa72a;
//           font-size: 12px;
//           line-height: 18px;
//           font-weight: normal;
//           letter-spacing: 0.16px;
//           font-family: "OpenSans-Bold";
//           //font-family: "OpenSans";
//           font-style: normal;
//         }
//         &.doc-view{
//           margin: 20px 32px;
//           padding: 10px 0px 0px;
//           border-top: 1px solid rgba(224, 227, 230, 0.2);
//         }
//       }
//       .zoom-btn{
//         position: fixed;
//         bottom: 70px;
//         left: 40%;
//         border-radius: 3px;
//         box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
//         .btn{
//           //padding: 0px 10px;
//           padding: 0px 6px;
//           height: 30px;
//         }
//       }
//     }       
//   }
//   &.confirm-modal{
//     position: fixed;
//     width:100%;
//     left: 0px;
//     right:0px;
//     top:0px;
//     bottom: 0px;
//     .upload-link{
//       position: relative;
//       &.mrgn-top{
//         margin-top: 0.5rem;
//       }
//       .btn{
//         height: auto;
//       }
//       .form-control-file{
//           top:0px;
//           opacity: 0;
//           cursor: pointer;
//           font-size: 0;
//           position: absolute;            
//       }          
//     }
//   }
// }

// input[type="checkbox"] {
//   appearance: checkbox !important;
   
//   height: 12px!important;
//   width: 12px!important;
   

// }

// input[type=checkbox] {
//   border: 3px solid #7e8a94;
//   float: right;
//   height: 20px;
//   width: 20px;
//   border-radius: 5px;
//   cursor: pointer;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   &:checked{
      
    
//     background-color: #8cde95;
    
//   }

//   &:focus{
//       outline: none !important;
//   }
// }


.custom-checkbox input {
  display: none;
}

.custom-checkbox span {
  border: 3px solid #7e8a94;
  float: right;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-checkbox:hover span,
.custom-checkbox input:checked + span {
  border: 3px solid #43D8B0;
}

.custom-checkbox input:checked + span:before {
  content: "✔";
}

.checkbox input[type="checkbox"] {
  display: none;
}
.checkbox input[type="checkbox"]:checked + .wrapper {
  background-color: #8cde95;
}
.checkbox input[type="checkbox"]:checked + .wrapper .knob {
  left: 20px;
}
.checkbox .wrapper {
  background-color: #666;
  border: 1px solid #666;
  border-radius: 10px;
  width: 42px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  outline: none;
}
.checkbox .knob {
  background-color: white;
  border: 1px solid #666;
  border-radius: 100%;
  display: inline-block;
  margin-left: 2px;
  position: relative;
  width: 16px;
  height: 16px;
  left: 0;
  transition: left 100ms ease-in-out 0s;
}



.react-bootstrap-tableExp{
  table{
    margin-bottom: 4px;
    thead{
      th{
       //  overflow: visible;
       //  background: #848992;
       //  min-width: 14%;
       //  width:auto;
       //  padding: 0 8px;
       //  color:white;
       font-weight: normal;
       background-color: "red"!important;
       //var(--bs-light) !important;
       color: black!important;
       // var(--bs-secondary) !important;
       font-weight: 500;
        &.ehid{
          width:120px;
        }
        &.expand-cell-header{
          width:50px;
        }
        &.amount {
          text-align: right;
        }
        .default-view{
          border-top: 1px solid #e0e3e6;
          width:100%;
          height: auto;
          padding: 20px 20px;
        }
        &.description{
          min-width:20%;
          width: auto;
        }                
        &:last-child{
          min-width: 75px;
          width:75px;
          text-align: right;
        }
        .rolePermissiondropdown{
          .dropdown-toggle{
            text-align: center;
            padding: 5px;
            height:30px;
            .icon{
              svg{
                margin-right: auto;
                fill: #1d1e20 !important;
              }
            }
            .arrow-icon{
              svg{
                margin-right:0px;
                stroke: #3f4347;
                fill: transparent !important;
              }                      
            }                    
            &:hover{
              .icon{
                svg{
                  fill: #fff !important;
                }
              }
              .arrow-icon{
                svg{
                  stroke: #fff;
                }
              }                     
            }
          }
          .header{
            padding: 17px 16px;
            border-bottom: 1px solid #e0e3e6;
            font-family: "OpenSans-Semibold";
            color: #1d1e20;
            
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.16px;
            margin-bottom: 10px;
          }
          .dropdown-menu{
            .dropdownItems{
              padding-bottom: 8px;
              .dropdown-item{
                padding: 7px 17px;
                .form-check-label{
                  top: -2px !important;
                }
              }
            }                    
          }
          &.show{
            .dropdown-toggle{
              .icon{
                svg{
                  fill: #fff !important;
                }
              }
              .arrow-icon{
                svg{
                  stroke: #fff;
                }
              }                    
            }
          }
        }
        .action-grp{
          .more{
            height: auto;
            margin-left: 5px;
            font-size: 12px;
            padding: 5px 5px;
            line-height: 12px;
            color: #2caf92;
            border-color: #2caf92;
            background: #ffffff;
            &:hover, &:focus{
              background: #364760;
              border-color: #364760;
              color: #fff;
            }
          }
        }
        &.selection-cell-header{
          width:50px;
        }
      }
      tr{
        &.assignCoaHeader{
          background: #E8EAED;
          th{
            opacity: 0;
            &:first-child{
              opacity: 1;
            }
          }
        }
      }
    }
    tbody{
      td{
        overflow: visible;
        padding: 0 8px;
        .static-form-control{
          font-family: "OpenSans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          padding: 10px 8px;
          height: 36px;
          color: #1D1E20;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          transition: border-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out;
        }
        .form-control{
          width:100%;
          font-family: "OpenSans";
          font-style: normal;
          font-weight: 400;
          text-align: left;
          font-size: 14px;
          line-height: 18px;
          color: #1d1e20;
        }
        .more-action{
          &.confirmation-modal{
            .dropdown-menu{
              border:0px;
              box-shadow: none;
            }
          }
        }
        &:last-child{
          text-align: right;
        }
        .single-select-image{
          .filter-btn{
            width: 100%;
            border: 1px solid #ced4da;
            background-color: #fff;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, 
            box-shadow 0.15s ease-in-out;
            padding: 0px 8px;
            height: 32px;
            .txt-area{
            padding-top: 1%;
              max-width: 75%;
              width: 100px;
            }
            &:focus{
              background-color: #364760 !important;
              color: #fff !important;
              border-color: #364760 !important;
              .drop-arrow{
                svg{
                  stroke:#fff;
                }
              }
            }
            .drop-arrow{
              right: 8px;
              svg{
                margin-right: 0px;
              }
            }
          }
          .dropdown-menu{
            .list-area{
              min-height: auto;
              max-height: 250px;
            }                    
          }                  
          &.coa-select{
            .rbt-input-hint{
              opacity: 0;
            }                    
            .dropdown-menu{
              width:300px !important;                      
              .dropdown-item{
                border-bottom: 0px;
                &.load-more{
                  text-align: center;
                  background-color: #2caf92;
                  color:#fff;
                  padding-top: 8px;
                  padding-bottom: 8px;
                  &:hover{
                    background-color: #0E9074;
                  }
                }
              }
              margin-bottom: 20px;
            }
          }
        }
        &.amount{
          .form-control{
            text-align: right;
            &::placeholder{
              text-align: left;
            }
          }                  
        }
        &.coa-col{
          .availble-width{
            width: calc(100% - 26px);
          }
          .custom-checkbox{
            padding-left: 16px;
            .custom-control-label{
              &::before{
                left: -16px;
              }
              &::after{
                left: -16px;
              }
            }
          }
        }
        .custom-autocomplete{
          position: relative;
          .form-control{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .suggestions{
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
            border: 1px solid #e0e3e6;
            border-radius: 3px;
            background: #ffffff;
            padding: 0px;
            margin: 0px;
            position: absolute;
            width: 250px;
            max-height: 250px;
            overflow: auto;
            z-index: 2;
            top: 36px;
            left: 0px;
            li{
                font-family: "OpenSans";
                font-size: 14px;
                padding: 13px 17px 12px;
                color: #1d1e20;
                font-weight: normal;
                line-height: 18px;
                border-bottom: 1px solid #e0e3e6;
                &.active, &:hover, &:focus{
                  background-color: #f4f6f8;
                  color: #1d1e20;
                  outline: 0px;
                }
            }
          }
        }                
      }
      tr{
        &.hidesplitrow {
          display: none;
        }
      }
    }
  }
}


// .reset-expansion-style{
//      padding: 14px 0px 14px 27px;
//     .row-expand-slide-enter-done{
//       border: 0px;
//       border-radius: 0px;
//       .row-expansion-style{         
//         //padding: 0px;              
//         .react-bootstrap-table{                          
//           thead, tbody{
//             tr{
//               th{
//                 //height:28px;
//                // background-color: #ced4da!important;
//               }
//               th, td{
//                 text-align: left;
//                 // &.coa-width{
//                 //   //width:300px;
//                 // }
//               }
//               &:last-child{
//                 border-bottom:10px;
//               }
//             }
//           }
//         }
//       }
//     }                  
// }    




.react-bootstrap-tableinner {
  font-size: 14px;
  line-height: 18px;
  color: #1d1e20;
  

  table {
   // border: 0px;
    line-height: 18px;
    border: 1px solid #e0e3e6;
  }

  thead {
    tr {
      th {
        height: 24px;
        vertical-align: middle;
        background: #d8e3f4;
       // background-color: "#d8e3f4"!important;
        font-family: "OpenSans-Semibold"; 
        border: 0px;
        white-space: nowrap;
       // padding: 0px 16px;
        color: black;
        font-style: normal;
       font-size: 14px;
        line-height: 18px;
        text-transform: none;
       
      }
    }
  }

  tbody,
  tfoot {
    tr {
      border-bottom: 1px solid #e0e3e6;

      td,
      th {
        height: 36px;
        vertical-align: middle;
        border: 0px;
        white-space: nowrap;
        padding: 0px 5px!important;
        color: #1d1e20;
        font-size: 14px;
        line-height: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
       

        .form-control {
          min-height: calc(1.5em + .5rem + calc(var(--bs-border-width)* 2));
          padding: .25rem .5rem;
          font-size: .875rem;
          border-radius: var(--bs-border-radius-sm);
    
      
      
        }
      }

      &:hover {
       // background-color: #e8eaed;
      }
      &.parent-expand-foo{

        background-color: violet!important;
      }
      // &.parent-expand-foo {
      //   border-bottom: 0px;
      //   background-color: violet;

      //   & + tr {
      //     .reset-expansion-style {
      //      // padding: 0px 20px;
      //      padding: 14px 0px 14px 27px;
      //       .row-expand-slide-enter-done {
      //        // border: 1px solid #e0e3e6;
      //        // border-radius: 3px;
      //       // background-color: red;
      //       }
      //     }

      //     &:hover {
      //       background-color: transparent;
      //     }
      //   }
      //   &  th {
      //     background-color: red;

      //   }
      // }
      .table-active {
        --bs-table-striped-bg: var(--bs-table-active-bg);
        --bs-table-striped-color: var(--bs-table-active-color);
      }
    }
  }

  tfoot {
    tr {
      border-top: 1px solid #e0e3e6;

      th {
        color: #2caf92;
      }
    }
  }
 
 
}
 

// .expanding-foo
// {
// background-color: red!important;

// }
.expanding-bar
{
background-color: #5c82ab08!important;

}

 .selection-row {

  background-color: #5c82ab08;

 }

 .parent-expand-main {

  background-color: red!important;

 }


 .upload-sectionnew{
  padding: 0px 32px 23px 32px;
  display: flex;
  label {
    font-family: "OpenSans-Semibold";
    font-size: 16px;
    line-height: 22px;
  }        
  .upload-linknew{
    position: relative;
    &.mrgn-top{
      margin-top: 0.5rem;
    }
    .btnnew{
      height: auto;
    }
    .form-control-filenew{
        top:0px;
        opacity: 0;
        cursor: pointer;
        font-size: 0;
        position: absolute;            
    }          
  }
  .form-group{
    width: 100%;
    margin-right: 0px;
    // &:first-child{
    //   margin-right: 15px;
    // }
    // &:last-child{
    //   margin-left: 15px;
    // }
  }
  .upload-area{
   
    box-sizing: border-box;          
    padding: 0px;
    .dropzone{
      // border: 2px dashed #E0E3E6;
      border: 2px dashed #fff;
      border-radius: 3px;
      min-height: 71px;
      //cursor: -webkit-grab; 
      //cursor: grab;
      cursor: pointer;
      text-align: center;
      aside{width:100%;}
      // p{
      //   color: #3F4347;
      //   font-family: "OpenSans";
      //   font-style: normal;
      //   font-weight: 600;
      //   font-size: 16px;
      //   line-height: 22px;
      //   span{
      //     color: #2CAF92;
      //     text-decoration: underline;
      //   }
      // }
      p{
        color: #84888C;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        font-family: "OpenSans";
        margin-bottom: 0px;
        width:100%;
        text-align: center;
        span{
          color: #2CAF92;
            text-decoration: underline;
          font-family: "OpenSans-Semibold";
        }
        &.not-accepted{
          color: #B12A18;
        }
      }
      &:focus, &:focus-visible{
        border-color: #2caf92;
        outline:0px;
      }
      &:hover{
        cursor: pointer;
      } 
    }
    ul{
      margin:0px;
      padding:0px;
      li{
        padding:10px 15px;
        .d-flex{
          .icon{width:40px;
          svg{
            width: 40px;
            height: 40px;
            color: #2caf92;
          }
        }
          .file-info{
            width:calc(100% - 80px);
            padding:0px 10px;
            .name-loader{
              width: calc(100% - 40px);
              padding-right: 20px;
              .name{    
                font-family: "OpenSans";
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                text-align: left;
                line-height: 16px; 
                max-width: 100%;
                position: relative;                 
                .full-name{
                  //max-width:calc(100% - 100px);
                  //max-width:250px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  cursor: pointer;
                  color: #1D1E20;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 18px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .full-size{
                  font-family: "OpenSans-Semibold";
                }
                .details{
                  margin-top: 4px;
                  color: #84888C;
                  font-family: "OpenSans";
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 18px;
                }
              }
              .loader{
                .bg-success{
                  background-color: #2CAF92 !important;
                }
              }
            }
            .formate-percentage{
              width:40px;
              .formate, .percentage{
                width:40px;
                height:24px;
                background: #E5F5FF;
                border-radius: 3px;
                color: #065A8C;
                font-family: "OpenSans-Semibold";
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
              }
              .percentage{
                background: transparent;
              }
            }
          }
          .action{
            width:40px;
            .more-action{
              &.dropdown{
                .more{
                  border-color: #fff;
                  &:hover, &:active, &:focus, &:not(:disabled):not(.disabled):active{
                    border-color: #364760;
                  }
                }
              }
            }
          }
        }              
      }
    }
    &.default{
      // border: 2px dashed #E0E3E6;
      border: 2px dashed #fff;
      border-radius: 20px;
      min-height: 74px;
      cursor: -webkit-grab; 
      cursor: grab;
      text-align: center;            
      position: relative;            
      .dropzone{
        border: 0px;
        background-color: transparent;
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        min-height: 71px;              
        cursor: pointer;              
        text-align: center;
        p{
          //color: #3F4347;
          font-family: "OpenSans";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          span{
            color: #2CAF92;
            text-decoration: underline;
          }
        }
      }
      &.drag-active{
        border-color: #2CAF92;
        .dropzone{
          z-index: 1;
          cursor: grab;
          cursor: -webkit-grab; 
          background-color: rgba(255, 255, 255, 0.8);
          p{
            color: #000000;
          }
        }
      }
    }          
  }
  .form-group{
    textarea{
      &.form-control{
        white-space: pre-wrap;
      }
    }
  }        
}

.dashboard-text
{
  text-align: center;
  padding-right: 40px;
  padding-top: 10px;
}


.loginnew {

  position: relative;
  top: 50%;
  transform: translateY(-50%);
  .login {
    width: 360px;
    padding: 8% 0 0;
    margin: auto;
    font-family: 'Comfortaa', cursive;
  }
  
  .form {
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    border-radius: 10px;
    max-width: 360px;
    margin: 0 auto 100px;
    padding: 45px;
    text-align: center;
  }
  
  .form input {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    border-radius: 5px;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: 'Comfortaa', cursive;
     text-align: center;
  }
  
  .form input:focus {
    background: #dbdbdb;
  }
  
  .form button {
    font-family: 'Comfortaa', cursive;
    text-transform: uppercase;
    outline: 0;
    background: #4b6cb7;
    width: 100%;
    border: 0;
    border-radius: 5px;
   
    color: #FFFFFF;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
  }
  
  .form button:active {
    background: #395591;
  }
  
  .form span {
    font-size: 40px;
    color: #4b6cb7;
    margin-bottom: 25px;
    display: block;
  }
  
  .form p.error {
    margin: 0 0 10px 10px;
    text-align: left;
    font-size: 10px;
    color: red;
  }
  .invalid-feedback {
    display: block !important;
    color: #dc3545 !important;
    margin-top: 1px;
    font-size: 12px !important;
    //position: absolute;
  }
}


.back-drop.viewer-panel-left {
 
  left: 0;
  right: 0px;
  transition: opacity 0.3s ease-out;
  background: #ffffff;
  width: 35%;
}

.fa-trash {
  border-radius: 3px;
  background: #f4f6f8;
  padding: 8px;
  height: 36px;
  cursor: pointer;
  border: 0px;
  background: transparent;
  &:after {
    content: none;
  }
  &:hover,
  &:active,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background: #e8eaed;
  }
}

.gg-remove {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 22px
}
.gg-remove::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  top: 8px;
  left: 4px
}

CSS
SVG
TSX
.gg-file-remove {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 14px;
    height: 16px;
    border: 2px solid transparent;
    border-right: 0;
    border-top: 0;
    box-shadow: 0 0 0 2px;
    border-radius: 1px;
    border-top-right-radius: 4px;
    overflow: hidden
}
.gg-file-remove::after,
.gg-file-remove::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px
}
.gg-file-remove::before {
    background: currentColor;
    left: 2px;
    height: 2px;
    top: 9px
}
.gg-file-remove::after {
    height: 6px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    right: -1px;
    top: -1px
}


.orderLinks {
  color: #2caf92;
  cursor: pointer;
  font-family: OpenSans-Semibold;
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  padding-top: 0;
}

.btnheight
{

  height: 16px;
  border:none;
  padding-left: 60%;
  background-color:transparent;
}
.btntrn
{

 
  border:none;
  
  background-color:transparent;
}
 .left-panel {
   // width: 100%;
    background: #f4f6f8;
    border-right: 1px solid #e0e3e6;

  }
.custom-row-class
{

  background: #f4f6f8;
  border-right: 1px solid #e0e3e6;
}


.back-drop2 {
  position: fixed;
  background-color: rgba(58, 58, 62, 0.4);
  width: 65%;
  top:62.50px;
  right: 0px;
  bottom: 0px;
 
   
    
}

.custompaging {

  .react-bootstrap-table {
    font-size: 14px;
    line-height: 18px;
    color: #1d1e20;
    
  
    table {
     // border: 0px;
      line-height: 18px;
      //border: 1px solid #e0e3e6;
    }
  
    thead {
      tr {
        th {
          height: 24px;
          vertical-align: middle;
          background: #d8e3f4;
         // background-color: "#d8e3f4"!important;
          font-family: "OpenSans-Semibold"; 
          border: 0px;
          white-space: nowrap;
         // padding: 0px 16px;
          color: black;
          font-style: normal;
         font-size: 14px;
          line-height: 18px;
          text-transform: none;
         
        }
      }
    }
  
    tbody,
    tfoot {
      tr {
        border-bottom: 1px solid #e0e3e6;
  
        td,
        th {
          height: 36px;
          vertical-align: middle;
          border: 0px;
          text-align: left;
          white-space: nowrap;
          padding: 0px 5px!important;
          color: #1d1e20;
          font-size: 14px;
          line-height: 18px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
         
  
          .form-control {
            min-height: calc(1.5em + .5rem + calc(var(--bs-border-width)* 2));
            padding: .25rem .5rem;
            font-size: .875rem;
            border-radius: var(--bs-border-radius-sm);
      
        
        
          }
        }
  
        &:hover {
         // background-color: #e8eaed;
        }
  &.parent-expand-foo{
  
    background-color: red!important;
  }
        // &.parent-expand-foo {
        //   border-bottom: 0px;
        //   background-color: violet;
  
        //   & + tr {
        //     .reset-expansion-style {
        //      // padding: 0px 20px;
        //      padding: 14px 0px 14px 27px;
        //       .row-expand-slide-enter-done {
        //        // border: 1px solid #e0e3e6;
        //        // border-radius: 3px;
        //       // background-color: red;
        //       }
        //     }
  
        //     &:hover {
        //       background-color: transparent;
        //     }
        //   }
        //   &  th {
        //     background-color: red;
  
        //   }
        // }
      }
    }
  
    tfoot {
      tr {
        border-top: 1px solid #e0e3e6;
  
        th {
          color: #2caf92;
        }
      }
    }
   
    .react-bootstrap-table-pagination {
      margin-top: auto !important;
      /* align-items: center !important; */
      display: flex !important;
      position: fixed;
      /* bottom: 0; */
      width: 100%;
      /* margin-right: 0px; */
      font-family: "OpenSans";
      font-style: normal;
      background-color: #FFFFFF;
      font-size: 12px;
      padding-top: 0.5%;
      padding-bottom: 2%;
    }
  }
  .react-bootstrap-table-pagination {
    margin-top: auto !important;
    /* align-items: center !important; */
    display: flex !important;
    position: fixed;
    /* bottom: 0; */
    width: 100%;
    /* margin-right: 0px; */
    font-family: "OpenSans";
    font-style: normal;
    background-color: #FFFFFF;
    font-size: 12px;
    padding-top: 0.5%;
    padding-bottom: 2%;
  }


}


.search-header {
  width: 100%!important;
  &.auto-complete {
    width: 90%;
  }
}

.selection-row
{

  background-color: red!important;
}



.process
{
 
     .react-bootstrap-table {
      font-size: 14px;
      line-height: 18px;
      color: #1d1e20;
      
    
      table {
       // border: 0px;
        line-height: 18px;
        //border: 1px solid #e0e3e6;
      }
    
      thead {
        tr {
          th {
            height: 24px;
            vertical-align: middle;
            background: #d8e3f4;
           // background-color: "#d8e3f4"!important;
            font-family: "OpenSans-Semibold"; 
            border: 0px;
            white-space: nowrap;
           // padding: 0px 16px;
            color: black;
            font-style: normal;
           font-size: 14px;
            line-height: 18px;
            text-transform: none;
          }
        }
      }
    
      tbody,
      tfoot {
        tr {
          border-bottom: 1px solid #e0e3e6;
    
          td,
          th {
            height: 36px;
            vertical-align: middle;
            border: 0px;
            text-align: left;
            white-space: nowrap;
            padding: 0px 5px!important;
            color: #1d1e20;
            font-size: 14px;
            line-height: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
           
            background-color: #fff;
           
    
            
  
            
        
          }
    
        &:active-row {
          background-color:red!important;
        }

        .table-active {
          --bs-table-striped-bg: var(--bs-table-active-bg);
          --bs-table-striped-color: var(--bs-table-active-color);
        }
          // &:hover {
          //   background-color: #fff!important;
          // }
          // &:active-row {
          //   background-color: #fff!important;
  
          // }
   
      
        }
      
  }
    
      .active-row {
        background-color: #fff!important;
  
      }
  
     
     
    }
   
   
}

.notification-banners {
  display: flex !important;
  height: 48px !important;
  margin-bottom: 16px !important;
  padding: 16px !important;
  border-radius: 3px !important;
  align-content: center;
  flex-wrap: wrap;
  margin-left: 0 !important;
  &.green-bg {
    font-family: "OpenSans";
    font-size: 14px;
    color: #0e9074;
    line-height: 40px;
    background-color: #defff8;
    .bold {
      font-family: "OpenSans-Semibold";
    }
  }
  &.orange-bg {
    font-family: "OpenSans";
    font-size: 14px;
    color: #863902;
    line-height: 40px;
    background-color: #fff3e8;
    .bold {
      font-family: "OpenSans-Semibold";
      &.inline-link{
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  &.blue-bg {
    font-family: "OpenSans";
    font-size: 14px;
    color: #065a8c;
    line-height: 40px;
    background-color: #e5f5ff;
    .bold {
      font-family: "OpenSans-Semibold";
    }
  }
  &.red-bg {
    font-family: "OpenSans";
    font-size: 14px;
    color: #b12a18;
    line-height: 40px;
    background: #ffefed;
    .bold {
      font-family: "OpenSans-Semibold";
    }
  }
}


.table-active {
  --bs-table-striped-bg: var(--bs-table-active-bg);
  --bs-table-striped-color: var(--bs-table-active-color);
}

.table-active1 {
  --#{$prefix}table-color-state: var(--#{$prefix}table-active-color);
 // --#{$prefix}table-bg-state:     --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: #2084ce24;

  
}


.switch.ios, .switch-on.ios, .switch-off.ios { border-radius: 20rem; }
  .switch.ios .switch-handle { border-radius: 20rem; }

  .childs {
    display: inline-block;
  
    }

    .leftmargindate
    {

      padding-left: 10%;
    }



    .overview-settings {
      width: 100%;
      min-height: calc(100vh - 186px);
      border: 1px solid #e0e3e6;
      border-radius: 3px;
    
      &.holidays {
        min-height: calc(100vh - 146px);
    
        .info-badges {
          background: #e5f5ff;
          border-radius: 3px;
          padding: 12px 16px;
          margin-bottom: 16px;
          color: #065a8c;
          font-size: 14px;
          line-height: 18px;
          font-family: "OpenSans";
    
          .btn-primary {
            margin-top: 10px;
          }
        }
      }
   
      .left-panel {
        width: 100%;
        background: rgb(16 7 1 / 2%);
        border-right: 1px solid #e0e3e6;
    
        //height: 100%;
        .add-Budget {
          font-family: "OpenSans-Semibold";
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          color: #2caf92;
          display: inline-block;
          cursor: pointer;
          margin-left: 10px;
          margin-top: 16px;
        }
    
        .search-header {
          height: 73px;
          vertical-align: middle;
          border-bottom: 1px solid #e0e3e6;
          padding: 16px 16px;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
    
          label {
            display: block;
          }
    
          .form-control {
            background: #ffffff;
            height: 40px;
            padding: 10px 8px;
            border: 1px solid #caced2;
            box-sizing: border-box;
            border-radius: 3px;
            color: #84888c;
            font-family: "OpenSans";
            font-size: 14px;
            line-height: 18px;
          }
        }
    
        .left-body {
          padding: 12px 0px 12px 6px;
          max-height: calc(100vh - 261px);
          overflow: auto;
    
          .react-bootstrap-table {
            table {
              //table-layout: inherit;
              margin-bottom: 0px;
              //margin-top: 28px;
              border: 0px;
    
              thead {
                display: none;
              }
    
              tr {
                font-family: "OpenSans-Semibold";
                font-style: normal;
                border: 1px solid #f4f6f8;
                border-bottom-color: #e0e3e6;
                flex-wrap: wrap !important;
                display: flex !important;
    
                .desc {
                  font-family: "OpenSans";
                  font-size: 12px;
                  line-height: 16px;
                  font-weight: normal;
    
                  &.grey {
                    color: #3f4347;
                  }
                }
    
                .title,
                .desc,
                .title1 {
                  white-space: nowrap;
                  max-width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
    
                .title {
                  font-size: 16px;
                  line-height: 22px;
                }
    
                .title1 {
                  font-size: 14px;
                  line-height: 22px;
                }
    
                td {
                  border: 0px;
                  width: 100%;
                  color: #3f4347;
                  padding: 10px 5px 10px 5px !important;
                  height: auto;
                  background-color: transparent;
                  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                  sans-serif;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                }
    
                &.active-row {
                  background: #0E9074;
                  border: 1px solid #e0e3e6;
                  border-top-color: #fff;
                  border-radius: 3px;
    
                  .title {
                    color: #2caf92;
                  }
                }

                // .table-active {
                //   --bs-table-striped-bg: var(--bs-table-active-bg);
                //   --bs-table-striped-color: var(--bs-table-active-color);
                // }
                
    
                &:first-child {
                  &.active-row {
                    border-top-color: #e0e3e6;
                  }
                }
              }
            }
          }
        }
      }
    
      .right-panel {
        width: 62%;
        position: relative;
    
        .search-header {
          &.auto-complete {
            width: 90%;
          }
        }
    
        .pos-header {
          border: 1px solid #e0e3e6;
          border-top: 0px;
          border-right: 0px;
          padding: 0px 72px;
          height: 73px;
          border-left: 0px;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
    
          .pos-ttl {
            font-family: "Poppins-Medium";
            font-style: normal;
            font-size: 24px;
            white-space: nowrap;
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #1d1e20;
    
            .form-control {
              color: #1d1e20;
              font-family: "Poppins-Medium";
              font-style: normal;
              font-size: 24px;
              line-height: 24px;
              height: 40px;
    
              &.input-component {
                padding-top: 0px;
                padding-bottom: 0px;
                width: auto;
                max-width: 84%;
    
                &.bdr-green {
                  border-color: #2caf92;
                }
    
                input {
                  outline: none;
                  border: 0px;
                  max-width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
    
            .service-status {
              margin-left: 5px;
              display: inline-block;
              padding: 3px 6px;
              background: #ffefed;
              border-radius: 3px;
              color: #b12a18;
              font-size: 12px;
              line-height: 18px;
              font-family: "Poppins-Medium";
              font-style: normal;
              border: 1px solid #ffd3ce;
            }
          }
    
          .pos-rel {
            .pos-abs {
              z-index: 2;
            }
          }
    
          .more {
            border: 0px;
          }
        }
    
        .pos-body {
          padding: 15px 72px;
          height: calc(100vh - 261px);
          overflow: auto;
          font-family: "OpenSans";
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          color: #1d1e20;
    
          //padding-bottom: 80px;
          textarea {
            resize: none;
          }
    
          &.dataChange {
            padding-bottom: 80px;
          }
    
          .form-group {
            margin-bottom: 8px;
          }
    
          .FactorConfiguration {
            .form-group {
              margin-bottom: 24px;
            }
          }
    
          .form-control-plaintext,
          .form-control {
            font-size: 14px;
            line-height: 18px;
            color: #1d1e20;
            &.min-digits {
              width: 52px;
              margin-right: 5px;
            }
          }
    
          .col-form-label {
            font-family: "OpenSans-Semibold";
    
            svg {
              fill: #6a6e73;
              margin-left: 4px;
    
              &:hover {
                fill: #1d1e20;
              }
            }
          }
    
          .dropdown {
            .btn {
              width: 100%;
              text-align: left;
              font-size: 14px;
              line-height: 18px;
              color: #1d1e20;
              font-family: "OpenSans" !important;
              font-weight: normal;
              background: #ffffff;
              border: 1px solid #fff;
              box-sizing: border-box;
              border-radius: 3px;
              padding: 0px 8px;
    
              &:after {
                margin: 8px 0px;
                float: right;
                content: none;
              }
    
              &:disabled {
                opacity: 0.5 !important;
              }
              &:hover {
                background-color: #e8eaed;
              }
            }
    
            &.show {
              .btn {
                border-color: #2caf92;
    
                &:after {
                  content: "";
                }
    
                &:hover {
                  background-color: #fff;
                }
              }
    
              .dropdown-menu {
                background: #ffffff;
                border: 1px solid #e0e3e6;
                box-sizing: border-box;
                box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
                border-radius: 3px;
                padding: 0px;
                right: 0 !important;
                max-height: 200px;
                overflow: auto;
                margin-top: 0px;
    
                .dropdown-item {
                  padding: 9px 17px;
                  font-family: "OpenSans";
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  color: #1d1e20;
                  cursor: pointer;
    
                  &:hover {
                    background-color: #f4f6f8;
                  }
                }
              }
            }
          }
    
          .border-line {
            border-bottom: 1px solid #e0e3e6;
            // margin-bottom: 14px;
            // margin-top: 16px;
            display: none;
          }
    
          .totalcount {
            display: block;
            position: inherit;
            padding-left: 29px;
            font-family: "OpenSans-Semibold";
            font-style: normal;
            font-size: 10px;
            line-height: 18px;
          }
    
          .react-checkbox-tree label:hover {
            background: none;
          }
    
          .selectallchild {
            color: #2caf92;
            margin-left: 86%;
            font-family: "OpenSans-Semibold";
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
          }
    
          .rct-collapse.rct-collapse-btn {
            cursor: pointer;
            margin-top: -18px;
          }
    
          .rct-title {
            font-weight: bold;
            font-family: "OpenSans-Semibold";
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
          }
    
          .rct-options {
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            margin-left: -5.5rem;
            text-align: right;
            margin-top: -30px !important;
          }
    
          .rct-option-expand-all {
            color: #2caf92;
            font-family: "OpenSans-Semibold" !important;
            font-style: normal !important;
            font-size: 14px !important;
            line-height: 18px !important;
          }
    
          .rct-option-collapse-all {
            color: #2caf92;
            font-family: "OpenSans-Semibold" !important;
            font-style: normal !important;
            font-size: 14px !important;
            line-height: 18px !important;
          }
        }
    
        .new-budget {
          margin: 14px 0px 14px 17px;
          font-family: "OpenSans";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #2caf92;
        }
      }
    }
    .widthspl {
      width:  135px!important;
     }
     .widthspl125 {
      width:  125px!important;
     }
     .widthsplmain {
      width:  150px!important;
     }
     .widthspl180 {
      width:  170px!important;
     }
       .widthspl200 {
      min-width:  220px!important;
     }


     .table-activeselected {
      --bs-table-striped-bg: var(--bs-table-active-bg);
      --bs-table-striped-color: var(--bs-table-active-color);
      //font-weight: bold;
     // padding: 10px 5px 10px 5px ;
    }




    