.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.align-middle {
    vertical-align: middle;
}
.pl-5 {
    padding-left: 1.25rem;
}
.p-3 {
    padding: 0.75rem;
}
.justify-between {
    justify-content: space-between;
}
.items-center {
    align-items: center;
}
.cursor-pointer {
    cursor: pointer;
}
.flex {
    display: flex;
}
 
 
 
.shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-black {
    --tw-text-opacity: 1;

}


.text-black2 {
    --tw-text-opacity: 1;
    width: 250px;
}
.hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
}
.hover\:bg-\[\#e0f3ff\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(224 243 255 / var(--tw-bg-opacity));
}
.text-\[\#6c757d\] {
    --tw-text-opacity: 1;
    color: rgb(108 117 125 / var(--tw-text-opacity));
}
.font-normal {
    font-weight: 400;
}
.text-\[14\.08px\] {
    font-size: 14.08px;
}
.align-middle {
    vertical-align: middle;
}
.pr-3 {
    padding-right: 0.75rem;
}
.pl-16 {
    padding-left: 4rem;
}
.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
 
.justify-between {
    justify-content: space-between;
}
.items-center {
    align-items: center;
}
.cursor-pointer {
    cursor: pointer;
}

.shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
 

 
.justify-center {
    justify-content: center;
}
.items-center {
    align-items: center;
}
.h-\[60px\] {
    height: 60px;
}


.text-\[\#343a40\] {
    --tw-text-opacity: 1;
    color: rgb(52 58 64 / var(--tw-text-opacity));
}
.font-semibold {
    font-family: OpenSans-Semibold;
    font-size: 14px;
}
.text-\[14\.08px\] {
    font-size: 14.08px;
}
.align-middle {
    vertical-align: middle;
}
.gap-4 {
    gap: 1rem;
}
.items-center {
    align-items: center;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.cursor-pointer {
    cursor: pointer;
}
.shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

 
 
.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}
.bg-white {
    --tw-bg-opacity: 1;
   
}
.justify-center {
    justify-content: center;
}
.items-center {
    align-items: center;
}
.h-\[60px\] {
    height: 60px;
}
 
 
.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.cursor-pointer {
    cursor: pointer;
}
.top-7 {
    top: 1.75rem;
}
.left-5 {
    left: 1.25rem;
}
 
 
.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.align-middle {
    vertical-align: middle;
}
.pl-5 {
    padding-left: 1.25rem;
}
.p-3 {
    padding: 0.75rem;
}
.hover\:bg-\[\#e0f3ff\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(224 243 255 / var(--tw-bg-opacity));
}
.text-\[\#6c757d\] {
    --tw-text-opacity: 1;
    color: rgb(108 117 125 / var(--tw-text-opacity));
}
.font-normal {
    font-weight: 400;
}
.text-\[14\.08px\] {
    font-size: 14.08px;
}
.align-middle {
    vertical-align: middle;
}
.pr-3 {
    padding-right: 0.75rem;
}
.pl-16 {
    padding-left: 4rem;
}
.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}


.ps__rail-x, .ps__rail-y {
    opacity: 0;
    transition: background-color .2s linear,opacity .2s linear;
    position: absolute;
    z-index: 7;
}
.ps__rail-x {
    display: none!important;
    height: 15px;
    bottom: 0;
}


.ps__thumb-x, .ps__thumb-y {
    background-color: rgba(0,0,0,.1);
    border-radius: 6px;
    position: absolute;
}

.ps__thumb-x {
    transition: background-color .2s linear,height .2s ease-in-out;
    height: 6px;
    bottom: 2px;
}



x>.ps__rail-x, .ps--active-y>.ps__rail-y {
    display: block;
    background-color: initial;
}
.ps__rail-y {
    display: none;
    width: 15px;
    right: 0;
    border-radius: 50px;
}
.ps__rail-x, .ps__rail-y {
    opacity: 0;
    transition: background-color .2s linear,opacity .2s linear;
    position: absolute;
    z-index: 7;
}